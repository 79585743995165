var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-text-field",
    _vm._b(
      {
        ref: "field",
        attrs: {
          type: "number",
          "error-messages": _vm.errorMessages,
          "hide-spin-buttons": true,
        },
        on: {
          focus: _vm.onFocus,
          blur: _vm.onBlur,
          change: _vm.onChange,
          keyup: _vm.onKeyUp,
          paste: _vm.onPaste,
          "click:append": _vm.onAppendClick,
          "click:prepend": _vm.onPrepend,
          "click:append-outer": _vm.onAppendOuter,
        },
        nativeOn: {
          keydown: function ($event) {
            return _vm.onKeyDown.apply(null, arguments)
          },
        },
        model: {
          value: _vm.model,
          callback: function ($$v) {
            _vm.model = $$v
          },
          expression: "model",
        },
      },
      "v-text-field",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }