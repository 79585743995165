<template>
    <v-text-field
        ref="field"
        v-model="model"
        type="number"
        @focus="onFocus"
        @blur="onBlur"
        @change="onChange"
        @keydown.native="onKeyDown"
        @keyup="onKeyUp"
        @paste="onPaste"
        @click:append="onAppendClick"
        @click:prepend="onPrepend"
        @click:append-outer="onAppendOuter"
        :error-messages="errorMessages"
        v-bind="$attrs"
        :hide-spin-buttons="true">
    </v-text-field>
</template>

<script>
const allowedKeys = ['Enter', 'Backspace', 'Tab', 'Home', 'End', 'Delete', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-', '.', ','];
const integerList = ['int', 'currencyInt', 'intZeroDash', 'intZeroEmpty', 'I', 'D'];

export default {
    name: 'v-numeric',
    props: {
        value: null,
        step: { type: Number, default: 1 },
        'error-messages': null,
        allowNegative: { type: Boolean, default: false },
        alwaysShowValue: { type: Boolean, default: true }, // Will display the placeholder when the value is blank instead of a 0
        numberType: { type: String, default: 'int' } // number, currency, currencyInt, int, intZeroDash, intZeroEmpty, km
    },
    emits: ['input', 'enter', 'paste', 'outer', 'prepend', 'append-click', 'send-value'],
    data () {
        return {
            numberValue: this.value,
            model: this.value,
            numberFormatType: this.numberType
        };
    },
    methods: {
        focus () {
            this.$refs.field.focus();
        },
        onFocus () {
            this.updateModel();
        },
        onBlur () {
            if (this.$listeners.blur) this.$listeners.blur();
            if (this.model === '' && !this.alwaysShowValue) return;
            this.numberValue = parseFloat(this.model);
            this.format();
        },
        onKeyDown (evt) {
            const key = evt.key;

            if ((evt.ctrlKey || evt.metaKey) && key === 'a') { // Select all.
                return;
            }

            if (allowedKeys.indexOf(key) === -1 || (key === '-' && !this.allowNegative)) { // Only allowed keys can pass.
                evt.preventDefault();
                return;
            }

            if (key === 'Enter') {
                this.$emit('send-value', this.model);
                return this.$emit('enter');
            }

            if (key === '.' && this.numberType !== 'D') {
                if (integerList.indexOf(this.numberFormatType) !== -1 || `${this.model}`.indexOf('.') > -1) {
                    evt.preventDefault();
                }
            }
        },
        onKeyUp () {
            if (this.model === '' && !this.alwaysShowValue) {
                this.numberValue = '';
                return;
            }

            if (this.numberType !== 'D') {
                this.numberValue = Math.round(this.model * 100) / 100;
            }
        },
        onChange () {
            if (this.$listeners.change) this.$listeners.change();
        },
        updateModel () {
            if (this.numberValue === null) return;

            if (this.model === '' && !this.alwaysShowValue) {
                this.numberValue = '';
                return;
            }

            this.model = this.numberValue;
            const field = this.$refs.field.$el.querySelectorAll('input');
            if (field) {
                this.$nextTick(() => {
                    field[0].select();
                });
            }
        },
        format () {
            if (this.numberValue === null) return;
            if (this.model === '' && !this.alwaysShowValue) return;

            switch (this.numberType) {
                case 'D':
                    this.model = this.$format.number.call(this, +this.numberValue);
                    break;
                case 'I':
                    this.model = this.$format.int.call(this, +this.numberValue);
                    break;
                default:
                    this.model = this.$format[this.numberType].call(this, +this.numberValue);
                    break;
            }
        },
        onPaste (evt) {
            this.$emit('paste', evt);
        },
        onAppendClick () {
            return this.$emit('append-click', this.model);
        },
        onPrepend () {
            return this.$emit('prepend', this.model);
        },
        onAppendOuter () {
            return this.$emit('outer', this.model);
        },
        valDown () {
            this.numberValue = this.numberValue - this.step;
        },
        valUp () {
            if (this.numberValue !== '' && typeof this.numberValue === 'string') {
                let newNumber = 0;
                newNumber = +this.numberValue;
                this.numberValue = newNumber + this.step;
                this.model = this.numberValue;
            }
            else {
                this.numberValue = this.numberValue + this.step;
                this.model = this.numberValue;
            }
        }
    },
    watch: {
        numberValue (v) {
            this.$emit('input', v);
        },
        value (v) {
            this.model = v;
            this.numberValue = v;
            if (!this.$refs.field.isFocused) {
                this.format();
            }
        }
    },
    created () {
        this.format();
    }
};
</script>
