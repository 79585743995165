var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.defaultImage
      ? _c("div", [
          _c("img", {
            ref: "imgRef",
            staticStyle: { width: "50%" },
            attrs: { src: _vm.image, crossorigin: "anonymous" },
            on: { click: _vm.showMarkerArea },
          }),
        ])
      : _vm._e(),
    _vm.defaultImage
      ? _c("div", [
          _c("img", {
            ref: "imgRef",
            staticStyle: { width: "50%" },
            attrs: {
              src: require("../assets/defaultSketchImage.svg"),
              crossorigin: "anonymous",
            },
            on: { click: _vm.showMarkerArea },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }