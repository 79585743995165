import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '../views/Dashboard/Dashboard.vue';
import DataManager from '../views/DataManager/DataManager.vue';
import Profile from '../views/User/Profile.vue';
import MyReports from '../views/Report/MyReports.vue';
import SignIn from '../views/User/SignIn.vue';
import SignOut from '../views/User/SignOut.vue';
import SurveyAct from '../views/Survey/Act.vue';
import SurveyForm from '../views/Survey/Form.vue';
import Offline from '../views/Offline.vue';
import VueHighlightJS from 'vue-highlightjs';

import Data from '../util/Data';
import middlewarePipeline from './middlewarePipeline';
import guest from './guest';
import logcall from './logcall';

const ApprovalList = Data.xcopy(DataManager);
ApprovalList.name = 'ApprovalList';

Vue.use(VueRouter);
Vue.use(VueHighlightJS);

const routes = [
    { path: '/', name: 'Dashboard', component: Dashboard, meta: { middleware: [guest, logcall] } },
    { path: '/index.html', component: Dashboard }, // Fix for PWA at /index.html for the proxy server redirect.
    { path: '/BulkFile', name: 'BulkFile', component: () => import(/* webpackChunkName: "bulk-file" */'../views/DataManager/BulkFile.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/BackupList', name: 'BackupList', component: () => import(/* webpackChunkName: "bulk-list" */'../views/Backup/BackupList.vue'), prefetch: true, meta: { middleware: [guest] } },
    { path: '/BackupSettings', name: 'BackupSettings', component: () => import(/* webpackChunkName: "backup-settings" */'../views/Backup/BackupSettings.vue'), prefetch: true, meta: { middleware: [guest] } },
    { path: '/CustomReport', name: 'CustomReport', component: () => import(/* webpackChunkName: "custom-report" */'../views/Report/CustomReport.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/CustomReports', name: 'CustomReportList', component: () => import(/* webpackChunkName: "custom-report-list" */'../views/Report/CustomReportList.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/DataEdit', name: 'DataEdit', component: () => import(/* webpackChunkName: "data-edit" */'../views/DataManager/DataEdit.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/DataManager', name: 'DataManager', component: DataManager, meta: { middleware: [guest, logcall] } },
    { path: '/Downloads', name: 'Downloads', component: () => import(/* webpackChunkName: "downloads" */'../views/Report/Downloads.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/GIS', name: 'GIS', component: () => import(/* webpackChunkName: "gis" */'../views/Report/GIS.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/LocalDatabase', name: 'LocalDatabase', component: () => import(/* webpackChunkName: "local-db" */'../views/User/LocalDatabase.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/Profile', name: 'Profile', component: Profile, meta: { middleware: [guest, logcall] } },
    { path: '/Project', name: 'Project', component: () => import(/* webpackChunkName: "project" */'../views/Project/Project.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/Projects', name: 'ProjectList', component: () => import(/* webpackChunkName: "project-list" */'../views/Project/ProjectList.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/Reports', name: 'Reports', component: () => import(/* webpackChunkName: "reports" */'../views/Report/Reports.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/MyReports', name: 'MyReports', component: MyReports, meta: { middleware: [guest, logcall] } },
    { path: '/ReportSetup', name: 'ReportSetup', component: () => import(/* webpackChunkName: "report-setup" */'../views/Report/ReportSetup.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/Role', name: 'Role', component: () => import(/* webpackChunkName: "role" */'../views/Role/Role.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/Roles', name: 'RoleList', component: () => import(/* webpackChunkName: "role-list" */'../views/Role/RoleList.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/SignIn', name: 'SignIn', component: SignIn },
    { path: '/SignOut', name: 'SignOut', component: SignOut, meta: { middleware: [guest, logcall] } },
    { path: '/SurveyEdit', name: 'SurveyEdit', component: () => import(/* webpackChunkName: "survey-edit" */'../views/Survey/SurveyEdit.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/Surveys', name: 'SurveyList', component: () => import(/* webpackChunkName: "survey-list" */'../views/Survey/SurveyList.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/Survey', name: 'Survey', component: () => import(/* webpackChunkName: "survey" */'../views/Survey/Survey.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/SurveyAct', name: 'SurveyAct', component: SurveyAct, meta: { middleware: [guest, logcall] } },
    { path: '/SurveyForm', name: 'SurveyForm', component: SurveyForm, meta: { middleware: [guest, logcall] } },
    { path: '/User', name: 'User', component: () => import(/* webpackChunkName: "user" */'../views/User/User.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/Users', name: 'UserList', component: () => import(/* webpackChunkName: "user-list" */'../views/User/UserList.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/Webhook', name: 'Webhook', component: () => import(/* webpackChunkName: "webhook" */'../views/Webhook/Webhook.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/Webhooks', name: 'Webhooks', component: () => import(/* webpackChunkName: "webhook-list" */'../views/Webhook/WebhookList.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/DataTables', name: 'DataTables', component: () => import(/* webpackChunkName: "data-tables" */'../views/DataSources/DataTables.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/Lookups', name: 'LookupList', component: () => import('../views/Lookups/LookupSourceList.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/LookupSourceEditor', name: 'LookupSourceEditor', component: () => import('../views/Lookups/LookupSourceEditor.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/RESTSource', name: 'RESTSourceList', component: () => import('../views/RESTSource/RESTSourceList.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '*', component: () => import(/* webpackChunkName: "dashboard" */'../views/Dashboard/Dashboard.vue'), prefetch: true },
    { path: '/Offline', name: 'Offline', component: Offline },
    { path: '/Page', name: 'Page', component: () => import(/* webpackChunkName: "page" */'../views/Externals/Page.vue'), prefetch: true },
    { path: '/PageList', name: 'PageList', component: () => import(/* webpackChunkName: "page-list" */'../views/Externals/PageList.vue'), prefetch: true },
    { path: '/WorkflowSettingList', name: 'WorkflowSettingList', component: () => import(/* webpackChunkName: "workflow-settings" */'../views/WorkflowSetting/WorkflowSettingList.vue'), prefetch: true },
    { path: '/WorkflowTester', name: 'WorkflowTester', component: () => import(/* webpackChunkName: "workflow-tester" */'../views/WorkflowSetting/WorkflowTester.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/TemplateEdit', name: 'TemplateEdit', component: () => import(/* webpackChunkName: "template-edit" */'../views/Templates/TemplateEdit.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/TemplateList', name: 'TemplateList', component: () => import(/* webpackChunkName: "template-list" */'../views/Templates/TemplateList.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
    { path: '/Navigations', name: 'Navigations', component: () => import(/* webpackChunkName: "Navigations" */'../views/Navigations/Navigations.vue'), prefetch: true, meta: { middleware: [guest, logcall] } },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    /** Navigate to next if middleware is not applied */
    if (!to.meta.middleware) {
        return next();
    }

    const middleware = to.meta.middleware;
    const context = {
        to,
        from,
        next,
        // store  | You can also pass store as an argument
    };
    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    });
});
export default router;
