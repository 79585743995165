<template>
    <v-container>
        <v-row>
            <!-- <v-col sm="1" md="3" lg="3" class="d-none d-sm-none d-md-flex text-center">&nbsp;</v-col> -->
            <v-col sm="12" class="text-center">
                <v-card class="pa-5 pt-2" style="width: 100%; max-width:450px; display:inline-block;">
                    <v-row>
                        <v-col sm="12">
                            <div class="text-center f-xxxl">
                                <v-img :src="require('../../assets/relax.svg')" alt="180.earth" width="220" class="d-inline-block"></v-img>
                            </div>
                            <div class="text-center">
                                <span class="f-m">v.{{version}}</span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-card-title><div class="text-center">{{ title }}</div></v-card-title>
                    <v-card-text>
                        <v-form ref="formLogin" v-model="isValid">
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        autofocus
                                        label="Email address or username"
                                        v-model="email"
                                        max-length="50"
                                        hide-details
                                        :rules="[rules.required]"
                                        @keyup.native="goToPass" autocomplete="username">
                                    </v-text-field>
                            </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        ref="passwordTxt"
                                        label="Password"
                                        v-model="password"
                                        max-length="50"
                                        hide-details
                                        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="[rules.required]"
                                        :type="showPass ? 'text' : 'password'"
                                        @keyup.native="onKey"
                                        @click:append="showPass = !showPass" autocomplete="current-password">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" :disabled="!isValid" :loading="isBusy" @click="onSignIn"><v-icon class="mr-2">mdi-lock-open-outline</v-icon>Log In</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <!-- <v-col sm="1" md="3" lg="3" class="d-none d-sm-none d-md-flex text-center">&nbsp;</v-col> -->
        </v-row>
        <v-row dense align="center" class="pa-8">
            <v-spacer></v-spacer>
        </v-row>
        <v-row dense>
            <v-spacer></v-spacer>
            <v-col cols="12" class="text-center">
                <span class="f-m">Powered by <b>Dotcom Products</b> © 2022</span>  </v-col>
            <v-spacer></v-spacer>
        </v-row>
    </v-container>
</template>

<script>
import Util from '@/util/Util';
import Version from '../../version';

export default {
    name: 'SignIn',
    mounted () {
        this.version = Version.VERSION;
    },
    data: () => ({
        title: 'Sign In',
        isBusy: false,
        version: '0.0.0',
        email: '',
        password: '',
        showPass: false,
        isValid: false,
        rules: {
            required: value => !!`${(value || '')}`.length || 'Required.'
        }
    }),
    methods: {
        goToPass (evt) {
            if (evt.key === 'Enter') this.$refs.passwordTxt.focus();
        },
        onKey (evt) {
            if (evt.key === 'Enter') this.onSignIn();
        },
        onSignIn () {
            this.$refs.formLogin.validate();
            if (this.isValid) {
                this.isBusy = true;
                this.$showProgress();
                try {
                    this.$http.post('/User/signin', { Email: this.email.trim(), Password: this.password }).then(res => {
                        if (res.data.s) {
                            const d = res.data.d;
                            // this.$router.push({ name: 'SignIn' });
                            this.$db.reInit().then(() => {
                                d.user.FullName = `${d.user.FirstName || ''} ${d.user.LastName || ''}`.trim();
                                this.$ls.set('User', d.user);
                                this.$ls.set('Token', d.token);
                                // this.$store.commit('token', d.token); // Useful for file uploads.
                                Object.defineProperty(d.user, 'has', { value: Util.has });
                                this.$store.commit('user', d.user);
                                this.$store.dispatch('isAuthed'); // Store authentication state.
                                this.$ls.set('LastDt', new Date());
                                this.$http.defaults.headers.common.Authorization = d.token; // Set the token on all request headers.
                                this.$db.setSetting({ _id: 'Username', Value: JSON.stringify(d.user) }).then(() => {
                                    this.$root.$emit('SRVY_Authed', true);
                                    this.$router.push({ name: 'Dashboard' });
                                });
                            });
                        }
                        else this.$error(this.$t('signin.failed'), this.$t('signin.failed_msg'));
                    });
                }
                catch (ex) {
                    this.$error(this.$t('signin.failed'), this.$t('signin.failed_msg'));
                }
                finally {
                    this.isBusy = false;
                    this.$hideProgress();
                }
            }
        }
    }
};
</script>

<style scoped>
#app-bar-logo__path {
    fill: #ff3a30;
}
</style>
