var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-app-bar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.authed,
              expression: "authed",
            },
          ],
          attrs: {
            app: "",
            color: _vm.$vuetify.theme.themes.light.titlebarBack,
            dark: "",
            dense: "",
            "clipped-left": "",
          },
        },
        [
          _c("v-app-bar-nav-icon", {
            attrs: { color: _vm.$vuetify.theme.themes.light.titlebarText },
            on: {
              click: function ($event) {
                _vm.drawerShow = !_vm.drawerShow
              },
            },
          }),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-2",
                              attrs: {
                                color: `${_vm.online ? "success" : "error"}`,
                                label: "",
                                "text-color": "white",
                              },
                              on: { click: _vm.checkOnline },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c("v-icon", [_vm._v("mdi-cloud-sync")])],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("Click to re-sync.")])]
          ),
          _c("v-select", {
            ref: "projectSelector",
            staticClass: "toolbar-project-select",
            attrs: {
              "hide-details": "",
              solo: "",
              height: "34px",
              items: _vm.projectList,
              "item-text": "Name",
              "item-value": "_id",
            },
            on: { change: _vm.onProjectChange },
            model: {
              value: _vm.viewingProjectId,
              callback: function ($$v) {
                _vm.viewingProjectId = $$v
              },
              expression: "viewingProjectId",
            },
          }),
          _vm.hasAppUpdate
            ? _c(
                "v-btn",
                {
                  staticClass: "error mr-2 ml-4",
                  attrs: { small: "" },
                  on: { click: _vm.onAppUpdate },
                },
                [_vm._v("Update Application")]
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "subtitle-1 mr-2 ml-4 main-user-title",
              style: `color:${_vm.$vuetify.theme.themes.light.titlebarText}`,
            },
            [_vm._v(_vm._s(this.user.FullName || "Guest"))]
          ),
          _vm.logo
            ? _c(
                "span",
                { staticClass: "app-menu-logo ml-2" },
                [
                  _c("v-img", {
                    attrs: {
                      src: _vm.logo,
                      alt: _vm.viewProject.Name,
                      title: `v${_vm.version}`,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-menu",
            {
              attrs: { left: "", bottom: "", "offset-y": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "span",
                        { staticClass: "app-menu-prog" },
                        [
                          _vm.submitting
                            ? _c("v-progress-circular", {
                                attrs: {
                                  rotate: 270,
                                  size: 32,
                                  width: 6,
                                  value: _vm.submitValue,
                                  color: "accent",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                style: `opacity: ${_vm.submitting ? 0.5 : 1};`,
                                attrs: {
                                  color:
                                    _vm.$vuetify.theme.themes.light
                                      .titlebarText,
                                  icon: "",
                                },
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "v-list",
                [
                  _vm._l(_vm.userMenu, function (item) {
                    return _c(
                      "v-list-item",
                      {
                        key: item.id,
                        class: `${item.disabled ? " opa-5" : ""}`,
                        attrs: { disabled: item.disabled },
                        on: {
                          click: function ($event) {
                            return _vm.onUserMenuClick(item)
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-title",
                          [
                            _c("v-icon", { staticClass: "mr-2" }, [
                              _vm._v(_vm._s(item.icon)),
                            ]),
                            _vm._v(_vm._s(item.text)),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _c(
                    "v-list-item",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c("v-chip", { staticClass: "success" }, [
                        _vm._v("Version - " + _vm._s(_vm.version)),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.authed,
              expression: "authed",
            },
          ],
          ref: "navDrawer",
          staticStyle: { "background-color": "#F5F5F5" },
          attrs: {
            app: "",
            clipped: "",
            "hide-overlay": "",
            persistent: "",
            width: "360px",
          },
          model: {
            value: _vm.drawerShow,
            callback: function ($$v) {
              _vm.drawerShow = $$v
            },
            expression: "drawerShow",
          },
        },
        [
          _c(
            "v-container",
            {
              staticClass: "pa-0 menu-pad-bottom",
              attrs: { fluid: "", dense: "" },
            },
            [
              _c(
                "v-list",
                { attrs: { dense: "" } },
                [
                  _vm._l(_vm.genMenu, function (item) {
                    return [
                      item.heading
                        ? _c(
                            "v-row",
                            {
                              key: item.idx,
                              attrs: { align: "center", "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  item.heading
                                    ? _c(
                                        "v-subheader",
                                        {
                                          staticClass: "text-uppercase",
                                          style: `color:${_vm.$vuetify.theme.themes.light.menuText}`,
                                        },
                                        [_vm._v(_vm._s(item.heading))]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : item.divider
                        ? _c("v-divider", {
                            key: item.idx,
                            attrs: { dark: "" },
                          })
                        : _c(
                            "v-list-item",
                            {
                              key: item.idx,
                              class: `${item.disabled ? " opa-5" : ""}`,
                              style: `${
                                item.active
                                  ? `background-color:${_vm.$vuetify.theme.themes.light.menuSelectedBack};color:${_vm.$vuetify.theme.themes.light.menuSelectedText};`
                                  : ""
                              }`,
                              attrs: {
                                link: "",
                                dark: item.active,
                                disabled: item.disabled,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onMenuClick(item)
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-action",
                                { staticClass: "ma-0 mr-5" },
                                [
                                  _c(
                                    "v-badge",
                                    {
                                      staticClass: "ma-0",
                                      attrs: {
                                        color: "info",
                                        content: item.counter,
                                        value: item.counter,
                                        "offset-y": 10,
                                        "offset-x": 10,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            color: `${
                                              item.active
                                                ? _vm.$vuetify.theme.themes
                                                    .light.menuSelectedText
                                                : _vm.$vuetify.theme.themes
                                                    .light.menuText
                                            }`,
                                          },
                                        },
                                        [_vm._v(_vm._s(item.icon))]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  item.tip
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { right: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "v-list-item-title",
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "subtitle-2",
                                                          style:
                                                            _vm.menuItemStyle(
                                                              item.active
                                                            ),
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.text) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.text)),
                                          ]),
                                        ]
                                      )
                                    : item.counter
                                    ? _c(
                                        "v-list-item-title",
                                        {
                                          staticClass: "subtitle-2",
                                          style: _vm.menuItemStyle(item.active),
                                        },
                                        [
                                          _c(
                                            "v-badge",
                                            {
                                              staticClass: "ma-0",
                                              attrs: {
                                                inline: "",
                                                bordered: "",
                                                dot: _vm.approvalDot,
                                                color: "error",
                                                content: item.counter,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.text) + " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "v-list-item-title",
                                        {
                                          staticClass: "subtitle-2",
                                          style: _vm.menuItemStyle(item.active),
                                        },
                                        [_vm._v(" " + _vm._s(item.text) + " ")]
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ]
                  }),
                ],
                2
              ),
              _c(
                "v-expansion-panels",
                {
                  staticClass: "pr-2 pl-2",
                  attrs: { accordion: "" },
                  model: {
                    value: _vm.selectedMenuIndex,
                    callback: function ($$v) {
                      _vm.selectedMenuIndex = $$v
                    },
                    expression: "selectedMenuIndex",
                  },
                },
                [
                  _vm.adminMenu.length > 0
                    ? _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v(" Management "),
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-list",
                                { attrs: { dense: "" } },
                                [
                                  _vm._l(_vm.adminMenu, function (item) {
                                    return [
                                      item.heading
                                        ? _c(
                                            "v-row",
                                            {
                                              key: item.idx,
                                              attrs: {
                                                align: "center",
                                                "no-gutters": "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  item.heading
                                                    ? _c(
                                                        "v-subheader",
                                                        {
                                                          staticClass:
                                                            "text-uppercase",
                                                          style: `color:${_vm.$vuetify.theme.themes.light.menuText}`,
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.heading)
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : item.divider
                                        ? _c("v-divider", {
                                            key: item.idx,
                                            attrs: { dark: "" },
                                          })
                                        : _c(
                                            "v-list-item",
                                            {
                                              key: item.idx,
                                              class: `${
                                                item.disabled ? " opa-5" : ""
                                              }`,
                                              style: `${
                                                item.active
                                                  ? `background-color:${_vm.$vuetify.theme.themes.light.menuSelectedBack};color:${_vm.$vuetify.theme.themes.light.menuSelectedText};`
                                                  : ""
                                              }`,
                                              attrs: {
                                                link: "",
                                                dark: item.active,
                                                disabled: item.disabled,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onMenuClick(item)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-action",
                                                { staticClass: "ma-0 mr-5" },
                                                [
                                                  _c(
                                                    "v-badge",
                                                    {
                                                      staticClass: "ma-0",
                                                      attrs: {
                                                        color: "info",
                                                        content: item.counter,
                                                        value: item.counter,
                                                        "offset-y": 10,
                                                        "offset-x": 10,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: `${
                                                              item.active
                                                                ? _vm.$vuetify
                                                                    .theme
                                                                    .themes
                                                                    .light
                                                                    .menuSelectedText
                                                                : _vm.$vuetify
                                                                    .theme
                                                                    .themes
                                                                    .light
                                                                    .menuText
                                                            }`,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.icon)
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  item.tip
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { right: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "subtitle-2",
                                                                          style:
                                                                            _vm.menuItemStyle(
                                                                              item.active
                                                                            ),
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item.text
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(item.text)
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : item.counter
                                                    ? _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "subtitle-2",
                                                          style:
                                                            _vm.menuItemStyle(
                                                              item.active
                                                            ),
                                                        },
                                                        [
                                                          _c(
                                                            "v-badge",
                                                            {
                                                              staticClass:
                                                                "ma-0",
                                                              attrs: {
                                                                inline: "",
                                                                bordered: "",
                                                                dot: _vm.approvalDot,
                                                                color: "error",
                                                                content:
                                                                  item.counter,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.text
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "subtitle-2",
                                                          style:
                                                            _vm.menuItemStyle(
                                                              item.active
                                                            ),
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.text
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.myReportMenu.length > 0
                    ? _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v(" My Reports "),
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-list",
                                { attrs: { dense: "" } },
                                [
                                  _vm._l(_vm.myReportMenu, function (item) {
                                    return [
                                      item.heading
                                        ? _c(
                                            "v-row",
                                            {
                                              key: item.idx,
                                              attrs: {
                                                align: "center",
                                                "no-gutters": "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  item.heading
                                                    ? _c(
                                                        "v-subheader",
                                                        {
                                                          staticClass:
                                                            "text-uppercase",
                                                          style: `color:${_vm.$vuetify.theme.themes.light.menuText}`,
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.heading)
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : item.divider
                                        ? _c("v-divider", {
                                            key: item.idx,
                                            attrs: { dark: "" },
                                          })
                                        : _c(
                                            "v-list-item",
                                            {
                                              key: item.idx,
                                              class: `${
                                                item.disabled ? " opa-5" : ""
                                              }`,
                                              style: `${
                                                item.active
                                                  ? `background-color:${_vm.$vuetify.theme.themes.light.menuSelectedBack};color:${_vm.$vuetify.theme.themes.light.menuSelectedText};`
                                                  : ""
                                              }`,
                                              attrs: {
                                                link: "",
                                                dark: item.active,
                                                disabled: item.disabled,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onMenuClick(item)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-action",
                                                { staticClass: "ma-0 mr-5" },
                                                [
                                                  _c(
                                                    "v-badge",
                                                    {
                                                      staticClass: "ma-0",
                                                      attrs: {
                                                        color: "info",
                                                        content: item.counter,
                                                        value: item.counter,
                                                        "offset-y": 10,
                                                        "offset-x": 10,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: `${
                                                              item.active
                                                                ? _vm.$vuetify
                                                                    .theme
                                                                    .themes
                                                                    .light
                                                                    .menuSelectedText
                                                                : _vm.$vuetify
                                                                    .theme
                                                                    .themes
                                                                    .light
                                                                    .menuText
                                                            }`,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.icon)
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  item.tip
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { right: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "subtitle-2",
                                                                          style:
                                                                            _vm.menuItemStyle(
                                                                              item.active
                                                                            ),
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item.text
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(item.text)
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : item.counter
                                                    ? _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "subtitle-2",
                                                          style:
                                                            _vm.menuItemStyle(
                                                              item.active
                                                            ),
                                                        },
                                                        [
                                                          _c(
                                                            "v-badge",
                                                            {
                                                              staticClass:
                                                                "ma-0",
                                                              attrs: {
                                                                inline: "",
                                                                bordered: "",
                                                                dot: _vm.approvalDot,
                                                                color: "error",
                                                                content:
                                                                  item.counter,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.text
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "subtitle-2",
                                                          style:
                                                            _vm.menuItemStyle(
                                                              item.active
                                                            ),
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.text
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.manReportMenu.length > 0
                    ? _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v(" Management Reports "),
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-list",
                                { attrs: { dense: "" } },
                                [
                                  _vm._l(_vm.manReportMenu, function (item) {
                                    return [
                                      item.heading
                                        ? _c(
                                            "v-row",
                                            {
                                              key: item.idx,
                                              attrs: {
                                                align: "center",
                                                "no-gutters": "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  item.heading
                                                    ? _c(
                                                        "v-subheader",
                                                        {
                                                          staticClass:
                                                            "text-uppercase",
                                                          style: `color:${_vm.$vuetify.theme.themes.light.menuText}`,
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.heading)
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : item.divider
                                        ? _c("v-divider", {
                                            key: item.idx,
                                            attrs: { dark: "" },
                                          })
                                        : _c(
                                            "v-list-item",
                                            {
                                              key: item.idx,
                                              class: `${
                                                item.disabled ? " opa-5" : ""
                                              }`,
                                              style: `${
                                                item.active
                                                  ? `background-color:${_vm.$vuetify.theme.themes.light.menuSelectedBack};color:${_vm.$vuetify.theme.themes.light.menuSelectedText};`
                                                  : ""
                                              }`,
                                              attrs: {
                                                link: "",
                                                dark: item.active,
                                                disabled: item.disabled,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onMenuClick(item)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-action",
                                                { staticClass: "ma-0 mr-5" },
                                                [
                                                  _c(
                                                    "v-badge",
                                                    {
                                                      staticClass: "ma-0",
                                                      attrs: {
                                                        color: "info",
                                                        content: item.counter,
                                                        value: item.counter,
                                                        "offset-y": 10,
                                                        "offset-x": 10,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: `${
                                                              item.active
                                                                ? _vm.$vuetify
                                                                    .theme
                                                                    .themes
                                                                    .light
                                                                    .menuSelectedText
                                                                : _vm.$vuetify
                                                                    .theme
                                                                    .themes
                                                                    .light
                                                                    .menuText
                                                            }`,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.icon)
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  item.tip
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { right: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "subtitle-2",
                                                                          style:
                                                                            _vm.menuItemStyle(
                                                                              item.active
                                                                            ),
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item.text
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(item.text)
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : item.counter
                                                    ? _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "subtitle-2",
                                                          style:
                                                            _vm.menuItemStyle(
                                                              item.active
                                                            ),
                                                        },
                                                        [
                                                          _c(
                                                            "v-badge",
                                                            {
                                                              staticClass:
                                                                "ma-0",
                                                              attrs: {
                                                                inline: "",
                                                                bordered: "",
                                                                dot: _vm.approvalDot,
                                                                color: "error",
                                                                content:
                                                                  item.counter,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.text
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "subtitle-2",
                                                          style:
                                                            _vm.menuItemStyle(
                                                              item.active
                                                            ),
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.text
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.navigation.length > 0
                    ? _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [_vm._v(" Forms ")]),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _vm.navigation.length > 1
                                ? _c("v-text-field", {
                                    attrs: {
                                      label: "Search Forms",
                                      outline: "",
                                      solo: "",
                                      "hide-details": "",
                                      clearable: "",
                                      "clear-icon": "mdi-close-circle-outline",
                                    },
                                    model: {
                                      value: _vm.searchForm,
                                      callback: function ($$v) {
                                        _vm.searchForm = $$v
                                      },
                                      expression: "searchForm",
                                    },
                                  })
                                : _vm._e(),
                              _c("v-treeview", {
                                attrs: {
                                  items: _vm.navigation,
                                  color: "primary",
                                  dense: "",
                                  activatable: "",
                                  hoverable: "",
                                  search: _vm.searchForm,
                                  filter: _vm.filter,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend",
                                      fn: function ({ item }) {
                                        return [
                                          item.count > 0
                                            ? _c("v-badge", {
                                                staticClass: "ma-0",
                                                attrs: {
                                                  inline: "",
                                                  bordered: "",
                                                  dot: _vm.approvalDot,
                                                  color: "error",
                                                  content: item.count,
                                                },
                                              })
                                            : _vm._e(),
                                          item.Icon !== "" &&
                                          item.type !== "Group"
                                            ? _c("v-icon", [
                                                _vm._v(
                                                  " " + _vm._s(item.Icon) + " "
                                                ),
                                              ])
                                            : _vm._e(),
                                          item.Icon === "" &&
                                          item.type !== "Group"
                                            ? _c("v-icon", [_vm._v("mdi-poll")])
                                            : _vm._e(),
                                          item.type === "Group"
                                            ? _c("v-icon", [
                                                _vm._v("mdi-folder"),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                    {
                                      key: "label",
                                      fn: function ({ item }) {
                                        return [
                                          item.route
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "text-overflow": "ellipsis",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onMenuClick(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(_vm._s(item.name)),
                                                  ]),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "text-overflow": "ellipsis",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.name))]
                                              ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1861385375
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-main",
        [
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [_c("router-view", { key: _vm.$route.fullPath })],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800" },
          model: {
            value: _vm.projectPop,
            callback: function ($$v) {
              _vm.projectPop = $$v
            },
            expression: "projectPop",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Select Category"),
              ]),
              _c(
                "v-card-text",
                { staticClass: "pb-0" },
                [
                  _c(
                    "v-row",
                    { staticClass: "row-list" },
                    [
                      _c(
                        "v-col",
                        { attrs: { sm: "12" } },
                        [
                          _c("v-text-field", {
                            staticClass: "ml-3 mr-3 mb-3",
                            attrs: {
                              autofocus: "",
                              dense: "",
                              "single-line": "",
                              placeholder: "Filter text ...",
                              hint: "Type to filter the project list",
                              "prepend-icon": "mdi-magnify",
                              "persistent-hint": "",
                            },
                            model: {
                              value: _vm.projectText,
                              callback: function ($$v) {
                                _vm.projectText = $$v
                              },
                              expression: "projectText",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-simple-table", {
                    attrs: { "fixed-header": "", height: "500" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { staticClass: "text-left" }, [
                                  _vm._v("Project"),
                                ]),
                                _c("th", { staticClass: "text-left" }, [
                                  _vm._v("Status"),
                                ]),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.projectList, function (item) {
                                return _c(
                                  "tr",
                                  {
                                    key: item._id,
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectProject(item._id)
                                      },
                                    },
                                  },
                                  [
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-medium subtitle-1",
                                        },
                                        [_vm._v(_vm._s(item.Name))]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "caption opa-5 mt-n2" },
                                        [_vm._v(_vm._s(item.Comment))]
                                      ),
                                    ]),
                                    _c("td", [
                                      _c("span", {
                                        class: `color-circle-s mr-1 ${_vm.$getStatusColor(
                                          item.StatusId
                                        )}`,
                                      }),
                                      _vm._v(
                                        _vm._s(
                                          _vm.$getStatusText(item.StatusId)
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.projectPop = false
                        },
                      },
                    },
                    [_vm._v(" Close ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.confirm.show,
            callback: function ($$v) {
              _vm.$set(_vm.confirm, "show", $$v)
            },
            expression: "confirm.show",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.confirm.title)),
              ]),
              _c("v-card-text", [
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.confirm.text) },
                }),
                _vm.confirm.additionalActions
                  ? _c(
                      "span",
                      { staticClass: "ml-3 mr-3" },
                      [
                        _c(
                          "v-radio-group",
                          {
                            model: {
                              value: _vm.confirm.additionalActionValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.confirm,
                                  "additionalActionValue",
                                  $$v
                                )
                              },
                              expression: "confirm.additionalActionValue",
                            },
                          },
                          _vm._l(_vm.confirm.additionalActions, function (n) {
                            return _c("v-radio", {
                              key: n.id,
                              attrs: { label: n.text, value: n.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("br"),
                _vm.confirm.value
                  ? _c("b", [_vm._v(_vm._s(_vm.confirm.value))])
                  : _vm._e(),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.onConfirm(true)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("general.confirm")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.onConfirm(false)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("general.cancel")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.prompt.show,
            callback: function ($$v) {
              _vm.$set(_vm.prompt, "show", $$v)
            },
            expression: "prompt.show",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.prompt.title)),
              ]),
              _c(
                "v-card-text",
                [
                  _vm._v(" " + _vm._s(_vm.prompt.text) + " "),
                  _vm.prompt.show && Array.isArray(_vm.prompt.options)
                    ? _c(
                        "v-radio-group",
                        {
                          model: {
                            value: _vm.prompt.value,
                            callback: function ($$v) {
                              _vm.$set(_vm.prompt, "value", $$v)
                            },
                            expression: "prompt.value",
                          },
                        },
                        _vm._l(_vm.prompt.options, function (item) {
                          return _c("v-radio", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.prompt.show &&
                  !Array.isArray(_vm.prompt.options) &&
                  !_vm.prompt.isPassword
                    ? _c("v-text-field", {
                        attrs: { clearable: "", autofocus: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            return _vm.onPromptKey.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.prompt.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.prompt, "value", $$v)
                          },
                          expression: "prompt.value",
                        },
                      })
                    : _vm._e(),
                  _vm.prompt.show &&
                  !Array.isArray(_vm.prompt.options) &&
                  _vm.prompt.isPassword === true
                    ? _c("v-text-field", {
                        attrs: {
                          "append-icon": _vm.pwdshow
                            ? "mdi-eye"
                            : "mdi-eye-off",
                          type: _vm.pwdshow ? "text" : "password",
                          clearable: "",
                          autofocus: "",
                        },
                        on: {
                          "click:append": function ($event) {
                            _vm.pwdshow = !_vm.pwdshow
                          },
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            return _vm.onPromptKey.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.prompt.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.prompt, "value", $$v)
                          },
                          expression: "prompt.value",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.onPromptClick(false)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("general.cancel")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.onPromptClick(true)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.prompt.okText || _vm.$t("general.ok")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "300" },
          model: {
            value: _vm.processing.show,
            callback: function ($$v) {
              _vm.$set(_vm.processing, "show", $$v)
            },
            expression: "processing.show",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { color: "primary", dark: "" } },
            [
              _c(
                "v-card-text",
                [
                  _vm._v(" " + _vm._s(_vm.processing.text) + " "),
                  _c("v-progress-linear", {
                    staticClass: "mb-0",
                    attrs: { indeterminate: "", color: "white" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          staticClass: "global-alert",
          attrs: {
            top: "",
            color: _vm.snack.color,
            "multi-line": false,
            timeout: _vm.snack.timeout,
            vertical: true,
          },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function ({ attrs }) {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex", staticStyle: { width: "100%" } },
                    [
                      _vm.snack.timeoutCounter
                        ? _c("span", { staticClass: "opa-5 mt-6" }, [
                            _vm._v(_vm._s(_vm.snack.timeoutCounter) + "s"),
                          ])
                        : _vm._e(),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        _vm._b(
                          {
                            staticClass: "mt-2",
                            attrs: { dark: "" },
                            on: {
                              click: function ($event) {
                                _vm.snack.show = false
                              },
                            },
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        [_vm._v(" " + _vm._s(_vm.$t("general.ok")) + " ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.snack.show,
            callback: function ($$v) {
              _vm.$set(_vm.snack, "show", $$v)
            },
            expression: "snack.show",
          },
        },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.snack.title)),
          ]),
          _c("div", {
            staticClass: "subheading",
            domProps: { innerHTML: _vm._s(_vm.snack.text) },
          }),
        ]
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "auto", persistent: "" },
          model: {
            value: _vm.hasAppUpdate,
            callback: function ($$v) {
              _vm.hasAppUpdate = $$v
            },
            expression: "hasAppUpdate",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", [
                _c(
                  "p",
                  { staticClass: "text-md-center" },
                  [
                    _c("v-icon", { attrs: { large: "" } }, [
                      _vm._v("mdi-update"),
                    ]),
                  ],
                  1
                ),
                _c("p", [
                  _vm._v(
                    " A new application version is available. Please click on the below button to update your client. "
                  ),
                ]),
              ]),
              _c(
                "v-card-actions",
                [
                  _vm.hasAppUpdate
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "error",
                          attrs: { block: "", small: "" },
                          on: { click: _vm.onAppUpdate },
                        },
                        [_vm._v("Update Application")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-overlay",
        {
          model: {
            value: _vm.isBusy,
            callback: function ($$v) {
              _vm.isBusy = $$v
            },
            expression: "isBusy",
          },
        },
        [
          _c(
            "div",
            [
              _c("v-progress-circular", {
                attrs: {
                  size: 70,
                  width: 7,
                  color: "black",
                  indeterminate: "",
                },
              }),
              _c(
                "p",
                { staticClass: "text-center", staticStyle: { color: "black" } },
                [
                  _c("b", [_vm._v("Loading ...")]),
                  _c("br"),
                  _vm.refreshTimer
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "black", "x-small": "" },
                          on: { click: _vm.refreshShell },
                        },
                        [_vm._v("Refresh App")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }