var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.fullWidthBarcode
    ? _c(
        "div",
        {
          staticClass: "scanner-container",
          style: { width: "100%", height: "100%" },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isLoading,
                  expression: "!isLoading",
                },
              ],
            },
            [
              _c("video", {
                ref: "scannervideo",
                staticStyle: { width: "100%", height: "100%" },
                attrs: { poster: "data:image/gif,AAAA" },
              }),
              _c("div", { staticClass: "laser" }),
            ]
          ),
        ]
      )
    : _c(
        "div",
        {
          staticClass: "scanner-container",
          style: { width: `${_vm.width}px`, height: `${_vm.height}px` },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isLoading,
                  expression: "!isLoading",
                },
              ],
            },
            [
              _c("video", {
                ref: "scannervideo",
                attrs: { poster: "data:image/gif,AAAA" },
              }),
              _c("div", { staticClass: "laser" }),
            ]
          ),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }