var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c("v-file-input", {
            ref: "upload",
            staticClass: "ma-0 pa-0",
            attrs: {
              rules: _vm.rules,
              accept: _vm.allowedUploadType,
              placeholder: _vm.placeholder,
              clearable: "",
              "show-size": "",
              "truncate-length": "200",
            },
            on: {
              change: _vm.onChange,
              "update:error": function ($event) {
                return _vm.$emit("update:validState", $event)
              },
            },
            model: {
              value: _vm.localData,
              callback: function ($$v) {
                _vm.localData = $$v
              },
              expression: "localData",
            },
          }),
        ],
        1
      ),
      _vm.isImageUploadType
        ? _c(
            "v-row",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-col",
                [
                  _vm.imageData === null
                    ? _c("v-img", {
                        attrs: {
                          src: _vm.noImageData.src,
                          contain: "",
                          height: _vm.noImageHeight,
                        },
                      })
                    : _vm._e(),
                  _vm.imageData !== null
                    ? _c("v-img", {
                        key: _vm.imageData.src,
                        attrs: {
                          src: _vm.imageData.src,
                          error: _vm.noImage,
                          contain: "",
                          "max-height": _vm.imageHeight,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isImageUploadType && _vm.hasFilename
        ? _c(
            "v-row",
            { staticClass: "d-flex justify-center" },
            [
              _c("v-btn", { on: { click: _vm.formDocumentClick } }, [
                _vm._v(" View "),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }