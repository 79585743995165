var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "mb-0 pb-0", attrs: { sm: "12" } }, [
            _c(
              "h1",
              { staticClass: "title font-weight-light" },
              [
                this.preview
                  ? _c("v-icon", { on: { click: _vm.onBack } }, [
                      _vm._v("mdi-arrow-left-circle"),
                    ])
                  : _vm._e(),
                _vm._v(" " + _vm._s(this.item.Name) + " "),
                _c("span", {
                  staticClass: "font-weight-light subtitle-1",
                  domProps: {
                    innerHTML: _vm._s(
                      this.preview
                        ? "<b class=accent--text>PREVIEW</b>. No answers will be saved"
                        : ""
                    ),
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "v-col",
            { staticClass: "mt-0 pt-0 w-1024", attrs: { sm: "12" } },
            [
              _c(
                "v-card-actions",
                { staticClass: "ma-0 pa-0" },
                [
                  _c("span", { staticClass: "font-weight-light f-m" }, [
                    _vm._v(_vm._s(_vm.user.FullName)),
                  ]),
                  _c("v-spacer"),
                  _c("span", { staticClass: "font-weight-light f-s" }, [
                    _vm._v(_vm._s(_vm.$format.dateTime(new Date()))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-0" },
        [
          _c(
            "v-col",
            { attrs: { sm: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "w-1024", attrs: { loading: _vm.isBusy } },
                [
                  _c(
                    "v-container",
                    [
                      _c("v-card-text", [
                        _vm.item.ShowStart
                          ? _c("div", {
                              staticClass: "question-label",
                              domProps: {
                                innerHTML: _vm._s(_vm.item.StartMessage),
                              },
                            })
                          : _vm._e(),
                      ]),
                      _c(
                        "v-card-text",
                        { staticStyle: { "min-height": "300px" } },
                        _vm._l(this.item.Questions, function (question) {
                          return _c("div", { key: question._id }, [
                            _c("div", {
                              staticClass: "question-label-form",
                              domProps: {
                                innerHTML: _vm._s(question.Question[0].Value),
                              },
                            }),
                            _c(
                              "div",
                              [
                                question.InputType ===
                                  _vm.$CONST.INPUT_TYPE.Text &&
                                question.Lines < 2
                                  ? _c("v-text-field", {
                                      attrs: {
                                        counter: question.Max || false,
                                        minlength: question.Min || false,
                                        maxlength: question.Max || false,
                                        prefix: question.Pre,
                                        suffix: question.Post,
                                        placeholder: question.InHint,
                                        hint: question.OutHint,
                                        "persistent-hint": "",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.onAnswerBlur(
                                            question.Index
                                          )
                                        },
                                      },
                                      model: {
                                        value: question.Answer,
                                        callback: function ($$v) {
                                          _vm.$set(question, "Answer", $$v)
                                        },
                                        expression: "question.Answer",
                                      },
                                    })
                                  : question.InputType ===
                                      _vm.$CONST.INPUT_TYPE.Text &&
                                    question.Lines > 1
                                  ? _c("v-textarea", {
                                      attrs: {
                                        rows: question.Lines || 2,
                                        counter: question.Max || false,
                                        minlength: question.Min || false,
                                        maxlength: question.Max || false,
                                        prefix: question.Pre,
                                        suffix: question.Post,
                                        placeholder: question.InHint,
                                        hint: question.OutHint,
                                        "persistent-hint": "",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.onAnswerBlur(
                                            question.Index
                                          )
                                        },
                                      },
                                      model: {
                                        value: question.Answer,
                                        callback: function ($$v) {
                                          _vm.$set(question, "Answer", $$v)
                                        },
                                        expression: "question.Answer",
                                      },
                                    })
                                  : question.InputType ===
                                    _vm.$CONST.INPUT_TYPE.Number
                                  ? _c(
                                      "div",
                                      [
                                        _c("v-numeric", {
                                          attrs: {
                                            maxlength: 11,
                                            prefix: question.Pre,
                                            suffix: question.Post,
                                            placeholder: question.InHint,
                                            hint: question.OutHint,
                                            "persistent-hint": "",
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.onAnswerBlur(
                                                question.Index
                                              )
                                            },
                                          },
                                          model: {
                                            value: question.Answer,
                                            callback: function ($$v) {
                                              _vm.$set(question, "Answer", $$v)
                                            },
                                            expression: "question.Answer",
                                          },
                                        }),
                                        !_vm.isBlank(question.Min) ||
                                        !_vm.isBlank(question.Max)
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "caption ml-2 mt-0",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    !_vm.isBlank(
                                                      question.Min
                                                    ) &&
                                                      !_vm.isBlank(question.Max)
                                                      ? `Must be between ${question.Min} and ${question.Max}`
                                                      : _vm.isBlank(
                                                          question.Min
                                                        )
                                                      ? `Must not be greater than ${question.Max}`
                                                      : `Must not be less than ${question.Min}`
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : question.InputType ===
                                    _vm.$CONST.INPUT_TYPE.Mobile
                                  ? _c("v-mask", {
                                      attrs: {
                                        prefix: question.Pre,
                                        suffix: question.Post,
                                        placeholder: question.InHint,
                                        hint: question.OutHint,
                                        "prepend-icon": "mdi-cellphone",
                                        "persistent-hint": "",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.onAnswerBlur(
                                            question.Index
                                          )
                                        },
                                      },
                                      model: {
                                        value: question.Answer,
                                        callback: function ($$v) {
                                          _vm.$set(question, "Answer", $$v)
                                        },
                                        expression: "question.Answer",
                                      },
                                    })
                                  : question.InputType ===
                                    _vm.$CONST.INPUT_TYPE.Email
                                  ? _c("v-text-field", {
                                      attrs: {
                                        counter: question.Max || false,
                                        minlength: question.Min || false,
                                        maxlength: question.Max || false,
                                        prefix: question.Pre,
                                        suffix: question.Post,
                                        placeholder: question.InHint,
                                        hint: question.OutHint,
                                        rules: [_vm.rules.email],
                                        "prepend-icon":
                                          "mdi-email-open-outline",
                                        "persistent-hint": "",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.onAnswerBlur(
                                            question.Index
                                          )
                                        },
                                      },
                                      model: {
                                        value: question.Answer,
                                        callback: function ($$v) {
                                          _vm.$set(question, "Answer", $$v)
                                        },
                                        expression: "question.Answer",
                                      },
                                    })
                                  : question.InputType ===
                                    _vm.$CONST.INPUT_TYPE.WebAddress
                                  ? _c("v-text-field", {
                                      attrs: {
                                        counter: question.Max || false,
                                        minlength: question.Min || false,
                                        maxlength: question.Max || false,
                                        prefix: question.Pre,
                                        suffix: question.Post,
                                        placeholder: question.InHint,
                                        hint: question.OutHint,
                                        rules: [_vm.rules.web],
                                        "prepend-icon": "mdi-web",
                                        "persistent-hint": "",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.onAnswerBlur(
                                            question.Index
                                          )
                                        },
                                      },
                                      model: {
                                        value: question.Answer,
                                        callback: function ($$v) {
                                          _vm.$set(question, "Answer", $$v)
                                        },
                                        expression: "question.Answer",
                                      },
                                    })
                                  : question.InputType ===
                                    _vm.$CONST.INPUT_TYPE.YesNo
                                  ? _c(
                                      "v-btn-toggle",
                                      {
                                        attrs: {
                                          color: "accent",
                                          group: "",
                                          tile: "",
                                        },
                                        model: {
                                          value: question.Answer,
                                          callback: function ($$v) {
                                            _vm.$set(question, "Answer", $$v)
                                          },
                                          expression: "question.Answer",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "pl-11 pr-11",
                                            attrs: { value: true },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.onAnswerBlur(
                                                  question.Index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" Yes ")]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "pl-12 pr-12",
                                            attrs: { value: false },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.onAnswerBlur(
                                                  question.Index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" No ")]
                                        ),
                                      ],
                                      1
                                    )
                                  : question.InputType ===
                                    _vm.$CONST.INPUT_TYPE.SelectOne
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "v-radio-group",
                                          {
                                            on: {
                                              change: function ($event) {
                                                return _vm.onAnswerBlur(
                                                  question.Index
                                                )
                                              },
                                            },
                                            model: {
                                              value: question.Answer,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  question,
                                                  "Answer",
                                                  $$v
                                                )
                                              },
                                              expression: "question.Answer",
                                            },
                                          },
                                          _vm._l(
                                            question.Options,
                                            function (item) {
                                              return _c("v-radio", {
                                                key: item._id,
                                                attrs: {
                                                  label: item.Value,
                                                  value: item.Value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                        _c("span", { staticClass: "caption" }, [
                                          _vm._v(_vm._s(question.OutHint)),
                                        ]),
                                      ],
                                      1
                                    )
                                  : question.InputType ===
                                    _vm.$CONST.INPUT_TYPE.SelectMany
                                  ? _c(
                                      "div",
                                      [
                                        _vm._l(
                                          question.Options,
                                          function (item) {
                                            return _c("v-checkbox", {
                                              key: item._id,
                                              staticClass: "mt-1",
                                              attrs: {
                                                label: item.Value,
                                                value: item.Value,
                                                multiple: "",
                                                "hide-details": "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onAnswerBlur(
                                                    question.Index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: question.Answer,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    question,
                                                    "Answer",
                                                    $$v
                                                  )
                                                },
                                                expression: "question.Answer",
                                              },
                                            })
                                          }
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "caption mt-6 d-block",
                                          },
                                          [_vm._v(_vm._s(question.OutHint))]
                                        ),
                                      ],
                                      2
                                    )
                                  : question.InputType ===
                                    _vm.$CONST.INPUT_TYPE.Date
                                  ? _c(
                                      "v-menu",
                                      {
                                        attrs: {
                                          "close-on-content-click": false,
                                          "nudge-right": 32,
                                          transition: "scale-transition",
                                          "offset-y": "",
                                          "min-width": "290px",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          placeholder:
                                                            question.InHint,
                                                          hint: question.OutHint,
                                                          "prepend-icon":
                                                            "mdi-calendar",
                                                          "persistent-hint": "",
                                                          readonly: "",
                                                        },
                                                        on: {
                                                          blur: function (
                                                            $event
                                                          ) {
                                                            return _vm.onAnswerBlur(
                                                              question.Index
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            question.Answer,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              question,
                                                              "Answer",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "question.Answer",
                                                        },
                                                      },
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.modalDate,
                                          callback: function ($$v) {
                                            _vm.modalDate = $$v
                                          },
                                          expression: "modalDate",
                                        },
                                      },
                                      [
                                        _c("v-date-picker", {
                                          on: {
                                            input: function ($event) {
                                              _vm.modalDate = false
                                            },
                                          },
                                          model: {
                                            value: question.Answer,
                                            callback: function ($$v) {
                                              _vm.$set(question, "Answer", $$v)
                                            },
                                            expression: "question.Answer",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : question.InputType ===
                                    _vm.$CONST.INPUT_TYPE.Time
                                  ? _c(
                                      "v-menu",
                                      {
                                        ref: "menu",
                                        refInFor: true,
                                        attrs: {
                                          "close-on-content-click": false,
                                          "nudge-right": 32,
                                          transition: "scale-transition",
                                          "offset-y": "",
                                          "max-width": "290px",
                                          "min-width": "290px",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          placeholder:
                                                            question.InHint,
                                                          hint: question.OutHint,
                                                          "prepend-icon":
                                                            "mdi-clock-outline",
                                                          format: "24hr",
                                                          scrollable: "",
                                                          "persistent-hint": "",
                                                          readonly: "",
                                                        },
                                                        on: {
                                                          blur: function (
                                                            $event
                                                          ) {
                                                            return _vm.onAnswerBlur(
                                                              question.Index
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            question.Answer,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              question,
                                                              "Answer",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "question.Answer",
                                                        },
                                                      },
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.modalTime,
                                          callback: function ($$v) {
                                            _vm.modalTime = $$v
                                          },
                                          expression: "modalTime",
                                        },
                                      },
                                      [
                                        _c("v-time-picker", {
                                          on: {
                                            "click:minute": function ($event) {
                                              _vm.modalTime = false
                                            },
                                          },
                                          model: {
                                            value: question.Answer,
                                            callback: function ($$v) {
                                              _vm.$set(question, "Answer", $$v)
                                            },
                                            expression: "question.Answer",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : question.InputType ===
                                    _vm.$CONST.INPUT_TYPE.DateOfBirth
                                  ? _c(
                                      "v-menu",
                                      {
                                        attrs: {
                                          "close-on-content-click": false,
                                          "nudge-right": 32,
                                          transition: "scale-transition",
                                          "offset-y": "",
                                          "min-width": "290px",
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.onAnswerBlur(
                                              question.Index
                                            )
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          placeholder:
                                                            question.InHint,
                                                          hint: question.OutHint,
                                                          "prepend-icon":
                                                            "mdi-gift-outline",
                                                          "persistent-hint": "",
                                                          readonly: "",
                                                        },
                                                        model: {
                                                          value:
                                                            question.Answer,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              question,
                                                              "Answer",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "question.Answer",
                                                        },
                                                      },
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.modalDOB,
                                          callback: function ($$v) {
                                            _vm.modalDOB = $$v
                                          },
                                          expression: "modalDOB",
                                        },
                                      },
                                      [
                                        _c("v-date-picker", {
                                          ref: "previewDOB",
                                          refInFor: true,
                                          attrs: {
                                            max: new Date()
                                              .toISOString()
                                              .substr(0, 10),
                                          },
                                          on: {
                                            input: function ($event) {
                                              _vm.modalDOB = false
                                            },
                                          },
                                          model: {
                                            value: question.Answer,
                                            callback: function ($$v) {
                                              _vm.$set(question, "Answer", $$v)
                                            },
                                            expression: "question.Answer",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : question.InputType ===
                                    _vm.$CONST.INPUT_TYPE.Rating
                                  ? _c(
                                      "div",
                                      [
                                        _c("v-rating", {
                                          attrs: {
                                            length: question.Max || 5,
                                            color: "accent",
                                            "background-color":
                                              "grey lighten-1",
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.onAnswerBlur(
                                                question.Index
                                              )
                                            },
                                          },
                                          model: {
                                            value: question.Answer,
                                            callback: function ($$v) {
                                              _vm.$set(question, "Answer", $$v)
                                            },
                                            expression: "question.Answer",
                                          },
                                        }),
                                        _c("span", { staticClass: "caption" }, [
                                          _vm._v(_vm._s(question.OutHint)),
                                        ]),
                                      ],
                                      1
                                    )
                                  : question.InputType ===
                                    _vm.$CONST.INPUT_TYPE.RangeSlider
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "v-row",
                                          { staticClass: "row-smaller mt-7" },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "d-flex",
                                                attrs: { sm: "12" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: question.Pre,
                                                        expression:
                                                          "question.Pre",
                                                      },
                                                    ],
                                                    staticClass: "body-1 mr-2",
                                                  },
                                                  [_vm._v(_vm._s(question.Pre))]
                                                ),
                                                _c("v-slider", {
                                                  attrs: {
                                                    "thumb-size": 24,
                                                    min: question.Min || 0,
                                                    max: question.Max || 10,
                                                    step: question.Step || 1,
                                                    hint: question.OutHint,
                                                    "thumb-label": "always",
                                                    ticks: "always",
                                                    "tick-size": "4",
                                                    color: "accent",
                                                    "persistent-hint": "",
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.onAnswerBlur(
                                                        question.Index
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: question.Answer,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        question,
                                                        "Answer",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "question.Answer",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: question.Post,
                                                        expression:
                                                          "question.Post",
                                                      },
                                                    ],
                                                    staticClass: "body-1 ml-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(question.Post)
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { staticClass: "row-smaller" },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "text-center display-1 accent--text",
                                                attrs: {
                                                  sm: "12",
                                                  "align-self": "center",
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(question.Answer)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : question.InputType ===
                                    _vm.$CONST.INPUT_TYPE.Image
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mr-2",
                                            attrs: { color: "primary" },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { left: "" } },
                                              [_vm._v("mdi-camera")]
                                            ),
                                            _vm._v(" Take Picture "),
                                          ],
                                          1
                                        ),
                                        question.Local
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: { color: "primary" },
                                                on: {
                                                  click:
                                                    _vm.onFileLoadPhotoClick,
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { left: "" } },
                                                  [_vm._v("mdi-folder-image")]
                                                ),
                                                _vm._v(" Load Image "),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c("v-file-input", {
                                          ref: "fileLoadPhoto",
                                          refInFor: true,
                                          staticClass: "d-none",
                                          attrs: {
                                            accept: "image/*",
                                            label: "Load Image",
                                          },
                                          on: { change: _vm.onFileLoadChange },
                                          model: {
                                            value: question.ValueFile,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                question,
                                                "ValueFile",
                                                $$v
                                              )
                                            },
                                            expression: "question.ValueFile",
                                          },
                                        }),
                                        _c("br"),
                                        _c("v-img", {
                                          staticClass: "mt-2",
                                          attrs: { src: question.Answer },
                                        }),
                                        _c("span", { staticClass: "caption" }, [
                                          _vm._v(_vm._s(question.OutHint)),
                                        ]),
                                      ],
                                      1
                                    )
                                  : question.InputType ===
                                    _vm.$CONST.INPUT_TYPE.Signature
                                  ? _c(
                                      "div",
                                      [
                                        question.Local
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: { color: "primary" },
                                                on: {
                                                  click:
                                                    _vm.onFileLoadSignClick,
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { left: "" } },
                                                  [_vm._v("mdi-folder-image")]
                                                ),
                                                _vm._v(" Load Image "),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c("v-file-input", {
                                          ref: "fileLoadSign",
                                          refInFor: true,
                                          staticClass: "d-none",
                                          attrs: {
                                            accept: "image/*",
                                            label: "Load Image",
                                          },
                                          on: { change: _vm.onFileLoadChange },
                                          model: {
                                            value: question.ValueFile,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                question,
                                                "ValueFile",
                                                $$v
                                              )
                                            },
                                            expression: "question.ValueFile",
                                          },
                                        }),
                                        _c("br"),
                                        _c("v-img", {
                                          staticClass: "mt-2",
                                          attrs: { src: question.Answer },
                                        }),
                                        _c("span", { staticClass: "caption" }, [
                                          _vm._v(_vm._s(question.OutHint)),
                                        ]),
                                      ],
                                      1
                                    )
                                  : question.InputType ===
                                    _vm.$CONST.INPUT_TYPE.GPSLocation
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { color: "primary" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.getLocation(
                                                  question._id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { left: "" } },
                                              [_vm._v("mdi-map-marker-radius")]
                                            ),
                                            _vm._v(" Get Location "),
                                          ],
                                          1
                                        ),
                                        _c("br"),
                                        _c("span", { staticClass: "caption" }, [
                                          _vm._v(_vm._s(question.OutHint)),
                                        ]),
                                        _c(
                                          "v-row",
                                          { staticClass: "w-512-max mt-2" },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { sm: "4" } },
                                              [_vm._v("Coordinates")]
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "font-weight-bold",
                                                attrs: { sm: "8" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    question.Display || "-"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { staticClass: "w-512-max" },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { sm: "4" } },
                                              [_vm._v("Latitude")]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { sm: "8" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    question.DisplayLat || "-"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { staticClass: "w-512-max" },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { sm: "4" } },
                                              [_vm._v("Longitude")]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { sm: "8" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    question.DisplayLon || "-"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { staticClass: "w-512-max" },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { sm: "4" } },
                                              [_vm._v("Accuracy")]
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                class: question.DisplayAccFail
                                                  ? "error--text"
                                                  : "",
                                                attrs: { sm: "8" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    question.DisplayAcc || "-"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { staticClass: "w-512-max" },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { sm: "4" } },
                                              [_vm._v("Altitude")]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { sm: "8" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    question.DisplayAlt || "-"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : question.InputType ===
                                    _vm.$CONST.INPUT_TYPE.Barcode
                                  ? _c(
                                      "div",
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            "prepend-icon": "mdi-qrcode-scan",
                                            readonly: !question.Manual,
                                            prefix: question.Pre,
                                            suffix: question.Post,
                                            placeholder: question.InHint,
                                            hint: question.OutHint,
                                            "persistent-hint": "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "append-outer",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.scanBarcode,
                                                        },
                                                      },
                                                      [_vm._v("Scan")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: question.Answer,
                                            callback: function ($$v) {
                                              _vm.$set(question, "Answer", $$v)
                                            },
                                            expression: "question.Answer",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : question.InputType ===
                                    _vm.$CONST.INPUT_TYPE.FileUpload
                                  ? _c(
                                      "div",
                                      [
                                        _c("v-fileupload", {
                                          key: question._id,
                                          attrs: {
                                            parentData: question.ValueFile,
                                            validState:
                                              _vm.questionErrors[
                                                question.Field
                                              ],
                                            parentImageData:
                                              _vm.imageData[question.Field],
                                            uploadType: question.UploadType,
                                            uploadSizeLimit:
                                              question.UploadSizeLimit,
                                            placeholder: question.InHint,
                                            isOptional: question.Optional,
                                          },
                                          on: {
                                            "update:parentData": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                question,
                                                "ValueFile",
                                                $event
                                              )
                                            },
                                            "update:parent-data": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                question,
                                                "ValueFile",
                                                $event
                                              )
                                            },
                                            "update:validState": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                _vm.questionErrors,
                                                question.Field,
                                                $event
                                              )
                                            },
                                            "update:valid-state": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                _vm.questionErrors,
                                                question.Field,
                                                $event
                                              )
                                            },
                                            "update:parentImageData": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                _vm.imageData,
                                                question.Field,
                                                $event
                                              )
                                            },
                                            "update:parent-image-data":
                                              function ($event) {
                                                return _vm.$set(
                                                  _vm.imageData,
                                                  question.Field,
                                                  $event
                                                )
                                              },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ])
                        }),
                        0
                      ),
                      _c("v-card-text", [
                        _vm.item.ShowEnd
                          ? _c("div", {
                              staticClass: "question-label",
                              domProps: {
                                innerHTML: _vm._s(_vm.item.EndMessage),
                              },
                            })
                          : _vm._e(),
                      ]),
                      _c(
                        "v-alert",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.answerNotValid,
                              expression: "answerNotValid",
                            },
                          ],
                          attrs: { type: "error" },
                        },
                        [
                          _vm._v(
                            " Answer validation failed. Please review your answer. "
                          ),
                        ]
                      ),
                      _vm.isSaveDone
                        ? _c(
                            "div",
                            { staticClass: "text-right" },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticStyle: { "font-size": "100px" },
                                  attrs: { color: "success" },
                                },
                                [_vm._v("mdi-check")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isSaveDone ? _c("v-divider") : _vm._e(),
                      !_vm.isSaveDone
                        ? _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _vm.item.RatingTotal
                                ? _c(
                                    "v-chip",
                                    {
                                      staticClass: "mr-2",
                                      attrs: {
                                        label: "",
                                        title: "Rating Total",
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v("mdi-calculator-variant"),
                                      ]),
                                      _vm._v(
                                        " " + _vm._s(_vm.ratingTotal) + " "
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    disabled: _vm.isSaving,
                                    loading: _vm.isSaving,
                                  },
                                  on: { click: _vm.onSave },
                                },
                                [
                                  _vm._v("Submit"),
                                  _c("v-icon", [_vm._v("mdi-flag-checkered")]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      this.preview
        ? _c(
            "v-row",
            { staticClass: "mt-0" },
            [
              _c(
                "v-col",
                { attrs: { sm: "12" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.onBack },
                        },
                        [_vm._v("Back")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }