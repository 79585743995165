<template>
    <div v-if="gridHasElements()">
        <p v-if="prefix">{{ prefix }}</p>
        <v-card class="pa-2" outlined tile>
        <v-row class="row-smaller mb-1 white" no-gutters>
            <v-col cols="12" sm="12" class="d-flex justify-center align-center">
                <v-menu bottom right offset-y>
                    <template v-slot:activator="{ on }">
                        <!-- <v-icon v-on="on" class="error--text mr-2">mdi-delete-outline</v-icon> -->
                        <v-btn text v-on="on" class="primary--text mr-2"><v-icon>mdi-toggle-switch-outline</v-icon>Toggle All</v-btn>
                    </template>
                    <v-btn color="error" @click="selectAll()">
                        <v-icon class="mr-2">mdi-alert-decagram</v-icon>
                        Are you sure?
                    </v-btn>
                </v-menu>
            </v-col>
        </v-row>
        <v-row v-for='gridPair in gridPair' :key='gridPair._id' class="row-smaller mb-1 grey lighten-5" no-gutters>
            <v-col cols="10" sm="11">
                    <p class="mt-3 ml-0">{{ gridPair.Question }} </p>
            </v-col>
            <v-col cols="2" sm="1" class="d-flex justify-center align-center">
                    <v-switch v-model='gridPair.Value' @change="valuesChanged()"></v-switch>
            </v-col>
        </v-row>
        </v-card>
        <br/>
        <p v-if="suffix">{{ suffix }}</p>
    </div>
    <div v-else>
        <v-card class="pa-2" outlined tile>
            No data available
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'v-xorgrid',
    props: {
        header: String,
        prefix: String,
        suffix: String,
        actionLabel: String,
        gridData: Array
    },
    data () {
        return {
            gridPair: [],
            validResult: false,
            toggleAll: false
        };
    },
    methods: {
        forceUpdate (passedData) {
            this.gridPair = passedData;
        },
        selectAll () {
            this.toggleAll = !this.toggleAll;
            this.gridPair.forEach((x, i) => {
                this.gridPair[i].Value = !this.gridPair[i].Value;
                this.valuesChanged();
            });
        },
        valuesChanged () {
            this.gridPair = this._data.gridPair;
            // Get the answer of the XOR equation
            this.validResult = false;
            let found = true;
            this.gridPair.forEach((x, i) => {
                if (x.Value === false || x.Value === '' || x.Value === undefined) found = false;
            });
            this.validResult = found;
            this.$emit('valueChanged', this.gridPair);
            this.$emit('validResultChanged', this.validResult);
        },
        gridHasElements () {
            return this.gridPair.length > 0;
        }

    },
    mounted () {
        if (this.gridData !== undefined) {
            this.gridPair = this.gridData;
            this.validResult = false;
            let found = true;
            this.gridPair.forEach((x, i) => {
                if (x.Value === false) found = false;
            });
            this.validResult = found;
            // Set default toggle for this question
            const size = this.gridPair.length;
            this.toggleAll = false;
            for (let index = 0; index < size; index++) {
                const element = this.gridPair[index];
                if (element.Value !== undefined) {
                    if (element.Value === true) {
                        this.toggleAll = true;
                        break;
                    }
                }
            }
        }
    },
    watch: {
        gridData (newVal, oldVal) {
            this.gridPair = newVal;
            this.validResult = false;
            let found = true;
            this.gridPair.forEach((x, i) => {
                if (x.Value === false || x.Value === '' || x.Value === undefined) found = false;
            });
            this.validResult = found;
            this.$emit('valueChanged', this.gridPair);
            this.$emit('validResultChanged', this.validResult);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
