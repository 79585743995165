var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._b({}, "div", _vm.$attrs, false),
    [
      _c(
        "span",
        { staticClass: "caption mr-2", staticStyle: { "margin-top": "2px" } },
        [_vm._v("Filters")]
      ),
      _c(
        "v-menu",
        {
          attrs: {
            bottom: "",
            "offset-y": "",
            "nudge-top": "-10",
            "close-on-content-click": false,
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-chip",
                    _vm._g(
                      {
                        staticClass: "mr-1 mb-1",
                        attrs: { label: "", small: "", color: "primary" },
                      },
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { small: "", color: "yellow" } }, [
                        _vm._v("mdi-plus"),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.filterMenu,
            callback: function ($$v) {
              _vm.filterMenu = $$v
            },
            expression: "filterMenu",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { width: "680" } },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { staticClass: "border-bottom" },
                    [
                      _c(
                        "v-col",
                        { attrs: { sm: "5" } },
                        [
                          _c("v-select", {
                            ref: "filterQuestion",
                            attrs: {
                              label: "Question",
                              items: _vm.filterFields,
                              "item-text": "text",
                              "item-value": "value",
                              autofocus: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    _c("v-icon", { staticClass: "mr-2" }, [
                                      _vm._v(_vm._s(item.inIcon)),
                                    ]),
                                    _vm._v(_vm._s(item.text) + " "),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.filterQuestion,
                              callback: function ($$v) {
                                _vm.filterQuestion = $$v
                              },
                              expression: "filterQuestion",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column justify-center",
                          attrs: { sm: "1" },
                        },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "open-on-hover": "",
                                bottom: "",
                                "offset-y": "",
                                "nudge-left": "38",
                                disabled: _vm.filterQuestion == null,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "pl-0 pr-0",
                                            staticStyle: {
                                              "min-width": "40px",
                                            },
                                            attrs: {
                                              text: "",
                                              disabled:
                                                _vm.filterQuestion == null,
                                            },
                                          },
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(_vm.searchAt.icon)),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c(
                                "v-list",
                                {
                                  staticClass: "pl-2 pr-2",
                                  attrs: { dense: "" },
                                },
                                _vm._l(_vm.searchAtOptions, function (item) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: item.value,
                                      staticClass: "ma-0 pa-0",
                                      attrs: { link: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onSearchAt(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { staticClass: "mr-2" }, [
                                        _vm._v(_vm._s(item.icon)),
                                      ]),
                                      _vm._v(_vm._s(item.text) + " "),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { sm: "5" } },
                        [
                          _c(
                            _vm.filterValueControl,
                            _vm._b(
                              {
                                ref: "filterValue",
                                tag: "component",
                                attrs: {
                                  clearable: "",
                                  placeholder: "Filter value",
                                  list: _vm.filterValueOptions,
                                  many: _vm.filterValueMany,
                                  inputType: _vm.filterSubInput,
                                  hint: _vm.searchAtText,
                                  error: _vm.filterValueError,
                                  disabled: _vm.filterQuestion == null,
                                },
                                on: {
                                  "send-value": _vm.onFilterAdd,
                                  change: _vm.onFilterValueChange,
                                  focus: function ($event) {
                                    return $event.target.select()
                                  },
                                },
                                model: {
                                  value: _vm.filterValue,
                                  callback: function ($$v) {
                                    _vm.filterValue = $$v
                                  },
                                  expression: "filterValue",
                                },
                              },
                              "component",
                              _vm.$props,
                              false
                            )
                          ),
                          _vm._t("default"),
                        ],
                        2
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column justify-center",
                          attrs: { sm: "1" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: "",
                                disabled: _vm.filterQuestion == null,
                              },
                              on: { click: _vm.onFilterAdd },
                            },
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._l(_vm.filters, function (item) {
                    return _c(
                      "v-row",
                      { key: item._id, staticClass: "border-bottom" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "d-flex flex-column justify-center pt-0 pb-0 cur-point",
                            attrs: { sm: "5" },
                            on: {
                              click: function ($event) {
                                return _vm.onReSelect(item)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.Field) + " ")]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "d-flex flex-column justify-center pt-0 pb-0 cur-point",
                            attrs: { sm: "1" },
                            on: {
                              click: function ($event) {
                                return _vm.onReSelect(item)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              [_c("v-icon", [_vm._v(_vm._s(item.Oper.icon))])],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "d-flex flex-column justify-center pt-0 pb-0 cur-point",
                            attrs: { sm: "5" },
                            on: {
                              click: function ($event) {
                                return _vm.onReSelect(item)
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "pa-2" }, [
                              _vm._v(_vm._s(_vm.oneOrMoreDisplay(item.Value))),
                            ]),
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "d-flex flex-column justify-center pt-0 pb-0",
                            attrs: { sm: "1" },
                          },
                          [
                            item.Removable !== false
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onFilterRemove(item)
                                      },
                                    },
                                  },
                                  [_c("v-icon", [_vm._v("mdi-window-close")])],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.onCloseFilterWindow()
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.filters.length
        ? _c(
            "v-chip",
            {
              staticClass: "mr-1 mb-1",
              attrs: { small: "", color: "grey lighten-3", label: "" },
            },
            [_vm._v("None")]
          )
        : _vm._e(),
      _vm._l(_vm.filters, function (item) {
        return _c(
          "v-chip",
          {
            key: item._id,
            staticClass: "mr-1 mb-1",
            attrs: {
              small: "",
              color: "info",
              label: "",
              close: item.Removable !== false,
            },
            on: {
              "click:close": function ($event) {
                return _vm.onFilterRemove(item)
              },
            },
          },
          [
            _vm._v(" " + _vm._s(item.Field) + " "),
            _c(
              "v-icon",
              { staticClass: "ml-1", attrs: { small: "", color: "yellow" } },
              [_vm._v(_vm._s(item.Oper.icon))]
            ),
            _c("strong", { staticClass: "ml-1" }, [
              _vm._v(_vm._s(_vm.oneOrMoreDisplay(item.Value))),
            ]),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }