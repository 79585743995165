var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { sm: "4" } }, [
            _c("h1", { staticClass: "title font-weight-light" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.isInApproval ? "Approvals" : "Data Manager") +
                  " (" +
                  _vm._s(_vm.$format.int(_vm.pager.total)) +
                  ") "
              ),
            ]),
          ]),
          _c(
            "v-col",
            { attrs: { sm: "2" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    dark: "",
                    small: "",
                    right: "",
                    fab: "",
                    loading: _vm.isBusy,
                  },
                  on: { click: _vm.loadData },
                },
                [_c("v-icon", [_vm._v("mdi-refresh")])],
                1
              ),
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        ref: "survey",
                        attrs: {
                          items: _vm.surveyOptions,
                          "item-text": "Name",
                          "item-value": "_id",
                          label: "Form",
                          autofocus: "",
                          "hide-details": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend-item",
                            fn: function () {
                              return [
                                _c(
                                  "v-list-item",
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Enter your filter criteria",
                                            clearable: "",
                                          },
                                          on: {
                                            "click:clear":
                                              _vm.clearLookupsFilter,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append-outer",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "mr-5",
                                                      attrs: {
                                                        fab: "",
                                                        "x-small": "",
                                                      },
                                                      on: {
                                                        click: _vm.loadLookups,
                                                      },
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("mdi-magnify"),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value: _vm.formFilter,
                                            callback: function ($$v) {
                                              _vm.formFilter = $$v
                                            },
                                            expression: "formFilter",
                                          },
                                        }),
                                        _c(
                                          "v-list-item-subtitle",
                                          {
                                            staticClass:
                                              "d-flex justify-center",
                                          },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                "Only 20 items displayed at a time"
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-divider", { staticClass: "mt-2" }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.surveyId,
                          callback: function ($$v) {
                            _vm.surveyId = $$v
                          },
                          expression: "surveyId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { dense: "" } },
                    [
                      _c("v-select", {
                        ref: "version",
                        attrs: {
                          items: _vm.surveyVersions,
                          "item-text": "Version",
                          "item-value": "_id",
                          label: "Version",
                          autofocus: "",
                          "hide-details": "",
                          "return-object": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend-item",
                            fn: function () {
                              return [
                                _c(
                                  "v-list-item",
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            type: "number",
                                            label: "Enter your filter criteria",
                                            clearable: "",
                                          },
                                          on: {
                                            "click:clear":
                                              _vm.clearVersionsFilter,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append-outer",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "mr-5",
                                                      attrs: {
                                                        fab: "",
                                                        "x-small": "",
                                                      },
                                                      on: {
                                                        click: _vm.loadVersions,
                                                      },
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("mdi-magnify"),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value: _vm.versionFilter,
                                            callback: function ($$v) {
                                              _vm.versionFilter = $$v
                                            },
                                            expression: "versionFilter",
                                          },
                                        }),
                                        _c(
                                          "v-list-item-subtitle",
                                          {
                                            staticClass:
                                              "d-flex justify-center",
                                          },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                "Only 20 items displayed at a time"
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-divider", { staticClass: "mt-2" }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.surveyVersion,
                          callback: function ($$v) {
                            _vm.surveyVersion = $$v
                          },
                          expression: "surveyVersion",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-0" },
        [
          _c(
            "v-col",
            { attrs: { sm: "12" } },
            [
              _c(
                "v-card",
                [
                  _c("div", { staticClass: "el-clearfix pb-1 pr-2" }, [
                    _c(
                      "div",
                      { staticClass: "mt-1 float-left" },
                      [
                        _c("v-pagination", {
                          staticStyle: { width: "auto" },
                          attrs: {
                            length: _vm.pager.pages,
                            "total-visible": 10,
                          },
                          on: {
                            next: _vm.loadData,
                            previous: _vm.loadData,
                            input: _vm.loadData,
                          },
                          model: {
                            value: _vm.pager.page,
                            callback: function ($$v) {
                              _vm.$set(_vm.pager, "page", $$v)
                            },
                            expression: "pager.page",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mt-3 pl-2 float-right" },
                      [
                        _c("v-numeric", {
                          staticClass: "el-text-right",
                          staticStyle: { "max-width": "90px" },
                          attrs: {
                            maxlength: 11,
                            label: "Go to Page",
                            dense: "",
                            placeholder: "Page #",
                            hint: `Max: ${_vm.$format.int(_vm.pager.pages)}`,
                            "persistent-hint": "",
                          },
                          on: { enter: _vm.loadData, blur: _vm.loadData },
                          model: {
                            value: _vm.pager.page,
                            callback: function ($$v) {
                              _vm.$set(_vm.pager, "page", $$v)
                            },
                            expression: "pager.page",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mt-3 pl-2 float-right" },
                      [
                        _c("v-select", {
                          staticStyle: { "max-width": "80px" },
                          attrs: {
                            items: _vm.pageSizes,
                            label: "Page Size",
                            dense: "",
                            "hide-details": "",
                          },
                          on: { change: _vm.loadData },
                          model: {
                            value: _vm.pager.size,
                            callback: function ($$v) {
                              _vm.$set(_vm.pager, "size", $$v)
                            },
                            expression: "pager.size",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mt-2 float-right" },
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              bottom: "",
                              left: "",
                              "offset-y": "",
                              "close-on-content-click": false,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "ml-2",
                                          attrs: { text: "" },
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color:
                                                _vm.headers.length - 1 ===
                                                _vm.headers.filter(
                                                  (o) => o._vis
                                                ).length -
                                                  1
                                                  ? ""
                                                  : "warning",
                                            },
                                          },
                                          [_vm._v("mdi-table-eye")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c(
                              "v-list",
                              {
                                staticClass: "pl-2 pr-2",
                                attrs: { dense: "" },
                              },
                              [
                                _c(
                                  "v-list-item",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "primary",
                                          elevation: "2",
                                          "x-small": "",
                                        },
                                        on: { click: _vm.onHeaderVisAll },
                                      },
                                      [_vm._v(" All ")]
                                    ),
                                    _vm._v("   "),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "primary",
                                          elevation: "2",
                                          "x-small": "",
                                        },
                                        on: { click: _vm.onHeaderVisNone },
                                      },
                                      [_vm._v(" None ")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._l(
                                  _vm.headers.filter(
                                    (o) => o.value !== "ApprovedIcon"
                                  ),
                                  function (item, index) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: index,
                                        staticClass: "ma-0 pa-0 mh-30",
                                        attrs: { dense: "", link: "" },
                                      },
                                      [
                                        _c("v-checkbox", {
                                          staticClass: "ma-0 pa-0",
                                          attrs: {
                                            label: item.text,
                                            dense: "",
                                            "hide-details": "",
                                          },
                                          on: { change: _vm.onHeaderVisChange },
                                          model: {
                                            value: item._vis,
                                            callback: function ($$v) {
                                              _vm.$set(item, "_vis", $$v)
                                            },
                                            expression: "item._vis",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mt-1 float-right" },
                      [
                        _c("v-checkbox", {
                          attrs: { label: "Field Names", "hide-details": "" },
                          on: { change: _vm.onFieldNamesChange },
                          model: {
                            value: _vm.fieldNames,
                            callback: function ($$v) {
                              _vm.fieldNames = $$v
                            },
                            expression: "fieldNames",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("v-filter", {
                    staticClass: "d-flex ml-2 mr-2 mb-2",
                    attrs: {
                      filterList: _vm.filters,
                      surveyFilterFields: _vm.filterFields,
                    },
                    on: { removed: _vm.loadData, closed: _vm.loadData },
                  }),
                  _c("v-divider"),
                  _vm.pager.total > 0
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            { staticClass: "wide-table" },
                            [
                              _c("v-data-table", {
                                attrs: {
                                  headers: _vm.headers.filter((o) => o._vis),
                                  items: _vm.tableData,
                                  "item-key": "_id",
                                  "items-per-page": _vm.pager.size,
                                  options: _vm.tableOptions,
                                  "show-select": _vm.isInApproval,
                                  loading: _vm.isBusy,
                                  "multi-sort": "",
                                  "hide-default-footer": "",
                                  dense: "",
                                  "no-data-text": "No data.",
                                },
                                on: {
                                  "update:options": function ($event) {
                                    _vm.tableOptions = $event
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function ({ item }) {
                                        return [
                                          _c(
                                            "tr",
                                            [
                                              _vm.isInApproval
                                                ? _c(
                                                    "td",
                                                    [
                                                      _c("v-checkbox", {
                                                        staticStyle: {
                                                          margin: "0px",
                                                          padding: "0px",
                                                        },
                                                        attrs: {
                                                          value: item,
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value: _vm.selected,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.selected = $$v
                                                          },
                                                          expression:
                                                            "selected",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.isInApproval
                                                ? _c(
                                                    "td",
                                                    {
                                                      staticClass: "ma-0 pa-0",
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { right: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                  attrs,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  item.Approved ===
                                                                                  "P"
                                                                                    ? "gray"
                                                                                    : item.Approved ===
                                                                                      "N"
                                                                                    ? "error"
                                                                                    : "success",
                                                                              },
                                                                          },
                                                                          "v-icon",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.ApprovedIcon
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.Reason ||
                                                                  "n/a"
                                                              ) + " "
                                                            ),
                                                            _c("i", [
                                                              _vm._v(
                                                                "[by: " +
                                                                  _vm._s(
                                                                    item.Approver ||
                                                                      "?"
                                                                  ) +
                                                                  "]"
                                                              ),
                                                            ]),
                                                          ]),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._l(
                                                _vm.headers.filter(
                                                  (o) =>
                                                    o._vis &&
                                                    o.value !== "ApprovedIcon"
                                                ),
                                                function (header) {
                                                  return _c(
                                                    "td",
                                                    {
                                                      key: header.value,
                                                      class: `el-table-top pt-1 pb-1 ${
                                                        header.align === "start"
                                                          ? "text-left"
                                                          : "text-right"
                                                      }`,
                                                    },
                                                    [
                                                      header.type !==
                                                      "FileUpload"
                                                        ? _c("div", {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                _vm.getValueForCell(
                                                                  item[
                                                                    header.value
                                                                  ],
                                                                  header
                                                                )
                                                              ),
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onSelect(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          })
                                                        : header.type ===
                                                            "FileUpload" &&
                                                          _vm.uploadIsImage(
                                                            header.UploadType
                                                          )
                                                        ? _c("v-img", {
                                                            attrs: {
                                                              src: _vm.getUploadImage(
                                                                item[
                                                                  header.value
                                                                ]
                                                              ),
                                                              width: "130",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onSelect(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          })
                                                        : header.type ===
                                                            "FileUpload" &&
                                                          !_vm.uploadIsImage(
                                                            header.UploadType
                                                          )
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                href: _vm.getFileUploadValue(
                                                                  item,
                                                                  header
                                                                ),
                                                                target:
                                                                  "_blank",
                                                                "x-small": "",
                                                                text: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item[
                                                                      header
                                                                        .value
                                                                    ].filename
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3215355445
                                ),
                                model: {
                                  value: _vm.selected,
                                  callback: function ($$v) {
                                    _vm.selected = $$v
                                  },
                                  expression: "selected",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c("div", { staticClass: "el-clearfix pb-1 pr-2" }, [
                            _c(
                              "div",
                              { staticClass: "mt-2 el-text-center" },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: { bottom: "", "offset-y": "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _vm.isInApproval
                                                ? _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        staticClass: "ml-2",
                                                        attrs: {
                                                          text: "",
                                                          disabled:
                                                            !_vm.selected
                                                              .length,
                                                          loading:
                                                            _vm.isActioning,
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            "mdi-format-list-checks"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v("Action "),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3986331338
                                    ),
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      [
                                        _c(
                                          "v-list-item",
                                          {
                                            attrs: { link: "" },
                                            on: { click: _vm.onActionApprove },
                                          },
                                          [_vm._v(" Approve ")]
                                        ),
                                        _c(
                                          "v-list-item",
                                          {
                                            attrs: { link: "" },
                                            on: { click: _vm.onActionReject },
                                          },
                                          [_vm._v(" Reject ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.isInApproval
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "ml-2",
                                        attrs: { text: "" },
                                        on: { click: _vm.onBulkFile },
                                      },
                                      [
                                        _c("v-icon", { staticClass: "mr-2" }, [
                                          _vm._v("mdi-list-box-outline"),
                                        ]),
                                        _vm._v("Bulk File "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      "open-on-hover": "",
                                      bottom: "",
                                      "offset-y": "",
                                      "nudge-top": "-4",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "span",
                                                { staticClass: "forStatus" },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        staticClass: "ml-2",
                                                        attrs: { text: "" },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { staticClass: "mr-2" },
                                                        [_vm._v("mdi-export")]
                                                      ),
                                                      _vm._v("Export "),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1461433314
                                    ),
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      _vm._l(
                                        _vm.exportOptions,
                                        function (item) {
                                          return _c(
                                            "v-list-item",
                                            {
                                              key: item.id,
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onExportClick(
                                                    item.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-title",
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { left: "" } },
                                                    [_vm._v(_vm._s(item.icon))]
                                                  ),
                                                  _vm._v(
                                                    _vm._s(item.text) + " "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("v-divider"),
                          _c("div", { staticClass: "el-clearfix pb-1 pr-2" }, [
                            _c(
                              "div",
                              { staticClass: "mt-1 float-left" },
                              [
                                _c("v-pagination", {
                                  staticStyle: { width: "auto" },
                                  attrs: {
                                    length: _vm.pager.pages,
                                    "total-visible": 10,
                                  },
                                  on: {
                                    next: _vm.loadData,
                                    previous: _vm.loadData,
                                    input: _vm.loadData,
                                  },
                                  model: {
                                    value: _vm.pager.page,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.pager, "page", $$v)
                                    },
                                    expression: "pager.page",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "mt-3 pl-2 float-right" },
                              [
                                _c("v-numeric", {
                                  staticClass: "el-text-right",
                                  staticStyle: { "max-width": "90px" },
                                  attrs: {
                                    maxlength: 11,
                                    label: "Go to Page",
                                    dense: "",
                                    placeholder: "Page #",
                                    hint: `Max: ${_vm.$format.int(
                                      _vm.pager.pages
                                    )}`,
                                    "persistent-hint": "",
                                  },
                                  on: {
                                    enter: _vm.loadData,
                                    blur: _vm.loadData,
                                  },
                                  model: {
                                    value: _vm.pager.page,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.pager, "page", $$v)
                                    },
                                    expression: "pager.page",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "mt-3 pl-2 float-right" },
                              [
                                _c("v-select", {
                                  staticStyle: { "max-width": "80px" },
                                  attrs: {
                                    items: _vm.pageSizes,
                                    label: "Page Size",
                                    dense: "",
                                    "hide-details": "",
                                  },
                                  on: { change: _vm.loadData },
                                  model: {
                                    value: _vm.pager.size,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.pager, "size", $$v)
                                    },
                                    expression: "pager.size",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "d-flex justify-center" },
                                [_vm._v("No data available")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }