var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "mt-2", attrs: { dense: "" } },
        [
          _c("v-col", { attrs: { sm: "6" } }, [
            _c("h1", { staticClass: "title font-weight-light" }, [
              _vm._v(" User " + _vm._s(_vm.title) + " "),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-2", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { sm: "6" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-2",
                          attrs: { "no-gutters": "", dense: "" },
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { sm: "12" } },
                            [
                              _c(
                                "v-card",
                                [
                                  _c("v-card-title", [
                                    _c(
                                      "span",
                                      { staticClass: "text-sm-body-2" },
                                      [_vm._v("Status")]
                                    ),
                                  ]),
                                  _c(
                                    "v-card-text",
                                    [
                                      _vm.lastSyncDate
                                        ? _c(
                                            "v-row",
                                            {
                                              staticClass: "mt-2",
                                              attrs: {
                                                "no-gutters": "",
                                                dense: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { sm: "12" } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "f-s float-left",
                                                      staticStyle: {
                                                        "line-height": "1",
                                                      },
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          "Data Last Synced On:"
                                                        ),
                                                      ]),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            new Date(
                                                              _vm.lastSyncDate
                                                            ).toLocaleDateString()
                                                          ) +
                                                          " @ " +
                                                          _vm._s(
                                                            new Date(
                                                              _vm.lastSyncDate
                                                            ).toLocaleTimeString()
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "float-right",
                                                      attrs: {
                                                        elevation: "2",
                                                        outlined: "",
                                                        color: `${
                                                          _vm.isOnline
                                                            ? "success"
                                                            : "error"
                                                        }`,
                                                        loading:
                                                          _vm.checkingStatus,
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.checkOnlineStatus,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { left: "" } },
                                                        [
                                                          _vm._v(
                                                            "mdi-signal-variant"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.isOnline
                                                              ? "Online"
                                                              : "Offline"
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-2",
                          attrs: { "no-gutters": "", dense: "" },
                        },
                        [
                          _c("v-col", { attrs: { sm: "12" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "f-s",
                                staticStyle: { "line-height": "1" },
                              },
                              [_vm._v("Application Version")]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "", dense: "" } },
                        [
                          _c("v-col", { attrs: { sm: "12" } }, [
                            _c("div", { staticClass: "body-1" }, [
                              _vm._v("v." + _vm._s(_vm.version)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-2 pwa-install",
                          attrs: { "no-gutters": "", dense: "" },
                        },
                        [
                          _c("v-col", { attrs: { sm: "12" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "f-s",
                                staticStyle: { "line-height": "1" },
                              },
                              [_vm._v("For Offline Usage")]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-1 pwa-install",
                          attrs: { "no-gutters": "", dense: "" },
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { sm: "12" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary",
                                  attrs: { small: "" },
                                  on: { click: _vm.onInstall },
                                },
                                [_vm._v("Install Application")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-2", attrs: { dense: "" } },
                        [
                          _c("v-col", { attrs: { sm: "12" } }, [
                            _c("div", { staticClass: "f-xxl" }, [
                              _vm._v(" " + _vm._s(_vm.user.FullName) + " "),
                              _c("span", {
                                class: `color-circle-s ml-1 ${_vm.$getStatusColorUser(
                                  _vm.item.StatusId
                                )}`,
                              }),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-2",
                          attrs: { "no-gutters": "", dense: "" },
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { sm: "6" } },
                            [
                              _c("v-switch", {
                                attrs: {
                                  label: "Send Notification",
                                  hint: "Send notification on password reset.",
                                  "persistent-hint": "",
                                },
                                model: {
                                  value: _vm.sendNotification,
                                  callback: function ($$v) {
                                    _vm.sendNotification = $$v
                                  },
                                  expression: "sendNotification",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-2",
                          attrs: { "no-gutters": "", dense: "" },
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { sm: "6" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    small: "",
                                    loading: _vm.isPasswordSaving,
                                  },
                                  on: { click: _vm.onPassword },
                                },
                                [_vm._v("Change My Password")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.hasAppUpdate
                        ? _c(
                            "v-row",
                            {
                              staticClass: "mt-2",
                              attrs: { "no-gutters": "", dense: "" },
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { sm: "12" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "warning",
                                      attrs: { small: "" },
                                      on: { click: _vm.onAppUpdate },
                                    },
                                    [_vm._v("Update Application")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-2",
                          attrs: { "no-gutters": "", dense: "" },
                        },
                        [
                          _c("v-col", { attrs: { sm: "12" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "f-s",
                                staticStyle: { "line-height": "1" },
                              },
                              [_vm._v("Username")]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "", dense: "" } },
                        [
                          _c("v-col", { attrs: { sm: "12" } }, [
                            _c("div", { staticClass: "body-1" }, [
                              _vm._v(_vm._s(_vm.item.Username || "n/a")),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-2",
                          attrs: { "no-gutters": "", dense: "" },
                        },
                        [
                          _c("v-col", { attrs: { sm: "12" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "f-s",
                                staticStyle: { "line-height": "1" },
                              },
                              [_vm._v("Email")]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "", dense: "" } },
                        [
                          _c("v-col", { attrs: { sm: "12" } }, [
                            _c("div", { staticClass: "body-1" }, [
                              _vm._v(_vm._s(_vm.item.Email || "n/a")),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-2",
                          attrs: { "no-gutters": "", dense: "" },
                        },
                        [
                          _c("v-col", { attrs: { sm: "12" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "f-s",
                                staticStyle: { "line-height": "1" },
                              },
                              [_vm._v("Mobile")]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "", dense: "" } },
                        [
                          _c("v-col", { attrs: { sm: "12" } }, [
                            _c("div", { staticClass: "body-1" }, [
                              _vm._v(_vm._s(_vm.item.Mobile || "n/a")),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-2",
                          attrs: { "no-gutters": "", dense: "" },
                        },
                        [
                          _c("v-col", { attrs: { sm: "12" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "f-s",
                                staticStyle: { "line-height": "1" },
                              },
                              [_vm._v("Application Permissions")]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-1",
                          attrs: { "no-gutters": "", dense: "" },
                        },
                        [
                          _c("v-col", { attrs: { sm: "12" } }, [
                            _c(
                              "div",
                              { staticClass: "body-1" },
                              [
                                _c(
                                  "v-chip",
                                  {
                                    staticClass: "mr-2",
                                    attrs: {
                                      color: `${
                                        _vm.permCamera ? "success" : "error"
                                      }`,
                                      label: "",
                                      "text-color": "white",
                                    },
                                    on: { click: _vm.askCamPerm },
                                  },
                                  [
                                    _c("v-icon", { attrs: { left: "" } }, [
                                      _vm._v("mdi-camera"),
                                    ]),
                                    _vm._v("Camera"),
                                    _c("v-icon", { attrs: { right: "" } }, [
                                      _vm._v(
                                        "mdi-" +
                                          _vm._s(
                                            _vm.permCamera ? "check" : "close"
                                          )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-chip",
                                  {
                                    staticClass: "mr-2",
                                    attrs: {
                                      color: `${
                                        _vm.permFiles ? "success" : "error"
                                      }`,
                                      label: "",
                                      "text-color": "white",
                                    },
                                    on: { click: _vm.askStoragePerm },
                                  },
                                  [
                                    _c("v-icon", { attrs: { left: "" } }, [
                                      _vm._v("mdi-file-image"),
                                    ]),
                                    _vm._v("File System"),
                                    _c("v-icon", { attrs: { right: "" } }, [
                                      _vm._v(
                                        "mdi-" +
                                          _vm._s(
                                            _vm.permFiles ? "check" : "close"
                                          )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-chip",
                                  {
                                    staticClass: "mr-2",
                                    attrs: {
                                      color: `${
                                        _vm.permGps ? "success" : "error"
                                      }`,
                                      label: "",
                                      "text-color": "white",
                                    },
                                    on: { click: _vm.askGpsPerm },
                                  },
                                  [
                                    _c("v-icon", { attrs: { left: "" } }, [
                                      _vm._v("mdi-map-marker"),
                                    ]),
                                    _vm._v("GPS"),
                                    _c("v-icon", { attrs: { right: "" } }, [
                                      _vm._v(
                                        "mdi-" +
                                          _vm._s(
                                            _vm.permGps ? "check" : "close"
                                          )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-4",
                          attrs: { "no-gutters": "", dense: "" },
                        },
                        [
                          _c("v-col", { attrs: { sm: "12" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "f-s",
                                staticStyle: { "line-height": "1" },
                              },
                              [_vm._v("Tags")]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "", dense: "" } },
                        [
                          _c("v-col", { attrs: { sm: "12" } }, [
                            !_vm.item.Tags.length
                              ? _c("div", { staticClass: "body-1" }, [
                                  _vm._v("-None-"),
                                ])
                              : _vm._e(),
                            _vm.item.Tags.length
                              ? _c(
                                  "ul",
                                  { staticClass: "body-1" },
                                  _vm._l(_vm.item.Tags, function (item) {
                                    return _c("li", { key: item }, [
                                      _vm._v(_vm._s(item)),
                                    ])
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-4",
                          attrs: { "no-gutters": "", dense: "" },
                        },
                        [
                          _c("v-col", { attrs: { sm: "12" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "f-s",
                                staticStyle: { "line-height": "1" },
                              },
                              [_vm._v("Roles")]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "", dense: "" } },
                        [
                          _c("v-col", { attrs: { sm: "12" } }, [
                            !_vm.roles.length
                              ? _c("div", { staticClass: "body-1" }, [
                                  _vm._v("-None-"),
                                ])
                              : _vm._e(),
                            _vm.roles.length
                              ? _c(
                                  "ul",
                                  { staticClass: "body-1" },
                                  _vm._l(_vm.roles, function (item) {
                                    return _c("li", { key: item }, [
                                      _vm._v(_vm._s(item)),
                                    ])
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-4",
                          attrs: { "no-gutters": "", dense: "" },
                        },
                        [
                          _c("v-col", { attrs: { sm: "12" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "f-s",
                                staticStyle: { "line-height": "1" },
                              },
                              [_vm._v("Projects")]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "", dense: "" } },
                        [
                          _c("v-col", { attrs: { sm: "12" } }, [
                            !_vm.projects.length
                              ? _c("div", { staticClass: "body-1" }, [
                                  _vm._v("-None-"),
                                ])
                              : _vm._e(),
                            _vm.projects.length
                              ? _c(
                                  "ul",
                                  { staticClass: "body-1" },
                                  _vm._l(_vm.projects, function (item) {
                                    return _c("li", { key: item }, [
                                      _vm._v(_vm._s(item)),
                                    ])
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }