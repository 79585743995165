var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { sm: "12" } }, [
            _c(
              "div",
              {
                staticClass: "font-weight-light text-center mt-10",
                staticStyle: { "font-size": "40pt" },
              },
              [_vm._v(_vm._s(_vm.title))]
            ),
            _c(
              "div",
              { staticClass: "text-center opa-5" },
              [
                _c(
                  "v-icon",
                  {
                    staticStyle: { "font-size": "80pt" },
                    attrs: { color: "accent" },
                  },
                  [_vm._v("mdi-lock-outline")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }