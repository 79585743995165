import Dexie from 'dexie';
import { exportDB, importDB, importInto } from 'dexie-export-import';
import Data from '@/util/Data';
import Encoder from '@/util/Encoder';
import ImageTools from '@/util/ImageTools';
import Util from '@/util/Util';
import config from '@/config';
// import { compress } from 'lz-string';
// import XXH from 'xxhashjs';

/**
 * Database helper class that uses [Dexie](https://dexie.org/).
 * IndexedDB is the underlying database.
 */
export default class Database {
    constructor () {
        this.answersToPush = [];
    }

    async init (name) {
        this.name = name;
        this.db = new Dexie(name);
        // Declare tables, IDs and indexes.
        // We control the version here because it needs to increment on schema changes.
        this.db.version(14).stores({
            Setting: '&_id, Value',
            Project: '&_id, Name, StatusId',
            Survey: '&_id, ProjectId, Name, StatusId',
            SurveyPublished: '&_id, SurveyId, ProjectId, Name, StatusId, Version, [SurveyId+Version]',
            Dashboard: '&_id, ProjectId, Name',
            Report: '&_id, ProjectId, Title, StatusId',
            Role: '&_id, Name',
            Primary: '&_id, Name, ProjectId',
            PrimaryData: '&_id, Lang, PrimaryId, [PrimaryId+Lang]',
            Approver: '&_id, ProjectId',
            InputType: '&_id, Name',
            Answer: '++_id, ProjectId, SurveyId, User, _SvrId, _ACK, [ProjectId+_ACK]',
            AnswerUpload: '++_id, AnswerId, Question',
            Counts: '&_id, Table, Version, SurveyId',
            DataSources: '++id',
            Lookup: '++id',
            DynamicLookupSources: '++id',
            Navigation: '&_id, ProjectId, NavigationSchema'
        });
        // Open the database.
        await this.db.open();
        return this;
    }

    async reInit () {
        return await this.init(this.name);
    }

    async cleanAllTablesExceptAnswers () {
        await this.db.Setting.clear();
        await this.db.Project.clear();
        await this.db.Survey.clear();
        await this.db.SurveyPublished.clear();
        await this.db.Dashboard.clear();
        await this.db.Report.clear();
        await this.db.Role.clear();
        await this.db.Primary.clear();
        await this.db.PrimaryData.clear();
        await this.db.Approver.clear();
        await this.db.InputType.clear();
        await this.db.Counts.clear();
        await this.db.DataSources.clear();
        await this.db.Lookup.clear();
        await this.db.DynamicLookupSources.clear();
        await this.db.Navigation.clear();
    }

    // # region Datastores for client storage
    async getTables () {
        const tablenames = [];
        this.db.tables.forEach(function (table) {
            tablenames.push(table.name);
        });
        return tablenames;
    }

    async addNewTable (name) {
        const newVersion = this.db.verno + 1;
        // Lets see if the table exists that we want to create. If so return false
        let exists = false;
        this.db.tables.forEach(function (table) {
            if (table.name === name) exists = true;
        });
        const tableString = '{"' + name + '": "++id" }';
        const newTable = JSON.parse(tableString);
        if (!exists) {
            await this.db.close();
            await this.db.version(newVersion).stores(
                newTable
            );
            await this.db.open();
            return true;
        }
        else return false;
    }

    async getTable (name) {
        return await this.db.table(name).toArray();
    }

    async getDataStore (id) {
        const rec = await this.db.DataSources.get(id);
        return rec ? rec.Value : null;
    }

    async getDataStoreRecords (startIndex, limit) {
        const rec = await this.db.DataSources.where('id').above(startIndex - 1).limit(limit).toArray();
        return rec;
    }

    async setDataStore (value, id) { // _id is optional. Do not pass when already in the record (value).
        return await this.db.DataSources.put(value, id);
    }

    async deleteDataStore (id) {
        return await this.db.DataSources.where('id').anyOf(id).delete();
    }

    async setDataStoreBulk (values) { // _id is optional. Do not pass when already in the record (value).
        try {
            const bp = await this.db.DataSources.bulkPut(values);
            return bp;
        }
        catch (ex) {
            // NOTE: do NOT remove the console statements for bulkPuts in this file at this point
            // As some point we need to invetigate why these occur and trace the real cause why the input paramater is null/undefined.
            // and handle it appropriately.
            console.error(ex);
        }
    }

    async getTotalDataStoreRecords () {
        return await this.db.DataSources.count();
    }

    async clearDataStoreRecords () {
        return await this.db.DataSources.clear();
    }

    isOnline () {
        if (navigator.onLine) {
            return true;
        }
        else {
            return false;
        }
    }

    // #region Setting
    async getSetting (_id) {
        const rec = await this.db.Setting.get(_id);
        return rec ? rec.Value : null;
    }

    async setSetting (value, _id) { // _id is optional. Do not pass when already in the record (value).
        await this.db.Setting.put(value, _id);
    }
    // #endregion

    // #region Project
    /**
     * Returns projects by status.
     * First load from local then check for updates from server.
     *
     * @param {String} statusId Project status id.
     * @param {Object} pager Pager properties.
     * @param {Function} callback Callback for server result.
     */
    async getProjects (statusId, pager, callback) {
        let hasChanges = false;
        if (this.isOnline()) {
            hasChanges = await this.refreshProjects();
        }
        if (hasChanges && callback) {
            const latest = await this.getProjectsLocal(statusId, pager);
            callback(latest);
            return latest;
        }
        else {
            const recs = await this.getProjectsLocal(statusId, pager);
            if (callback) callback(recs);
            return recs;
        }
    }

    /**
     * Returns locally stored projects by status.
     *
     * @param {String} statusId Project status id.
     * @param {Object} pager Pager properties.
     */
    async getProjectsLocal (statusId, pager) {
        if (statusId === '') statusId = ['A', 'N', 'D', 'H'];
        else if (!Array.isArray(statusId)) statusId = [statusId];
        if (pager) {
            // Count for total.
            const count = await this.db.Project.where('StatusId').anyOf(statusId).count();
            pager.pages = Math.ceil(count / pager.size) || 1;
            pager.total = count;
        }
        // Get the records.
        const recs = await this.db.Project.where('StatusId').anyOf(statusId).toArray() || []; // Locally stored.
        // Sort.
        Data.sort(recs, 'Name');
        if (pager) {
            // Return the requested page.
            const offset = (pager.page - 1) * pager.size;
            return recs.slice(offset, offset + pager.size);
        }
        return recs;
    }

    /**
     * Returns a project record.
     *
     * @param {String} id Project id.
     */
    async getProject (id) {
        const rec = await this.db.Project.get(id);
        return rec;
    }

    /**
     * Filter records based on search text and supplied fields. All local.
     *
     * @param {String} searchFor String to search for.
     * @param {String} searchAt Search at start of, end of, anywhere, or exact match of string.
     * @param {Array} fields List of fields to apply the filter on.
     * @param {String} statusId Status of records to let through.
     */
    async filterProjects (searchFor, searchAt, fields, statusId, pager) {
        if (!fields || !fields.length) throw Error('Please provide fields to filter on.');
        if (statusId === '') statusId = ['A', 'N', 'D', 'H'];
        const reg = searchFor === '' ? null : this.getFilterReg(searchFor, searchAt);
        if (searchFor) { // Search on the provided text.
            // Count for total.
            const count = await this.db.Project.filter(o => statusId.indexOf(o.StatusId) > -1 && this.isMatch(o, reg, fields)).count();
            pager.pages = Math.ceil(count / pager.size) || 1;
            pager.total = count;
            if (pager.page > pager.pages) pager.page = 1; // Reset to 1.
            // Get the records.
            const recs = await this.db.Project.filter(o => statusId.indexOf(o.StatusId) > -1 && this.isMatch(o, reg, fields)).toArray();
            Data.sort(recs || [], 'Name');
            const offset = (pager.page - 1) * pager.size;
            return recs.slice(offset, offset + pager.size);
        }
        else { // Return all.
            // Count for total.
            const count = await this.db.Project.where('StatusId').anyOf(statusId).count();
            pager.pages = Math.ceil(count / pager.size) || 1;
            pager.total = count;
            if (pager.page > pager.pages) pager.page = 1; // Reset to 1.
            // Get the records.
            const recs = await this.db.Project.where('StatusId').anyOf(statusId).toArray();
            Data.sort(recs || [], 'Name');
            const offset = (pager.page - 1) * pager.size;
            return recs.slice(offset, offset + pager.size);
        }
    }

    async refreshDynamicLookupSets () {
        if (this.isOnline()) {
            return this.refreshFromServer('DynamicLookupSources', null, '/lookups/types-with-sub-set', true);
        }
    }

    async getDynamicLookupSet (id) {
        if (this.isOnline()) {
            const sourceSet = await this.$http.get(`/lookups/${id}`);
            return sourceSet.data.d.Values.map(element => ({ value: element.Key, text: element.Value }));
        }
        else {
            const sources = await this.db.DynamicLookupSources.toArray() || [];
            const sourceSet = sources.find(x => x._id === id);
            return sourceSet.Values.map(element => ({ value: element.Key, text: element.Value }));
        }
    }

    async getDynamicLookupSetsLocal () {
        return await this.db.DynamicLookupSources.toArray() || [];
    }

    async getDynamicLookupSets (callback) {
        const recs = await this.getDynamicLookupSetsLocal();
        let hasChanges = false;
        if (this.isOnline()) {
            hasChanges = await this.refreshDynamicLookupSets();
        }
        if (hasChanges && callback) {
            const latest = await this.getDynamicLookupSetsLocal();
            callback(latest);
        }
        return recs;
    }

    async refreshLookups () {
        await this.db.Lookup.clear();
        return await this.refreshFromServer('Lookup', null, '/lookups/types');
    }

    async getLookups (pager, callback) {
        const hasChanges = await this.refreshLookups();
        if (hasChanges && callback) {
            const latest = await this.getLookupsLocal(pager);
            callback(latest);
            return latest;
        }
        else {
            const recs = await this.getLookupsLocal(pager);
            if (callback) callback(recs);
            return recs;
        }
    }

    async getLookupsLocal (pager) {
        if (pager) {
            // Count for total.
            const count = await this.db.Lookup.count();
            pager.pages = Math.ceil(count / pager.size) || 1;
            pager.total = count;
        }
        // Get the records.
        const recs = await this.db.Lookup.toArray() || []; // Locally stored.
        // Sort.
        Data.sort(recs, 'Name');
        if (pager) {
            // Return the requested page.
            const offset = (pager.page - 1) * pager.size;
            return recs.slice(offset, offset + pager.size);
        }
        return recs;
    }

    async filterLookups (searchFor, searchAt, fields, pager) {
        const reg = searchFor === '' ? null : this.getFilterReg(searchFor, searchAt);
        if (searchFor) { // Search on the provided text.
            // Count for total.
            const count = await this.db.Lookup.filter(o => this.isMatch(o, reg, fields)).count();
            pager.pages = Math.ceil(count / pager.size) || 1;
            pager.total = count;
            if (pager.page > pager.pages) pager.page = 1; // Reset to 1.
            // Get the records.
            const recs = await this.db.Lookup.filter(o => this.isMatch(o, reg, fields)).toArray();
            Data.sort(recs || [], 'Name');
            const offset = (pager.page - 1) * pager.size;
            return recs.slice(offset, offset + pager.size);
        }
        else { // Return all.
            // Count for total.
            const count = await this.db.Lookup.count();
            pager.pages = Math.ceil(count / pager.size) || 1;
            pager.total = count;
            if (pager.page > pager.pages) pager.page = 1; // Reset to 1.
            // Get the records.
            const recs = await this.db.Lookup.toArray();
            Data.sort(recs || [], 'Name');
            const offset = (pager.page - 1) * pager.size;
            return recs.slice(offset, offset + pager.size);
        }
    }

    /**
     * Refresh the project records from the server.
     * Use _id and UpdateDate to check for change.
     */
    async refreshProjects () {
        return await this.refreshFromServer('Project', null, '/Project/latest/');
    }
    // #endregion

    // #region Survey
    /**
     * Returns surveys by project and status.
     * First load from local then check for updates from server.
     *
     * @param {String} projectId Project id.
     * @param {String} statusId Project status id.
     * @param {Object} pager Pager properties.
     * @param {Function} callback Callback for server result.
     */
    async getSurveys (projectId, statusId, pager, callback) {
        let hasChanges = false;
        if (this.isOnline()) {
            hasChanges = await this.refreshSurveys(projectId);
        }
        if (hasChanges && callback) {
            const latest = await this.getSurveysLocal(projectId, statusId, pager);
            callback(latest);
            return latest;
        }
        else {
            const recs = await this.getSurveysLocal(projectId, statusId, pager);
            if (callback) callback(recs);
            return recs;
        }
    }

    /**
     * Returns locally stored surveys by project and status.
     *
     * @param {String} projectId Project id.
     * @param {String} statusId Project status id.
     * @param {Object} pager Pager properties.
     */
    async getSurveysLocal (projectId, statusId, pager) {
        if (statusId === '') statusId = ['A', 'N', 'D', 'H'];
        else if (!Array.isArray(statusId)) statusId = [statusId];
        if (pager) {
            // Count for total.
            const count = await this.db.Survey.where({ ProjectId: projectId }).filter(o => statusId.indexOf(o.StatusId) > -1).count();
            pager.pages = Math.ceil(count / pager.size) || 1;
            pager.total = count;
        }
        // Get the records.
        const recs = await this.db.Survey
            .where({ ProjectId: projectId })
            .filter(o => statusId.indexOf(o.StatusId) > -1)
            .toArray() || []; // Locally stored.
        // Sort.
        Data.sort(recs, 'Name');
        if (pager) {
            // Return the requested page.
            const offset = (pager.page - 1) * pager.size;
            return recs.slice(offset, offset + pager.size);
        }
        return recs;
    }

    /**
     * Returns a survey record.
     *
     * @param {String} id Survey id.
     */
    async getSurvey (id) {
        const rec = await this.db.Survey.get(id);
        return rec;
    }

    /**
     * Filter records based on search text and supplied fields. All local.
     *
     * @param {String} projectId Project id.
     * @param {String} searchFor String to search for.
     * @param {String} searchAt Search at start of, end of, anywhere, or exact match of string.
     * @param {Array} fields List of fields to apply the filter on.
     * @param {String} statusId Status of records to let through.
     */
    async filterSurveys (projectId, searchFor, searchAt, fields, statusId, pager) {
        if (!fields || !fields.length) throw Error('Please provide fields to filter on.');
        if (statusId === '') statusId = ['A', 'N', 'D', 'H'];
        const reg = searchFor === '' ? null : this.getFilterReg(searchFor, searchAt);
        if (searchFor) { // Search on the provided text.
            const count = await this.db.Survey.where({ ProjectId: projectId }).filter(o => statusId.indexOf(o.StatusId) > -1 && this.isMatch(o, reg, fields)).count();
            pager.pages = Math.ceil(count / pager.size) || 1;
            pager.total = count;
            if (pager.page > pager.pages) pager.page = 1; // Reset to 1.
            /* const recs = await this.db.Survey
                .where({ ProjectId: projectId })
                .filter(o => statusId.indexOf(o.StatusId) > -1 && this.isMatch(o, reg, fields))
                .offset((pager.page - 1) * pager.size)
                .limit(pager.size)
                .toArray(); */
            const recs = await this.db.Survey
                .where({ ProjectId: projectId })
                .filter(o => statusId.indexOf(o.StatusId) > -1 && this.isMatch(o, reg, fields))
                .toArray();
            Data.sort(recs || [], 'Name');
            const offset = (pager.page - 1) * pager.size;
            return recs.slice(offset, offset + pager.size);
        }
        else { // Return all.
            const count = await this.db.Survey.where({ ProjectId: projectId }).filter(o => statusId.indexOf(o.StatusId) > -1).count();
            pager.pages = Math.ceil(count / pager.size) || 1;
            pager.total = count;
            if (pager.page > pager.pages) pager.page = 1; // Reset to 1.
            /* const recs = await this.db.Survey
                .where({ ProjectId: projectId })
                .filter(o => statusId.indexOf(o.StatusId) > -1)
                .offset((pager.page - 1) * pager.size)
                .limit(pager.size)
                .toArray(); */
            const recs = await this.db.Survey
                .where({ ProjectId: projectId })
                .filter(o => statusId.indexOf(o.StatusId) > -1)
                .toArray();
            Data.sort(recs || [], 'Name');
            const offset = (pager.page - 1) * pager.size;
            return recs.slice(offset, offset + pager.size);
        }
    }

    /**
     * Refresh the survey records from the server.
     * Use _id and UpdateDate to check for change.
     *
     * @param {String} projectId Project id.
     */
    async refreshSurveys (projectId) {
        return await this.refreshFromServer('Survey', { ProjectId: projectId }, `/Survey/latest/${projectId}`);
    }
    // #endregion

    // #region Published (SurveyPublished)
    /**
     * Returns published surveys by project and status.
     * First load from local then check for updates from server.
     *
     * @param {String} projectId Project id.
     * @param {String} statusId Project status id.
     * @param {Object} pager Pager properties.
     * @param {Function} callback Callback for server result.
     */
    async getPublished (projectId, statusId, pager, callback) {
        let hasChanges = false;
        if (this.isOnline()) {
            hasChanges = await this.refreshPublished(projectId);
        }
        if (hasChanges && callback) {
            const latest = await this.getPublishedLocal(projectId, statusId, pager);
            callback(latest);
        }
        else {
            const recs = await this.getPublishedLocal(projectId, statusId, pager);
            if (callback) callback(recs);
            return recs;
        }
    }

    /**
     * Returns locally stored published surveys by project and status.
     *
     * @param {String} projectId Project id.
     * @param {String} statusId Project status id.
     * @param {Object} pager Pager properties.
     */
    async getPublishedLocal (projectId, statusId, pager) {
        if (statusId === '') statusId = ['A', 'N', 'D', 'H'];
        else if (!Array.isArray(statusId)) statusId = [statusId];
        if (pager) {
            // Count for total.
            const count = await this.db.SurveyPublished.where({ ProjectId: projectId }).filter(o => statusId.indexOf(o.StatusId) > -1).count();
            pager.pages = Math.ceil(count / pager.size) || 1;
            pager.total = count;
        }
        // Get the records.
        const drecs = await this.db.SurveyPublished
            .orderBy('Version')
            .reverse()
            // .where({ ProjectId: projectId })
            .filter(o => statusId.indexOf(o.StatusId) > -1 && o.ProjectId === projectId)
            .toArray() || []; // Locally stored.
        // The data is sorted by version desc.
        // Create a new list with only the highest survey versions.
        const ids = [];
        const recs = [];
        for (const rec of drecs) {
            if (ids.indexOf(rec.SurveyId) === -1) {
                ids.push(rec.SurveyId);
                recs.push(rec);
            }
        }
        // Sort.
        Data.sort(recs, 'Name');
        if (pager) {
            // Return the requested page.
            const offset = (pager.page - 1) * pager.size;
            return recs.slice(offset, offset + pager.size);
        }
        return recs;
    }

    /**
     * Returns a published survey record.
     *
     * @param {String} id Survey id.
     */
    async getPublishedOne (id) {
        const recs = await this.db.SurveyPublished
            .orderBy('Version')
            .reverse()
            .filter(o => o.SurveyId === id)
            .limit(1)
            .toArray() || []; // Locally stored.
        return recs[0];
    }

    /**
     * Filter records based on search text and supplied fields. All local.
     *
     * @param {String} projectId Project id.
     * @param {String} searchFor String to search for.
     * @param {String} searchAt Search at start of, end of, anywhere, or exact match of string.
     * @param {Array} fields List of fields to apply the filter on.
     * @param {String} statusId Status of records to let through.
     */
    async filterPublished (projectId, searchFor, searchAt, fields, statusId, pager) {
        if (!fields || !fields.length) throw Error('Please provide fields to filter on.');
        if (statusId === '') statusId = ['A', 'N', 'D', 'H'];
        const reg = searchFor === '' ? null : this.getFilterReg(searchFor, searchAt);
        if (searchFor) { // Search on the provided text.
            const count = await this.db.SurveyPublished.where({ ProjectId: projectId }).filter(o => statusId.indexOf(o.StatusId) > -1 && this.isMatch(o, reg, fields)).count();
            pager.pages = Math.ceil(count / pager.size) || 1;
            pager.total = count;
            if (pager.page > pager.pages) pager.page = 1; // Reset to 1.
            /* const recs = await this.db.SurveyPublished
                .where({ ProjectId: projectId })
                .filter(o => statusId.indexOf(o.StatusId) > -1 && this.isMatch(o, reg, fields))
                .offset((pager.page - 1) * pager.size)
                .limit(pager.size)
                .toArray(); */
            const recs = await this.db.SurveyPublished
                .where({ ProjectId: projectId })
                .filter(o => statusId.indexOf(o.StatusId) > -1 && this.isMatch(o, reg, fields))
                .toArray();
            Data.sort(recs || [], 'Name');
            const offset = (pager.page - 1) * pager.size;
            return recs.slice(offset, offset + pager.size);
        }
        else { // Return all.
            const count = await this.db.SurveyPublished.where({ ProjectId: projectId }).filter(o => statusId.indexOf(o.StatusId) > -1).count();
            pager.pages = Math.ceil(count / pager.size) || 1;
            pager.total = count;
            if (pager.page > pager.pages) pager.page = 1; // Reset to 1.
            /* const recs = await this.db.SurveyPublished
                .where({ ProjectId: projectId })
                .filter(o => statusId.indexOf(o.StatusId) > -1)
                .offset((pager.page - 1) * pager.size)
                .limit(pager.size)
                .toArray(); */
            const recs = await this.db.SurveyPublished_Dashboard
                .where({ ProjectId: projectId })
                .filter(o => statusId.indexOf(o.StatusId) > -1)
                .toArray();
            Data.sort(recs || [], 'Name');
            const offset = (pager.page - 1) * pager.size;
            return recs.slice(offset, offset + pager.size);
        }
    }

    /**
     * Refresh the published survey records from the server.
     * Use _id and UpdateDate to check for change.
     *
     * @param {String} projectId Project id.
     */
    async refreshPublished (projectId) {
        if (projectId === undefined) return;
        return await this.refreshFromServer('SurveyPublished', { ProjectId: projectId }, `/SurveyPublished/latest/${projectId}`);
    }
    // #endregion

    // #region Role
    /**
     * Returns roles.
     * First load from local then check for updates from server.
     *
     * @param {Function} callback Callback for server result.
     */
    async getRoles (pager, callback) {
        const hasChanges = await this.refreshRoles();
        if (hasChanges && callback) {
            const latest = await this.getRolesLocal(pager);
            callback(latest);
            return latest;
        }
        else {
            const recs = await this.getRolesLocal(pager);
            if (callback) callback(recs);
            return recs;
        }
    }

    /**
     * Returns locally stored roles.
     *
     * @param {Object} pager Pager properties.
     */
    async getRolesLocal (pager) {
        if (pager) {
            // Count for total.
            const count = await this.db.Role.count();
            pager.pages = Math.ceil(count / pager.size) || 1;
            pager.total = count;
        }
        // Get the records.
        const recs = await this.db.Role.toArray() || []; // Locally stored.
        // Sort.
        Data.sort(recs, 'Name');
        if (pager) {
            // Return the requested page.
            const offset = (pager.page - 1) * pager.size;
            return recs.slice(offset, offset + pager.size);
        }
        return recs;
    }

    /**
     * Returns a role record.
     *
     * @param {String} id Role id.
     */
    async getRole (id) {
        const rec = await this.db.Role.get(id);
        return rec;
    }

    /**
     * Retrieves the list of roles.
     * @returns {Promise<object|null>} The role with super admin permissions or null if no roles are found.
     */
    async getRoleList () {
        let roles = [];
        const recs = await this.db.Role.toArray();

        if (recs.length === 0) {
            roles = await this.$http.get('/Role/list');
        }

        if (recs.length > 0) {
            const role = this.getSuperAdminPerm(recs);
            return role;
        }
        else if (roles.data.d.length > 0) {
            const role = this.getSuperAdminPerm(roles.data.d);
            return role;
        }
        return null;
    }

    /**
     * Finds the super admin permission from a list of roles.
     * @param {Array} roles - The list of roles to search.
     * @returns {Object} - The role with super admin permission, or undefined if not found.
     */
    getSuperAdminPerm (roles) {
        const superAdminPerms = roles.find(role => role.Perms.includes(888));

        if (superAdminPerms) {
            const role = {
                _id: superAdminPerms._id,
                Name: superAdminPerms.Name
            };
            return role;
        }
    }

    /**
     * Filter records based on search text and supplied fields. All local.
     *
     * @param {String} searchFor String to search for.
     * @param {String} searchAt Search at start of, end of, anywhere, or exact match of string.
     * @param {Array} fields List of fields to apply the filter on.
     */
    async filterRoles (searchFor, searchAt, fields) {
        if (!fields || !fields.length) throw Error('Please provide fields to filter on.');
        const reg = searchFor === '' ? null : this.getFilterReg(searchFor, searchAt);
        const recs = [];
        await this.db.Role.each(rec => {
            if (searchFor) {
                if (this.isMatch(rec, reg, fields)) recs.push(rec);
            }
            else recs.push(rec);
        });
        return Data.sort(recs || [], 'Name');
    }

    /**
     * Refresh the role records from the server.
     * Use _id and UpdateDate to check for change.
     */
    async refreshRoles () {
        return await this.refreshFromServer('Role', null, '/Role/latest/');
    }
    // #endregion

    // #region Primary lists
    /**
     * Returns all the primary lists.
     * First load from local then check for updates from server.
     *
     * @param {String} projectId Project id.
     * @param {Function} callback Callback for server result.
     */
    async getPrimaries (projectId, callback) {
        const result = await this.refreshPrimaries(projectId);
        if (callback) {
            const latest = result ? Data.sort(result, 'Name') : null;
            callback(latest);
            return latest;
        }
        else {
            const recs = await this.db.Primary.where({ ProjectId: projectId }).toArray(); // Locally stored.
            const latest = Data.sort(recs || [], 'Name');
            if (callback) callback(latest);
            return latest;
        }
    }

    /**
     * Returns a `primary` record.
     *
     * @param {String} id Primary id.
     */
    async getPrimary (id) {
        const rec = await this.db.Primary.get(id);
        return rec;
    }

    /**
     * Filter records based on search text and supplied fields. All local.
     *
     * @param {String} projectId Project id.
     * @param {String} searchFor String to search for.
     * @param {String} searchAt Search at start of, end of, anywhere, or exact match of string.
     * @param {Array} fields List of fields to apply the filter on.
     */
    async filterPrimaries (projectId, searchFor, searchAt, fields) {
        if (!fields || !fields.length) throw Error('Please provide fields to filter on.');
        const reg = searchFor === '' ? null : this.getFilterReg(searchFor, searchAt);
        const recs = [];
        await this.db.Primary.where({ ProjectId: projectId }).each(rec => {
            if (searchFor) {
                if (this.isMatch(rec, reg, fields)) recs.push(rec);
            }
            else recs.push(rec);
        });
        return Data.sort(recs || [], 'Name');
    }

    /**
     * Refresh the `primary` records from the server.
     * Use _id and UpdateDate to check for change.
     * This will also pull all the `PrimaryData` records.
     *
     * @param {String} projectId Project id.
     */
    async refreshPrimaries (projectId) {
        try {
            // Gather survey ids and their dates.
            const recChecks = [];
            await this.db.Primary.where({ ProjectId: projectId }).each(o => {
                recChecks.push({ _id: o._id, UpdateDate: o.UpdateDate });
            });
            // Let the server check if there is new/updated data.
            const res = await this.$http.post(`/PrimaryList/latest/${projectId}`, { Check: recChecks }, this.authHeader);
            if (res.data.s && res.data.d.length) { // Success
                // Store the records.
                try {
                    await this.db.Primary.bulkPut(res.data.d);
                    return res.data.d;
                }
                catch (ex) {
                    console.error(ex);
                }
            }
            else return null;
        }
        catch (ex) {
            return null;
        }
    }
    // #endregion

    // #region Navigations
    async getNavigations (projectId, callback) {
        let hasChanges = false;
        if (this.isOnline()) {
            hasChanges = await this.refreshNavigations(projectId);
        }
        if (hasChanges && callback) {
            const latest = await this.getNavigationsLocal(projectId);
            if (callback) callback(latest);
            return latest;
        }
        else {
            const recs = await this.getNavigationsLocal(projectId);
            if (callback) callback(recs);
            return recs;
        }
    }

    async getNavigationsLocal (projectId) {
        // Get the records.
        const recs = await this.db.Navigation.where({ ProjectId: projectId }).toArray() || []; // Locally stored.
        return recs;
    }

    async refreshNavigations (projectId) {
        return await this.refreshFromServer('Navigation', { ProjectId: projectId }, `/Navigation/latest/${projectId}`);
    }
    // #endregion

    // #region Primary data
    /**
     * Returns all the primary data.
     * First load from local then check for updates from server.
     *
     * @param {String} primaryId Primary id.
     * @param {Function} callback Callback for server result.
     */
    async getPrimaryDatas (primaryId, callback) {
        const result = await this.refreshPrimaryDatas(primaryId);
        if (callback) {
            callback(result ? Data.sort(result, 'Index') : null);
        }
        else {
            const recs = await this.db.PrimaryData.where({ PrimaryId: primaryId }).toArray(); // Locally stored.
            const latest = Data.sort(recs || [], 'Index');
            if (callback) callback(latest);
            return latest;
        }
    }

    /**
     * Returns a `primaryData` record.
     *
     * @param {String} id PrimaryData id.
     */
    async getPrimaryData (id) {
        const rec = await this.db.PrimaryData.get(id);
        return rec;
    }

    /**
     * Filter records based on search text and supplied fields. All local.
     *
     * @param {String} primaryId Primary id.
     * @param {String} searchFor String to search for.
     * @param {String} searchAt Search at start of, end of, anywhere, or exact match of string.
     * @param {Array} fields List of fields to apply the filter on.
     */
    async filterPrimaryDatas (primaryId, searchFor, searchAt, fields) {
        if (!fields || !fields.length) throw Error('Please provide fields to filter on.');
        const reg = searchFor === '' ? null : this.getFilterReg(searchFor, searchAt);
        const recs = [];
        await this.db.PrimaryData.where({ PrimaryId: primaryId }).each(rec => {
            if (searchFor) {
                if (this.isMatch(rec, reg, fields)) recs.push(rec);
            }
            else recs.push(rec);
        });
        return Data.sort(recs || [], 'Index');
    }

    /**
     * Refresh the `primaryData` records from the server.
     * Use _id and UpdateDate to check for change.
     * This will also pull all the `PrimaryData` records.
     *
     * @param {String} primaryId Primary id.
     */
    async refreshPrimaryDatas (primaryId) {
        try {
            // Gather record ids and their dates.
            const recChecks = [];
            await this.db.PrimaryData.where({ PrimaryId: primaryId }).each(o => {
                recChecks.push({ _id: o._id, UpdateDate: o.UpdateDate });
            });
            // Let the server check if there is new/updated data.
            const res = await this.$http.post(`/PrimaryData/latest/${primaryId}`, { Check: recChecks }, this.authHeader);
            if (res.data.s && res.data.d.length) { // Success
                // Store the records.
                try {
                    await this.db.PrimaryData.bulkPut(res.data.d);
                    return res.data.d;
                }
                catch (ex) {
                    console.error(ex);
                }
            }
            else return null;
        }
        catch (ex) {
            return null;
        }
    }
    // #endregion

    // #region Dashboard
    /**
     * Returns all the dashboard counts per survey.
     * First load from local then check for updates from server.
     *
     * @param {String} projectId Project id.
     * @param {Function} callback Callback for server result.
     */
    async getDashboards (projectId, callback) {
        // lets see if there are any mycounts if not lets try and get that first
        let hasChanges = false;
        if (this.isOnline()) {
            hasChanges = await this.refreshDashboards(projectId);
        }
        if (hasChanges && callback) {
            const reportCount = await this.getMyCountsReport(true, '');
            const latest = await this.getDashboardsLocal(projectId, reportCount);
            // lets update the Local DB with these values
            try {
                if (latest) await this.db.Dashboard.bulkPut(latest);
                callback(latest);
            }
            catch (ex) {
                console.error(ex);
            }
        }
        else {
            const recordCount = await this.db.Counts.count();
            let reportCount;
            let recs;
            if (recordCount > 0) {
                reportCount = await this.getMyCountsReport(true, '');
                recs = await this.getDashboardsLocal(projectId, reportCount);
            }
            else {
                recs = await this.getDashboardsLocal(projectId);
            }
            if (callback) callback(recs);
            return recs;
        }
    }

    /**
     * Returns locally stored dashboards.
     *
     * @param {String} projectId Project id.
     */
    async getDashboardsLocal (projectId, countRecords) {
        const recs = await this.db.Dashboard.where({ ProjectId: projectId }).toArray() || []; // Locally stored.
        for (let index = 0; index < recs.length; index++) {
            if (countRecords !== undefined) {
                if (countRecords.length > 0) {
                    const result = await countRecords.find(x => x.SurveyId === recs[index]._id);
                    recs[index].MyCount = 0;
                    recs[index].VersionTotal = 0;
                    recs[index].UnPushed = 0;
                    if (result !== undefined) {
                        recs[index].MyCount = result.MyCount;
                        recs[index].VersionTotal = result.Total;
                        recs[index].UnPushed = result.UnPushed;
                    }
                }
            }
            if (!this.isOnline()) {
                const unpushed = await this.getUnpushedReport();
                if (unpushed.length > 0) {
                    const result = await unpushed.find(x => x.Name === recs[index].Name);
                    recs[index].UnPushed = 0;
                    if (result !== undefined) {
                        recs[index].UnPushed = result.Count;
                    }
                }
            }
            if (recs[index].MyCount === undefined) recs[index].MyCount = 0;
            if (recs[index].VersionTotal === undefined) recs[index].VersionTotal = 0;
        }
        // lets update the Local DB with these values
        try {
            await this.db.Dashboard.bulkPut(recs);
            return Data.sort(recs, 'Name');
        }
        catch (ex) {
            console.error(ex);
        }
    }

    /**
     * Returns a dashboard record.
     *
     * @param {String} id Project id.
     */
    async getDashboard (id) {
        const rec = await this.db.Dashboard.get(id);
        if (rec.MyCount === undefined) rec.MyCount = 0;
        if (rec.VersionTotal === undefined) rec.VersionTotal = 0;
        return rec;
    }

    /**
     * Refresh the dashboard records from the server.
     * Use _id and UpdateDate to check for change.
     *
     * @param {String} projectId Project id.
     */
    async refreshDashboards (projectId) {
        return await this.refreshFromServer('Dashboard', { ProjectId: projectId }, `/Dashboard/latest/${projectId}`);
    }
    // #endregion

    // #region Report/Designer
    /**
     * Returns reports.
     * First load from local then check for updates from server.
     *
     * @param {Function} callback Callback for server result.
     */
    async getReports (projectId, statusId, pager, callback) {
        const recs = await this.getReportsLocal(projectId, statusId, pager);
        let hasChanges = false;
        if (this.isOnline()) {
            hasChanges = await this.refreshReports(projectId);
        }
        if (hasChanges && callback) {
            const latest = await this.getReportsLocal(projectId, statusId, pager);
            if (callback !== null) callback(latest);
            return latest;
        }
        else {
            if (callback) callback(recs);
            return recs;
        }
    }

    /**
     * Returns locally stored reports.
     *
     * @param {Object} pager Pager properties.
     */
    async getReportsLocal (projectId, statusId, pager) {
        if (statusId === '') statusId = ['A', 'L'];
        else if (!Array.isArray(statusId)) statusId = [statusId];
        if (pager) {
            // Count for total.
            const count = await this.db.Report.where({ ProjectId: projectId }).filter(o => statusId.indexOf(o.StatusId) > -1).count();
            pager.pages = Math.ceil(count / pager.size) || 1;
            pager.total = count;
        }
        // Get the records.
        const recs = await this.db.Report
            .where({ ProjectId: projectId })
            .filter(o => statusId.indexOf(o.StatusId) > -1)
            .toArray() || []; // Locally stored.
        // Sort.
        Data.sort(recs, 'Title');
        if (pager) {
            // Return the requested page.
            const offset = (pager.page - 1) * pager.size;
            return recs.slice(offset, offset + pager.size);
        }
        return recs;
    }

    /**
     * Returns a report record.
     *
     * @param {String} id Report id.
     */
    async getReport (id) {
        const rec = await this.db.Report.get(id);
        return rec;
    }

    /**
     * Filter records based on search text and supplied fields. All local.
     *
     * @param {String} searchFor String to search for.
     * @param {String} searchAt Search at start of, end of, anywhere, or exact match of string.
     * @param {Array} fields List of fields to apply the filter on.
     */
    async filterReports (projectId, searchFor, searchAt, fields, statusId, pager) {
        if (!fields || !fields.length) throw Error('Please provide fields to filter on.');
        if (statusId === '') statusId = ['A', 'L'];
        const reg = searchFor === '' ? null : this.getFilterReg(searchFor, searchAt);
        if (searchFor) { // Search on the provided text.
            const count = await this.db.Report.where({ ProjectId: projectId }).filter(o => statusId.indexOf(o.StatusId) > -1 && this.isMatch(o, reg, fields)).count();
            pager.pages = Math.ceil(count / pager.size) || 1;
            pager.total = count;
            if (pager.page > pager.pages) pager.page = 1; // Reset to 1.
            const recs = await this.db.Report
                .where({ ProjectId: projectId })
                .filter(o => statusId.indexOf(o.StatusId) > -1 && this.isMatch(o, reg, fields))
                .toArray();
            Data.sort(recs || [], 'Title');
            const offset = (pager.page - 1) * pager.size;
            return recs.slice(offset, offset + pager.size);
        }
        else { // Return all.
            const count = await this.db.Report.where({ ProjectId: projectId }).filter(o => statusId.indexOf(o.StatusId) > -1).count();
            pager.pages = Math.ceil(count / pager.size) || 1;
            pager.total = count;
            if (pager.page > pager.pages) pager.page = 1; // Reset to 1.
            const recs = await this.db.Report
                .where({ ProjectId: projectId })
                .filter(o => statusId.indexOf(o.StatusId) > -1)
                .toArray();
            Data.sort(recs || [], 'Title');
            const offset = (pager.page - 1) * pager.size;
            return recs.slice(offset, offset + pager.size);
        }
    }

    /**
     * Refresh the role records from the server.
     * Use _id and UpdateDate to check for change.
     */
    async refreshReports () {
        return await this.refreshFromServer('Report', null, '/Report/latest/');
    }
    // #endregion

    // #region Approver
    /**
     * Returns all the approvers.
     * First load from local then check for updates from server.
     *
     * @param {String} projectId Project id.
     * @param {Function} callback Callback for server result.
     */
    /* async getApprovers (projectId, callback) {
        const recs = await this.db.Approver.where({ ProjectId: projectId }).toArray(); // Locally stored.
        this.refreshApprovers(projectId).then(result => { // Refresh from the server.
            if (callback) callback(result ? Data.sort(result, 'Name') : null);
        });
        return Data.sort(recs || [], 'Name');
    } */

    /**
     * Refresh the approver records from the server.
     * Use _id and UpdateDate to check for change.
     *
     * @param {String} projectId Project id.
     */
    /* async refreshApprovers (projectId) {
        try {
            // If not online, do not check for updated records.
            if (!this.isOnline()) return null;
            // Gather project ids and their dates.
            const recChecks = [];
            await this.db.Approver.each(o => {
                // recChecks.push({ _id: o._id, UpdateDate: o.UpdateDate });
                const xs = JSON.stringify({ _id: o._id, UpdateDate: o.UpdateDate });
                recChecks.push(`${o._id}|${XXH.h32(xs, 0xFEBACEDF).toString(16).toUpperCase()}`);
            });
            // Let the server check if there is new/updated data.
            const res = await this.$http.post(`/User/approvers/${projectId}`, { Check: recChecks });
            if (res.data.s && res.data.d.length) { // Success
                // Store the records.
                await this.db.Approver.bulkPut(res.data.d);
                return res.data.d;
            }
            else return null;
        }
        catch (ex) {
            return null;
        }
    } */
    // #endregion

    // #region InputType
    /**
     * Returns all the input types.
     * First load from local then check for updates from server.
     *
     * @param {Function} callback Callback for server result.
     */
    async getInputTypes (callback) {
        const result = await this.refreshInputTypes();
        if (callback) callback(result ? Data.sort(result, 'Name') : null);
        else {
            const recs = await this.db.InputType.toArray(); // Locally stored.
            return Data.sort(recs || [], 'Name');
        }
    }

    /**
     * Refresh the InputType records from the server.
     * Use _id and UpdateDate to check for change.
     */
    async refreshInputTypes () {
        try {
            // Gather project ids and their dates.
            const recChecks = [];
            await this.db.InputType.each(o => {
                recChecks.push({ _id: o._id, UpdateDate: o.UpdateDate });
            });
            // Let the server check if there is new/updated data.
            const res = await this.$http.post('/InputType/latest', { Check: recChecks }, this.authHeader);
            if (res.data.s && res.data.d) { // Success
                // Store the records.
                try {
                    await this.db.InputType.bulkPut(res.data.d);
                    return res.data.d;
                }
                catch (ex) {
                    console.error(ex);
                }
            }
            else return null;
        }
        catch (ex) {
            return null;
        }
    }
    // #endregion

    async clearPushedRecords () {
        const answers = await this.db.Answer.where({ _ACK: 1 }).toArray();
        const deleteList = [];
        answers.forEach(element => {
            deleteList.push(element._id);
        });

        try {
            await this.db.Answer.bulkDelete(deleteList);
            await this.db.AnswerUpload.bulkDelete(deleteList);
            return true;
        }
        catch (error) {
            return false;
        }
    }

    // #region `Answer`
    async getUnpushedReport () {
        const answers = await this.db.Answer.where({ _ACK: 0 }).toArray();
        const results = [];
        let blueMax = 265;
        const red = 10;
        const green = 0;
        for (let index = 0; index < answers.length; index++) {
            const element = answers[index];
            // (Math.random() * 0xFFFFFF << 0).toString(16) // generate random color value
            if (blueMax < 0) blueMax = 0;
            else blueMax = blueMax - 10;

            const rgbToHex = (r, g, b) => {
                const hexr = r.toString(16);
                const hexg = g.toString(16);
                const hexb = b.toString(16);
                return '#' + (hexr.length === 1 ? '0' + hexr : hexr) + (hexg.length === 1 ? '0' + hexg : hexg) + (hexb.length === 1 ? '0' + hexb : hexb);
            };

            const found = (results && results.length > 0) ? results.find(x => x._id === element.SurveyId) : null;

            if (!found) {
                results.push({ _id: element.SurveyId, Count: 1, Name: '', Version: '0', Icon: '', Color: rgbToHex(red, green, blueMax) });
            }
            else {
                const item = results.find(x => x._id === element.SurveyId);
                item.Count += 1;
            }
        }
        try {
            for (let index = 0; index < results.length; index++) {
                const element = results[index];
                const rec = await this.db.SurveyPublished.where({ _id: element._id }).toArray();
                element.Name = (rec[0].DashName !== '') ? rec[0].DashName : rec[0].Name;
                element.Version = rec[0].Version;
                element.Icon = rec[0].Icon;
            }
            return results;
        }
        catch {
            return results;
        }
    }

    async getMyCountsReport (fetchOnline, projectId) {
        try {
            if (fetchOnline !== undefined && fetchOnline !== false && this.isOnline()) {
                await this.db.Counts.clear();
            }
            let availableSurveys = [];
            if (projectId === '' || projectId === undefined) {
                availableSurveys = await this.db.SurveyPublished.toArray();
            }
            else {
                availableSurveys = await this.db.SurveyPublished.where({ ProjectId: projectId }).toArray();
            }
            const filteredSurvey = [];
            for (let index = 0; index < availableSurveys.length; index++) {
                const element = availableSurveys[index];
                const getVal = filteredSurvey.find(value => value.SurveyId === element.SurveyId);
                if (getVal !== undefined) {
                    if (element.PublishedVer > getVal.PublishedVer) {
                        const index = filteredSurvey.findIndex(object => {
                            return object.SurveyId === element.SurveyId;
                        });
                        filteredSurvey[index] = element;
                    }
                }
                else {
                    filteredSurvey.push(element);
                }
            }
            // =====================================================================================
            // Get the Highest version of each survey
            const returnResults = [];
            // Get the unpushed counts as well to add them to the results
            const unpushed = await this.getUnpushedReport();
            for (let index = 0; index < filteredSurvey.length; index++) {
                const element = filteredSurvey[index];
                const localDashboard = await this.db.Dashboard.where({ _id: element.SurveyId }).toArray();
                if (localDashboard) {
                    const offlineAvailable = {
                        _id: element._id,
                        Name: element.DashName ? element.DashName : element.Name,
                        SurveyId: element.SurveyId,
                        Table: element.Table,
                        Icon: element.Icon,
                        MyCount: localDashboard.length > 0 ? localDashboard[0].MyCount : 0,
                        Total: localDashboard.length > 0 ? localDashboard[0].Count : 0,
                        UnPushed: 0,
                        Version: element.PublishedVer,
                    };
                    if (unpushed.length > 0) {
                        let unpushedCount = 0;
                        unpushed.forEach(e => {
                            if (e._id === element._id && e.Version === element.PublishedVer) {
                                unpushedCount = e.Count;
                            }
                        });
                        offlineAvailable.UnPushed = unpushedCount;
                        if (offlineAvailable.Total > 0) {
                            returnResults.push(offlineAvailable);
                        }
                    }
                    else {
                        returnResults.push(offlineAvailable);
                    }
                }
            }
            // const onlineresults = [];
            if (returnResults.length > 0) {
                // lets only pull tablenames and versions needed to get the latest version counts from the server
                const query = [];
                returnResults.forEach(element => {
                    query.push({ Table: element.Table, Version: element.Version, SurveyId: element.SurveyId });
                });
                if (this.isOnline()) {
                    const counts = await this.$http.post('/Survey/submissioncount', query, this.authHeader);
                    if (counts && counts.data.d !== undefined) {
                        for (let index = 0; index < counts.data.d.length; index++) {
                            const countsData = counts.data.d[index];
                            const returnElement = returnResults.find(o => o.Table === countsData.table && o.Version === countsData.version);
                            let unpushedCount = 0;
                            unpushed.forEach(e => {
                                if (e._id === returnElement._id && e.Version === returnElement.PublishedVer) {
                                    unpushedCount = e.Count;
                                }
                            });
                            returnElement.MyCount = countsData.myCount;
                            returnElement.Total = countsData.totalCount;
                            returnElement.UnPushed = unpushedCount;
                            returnElement.VersionTotal = countsData.versionTotal;
                            const rec = await this.db.SurveyPublished.where({ _id: returnElement._id }).toArray();
                            if (rec) {
                                returnElement.Name = (rec[0].DashName !== '') ? rec[0].DashName : rec[0].Name;
                                returnElement.Icon = rec[0].Icon;
                            }
                        }
                    }
                }
            }
            const filterdResults = returnResults.filter(function (x) { return (x.Total > 0 && x.MyCount > 0); });
            // lets update counts

            await this.db.Counts.bulkPut(filterdResults);
            return Data.sort(filterdResults, 'Name');
        }
        catch (ex) {
            console.error(ex);
        }
    }

    async countAnswers (surveyId) {
        const count = await this.db.Answer.where({ SurveyId: surveyId }).count();
        return count;
    }

    async countAnswersToPush (surveyId) {
        const count = await this.db.Answer.where({ SurveyId: surveyId, _ACK: 0 }).count();
        return count;
    }

    async countAllAnswersToPush () {
        // lets delete stale record here so we do not have to many to push
        const result = await this.clearPushedRecords();
        if (result) {
            const count = await this.db.Answer.where({ _ACK: 0 }).count();
            return count;
        }
        else return 0;
    }

    async countProjectAnswersToPush (projectId) {
        const keys = [];
        const results = await this.db.Answer.where({ ProjectId: projectId, _ACK: 0 }).toArray();
        results.forEach(o => { if (o.IsExternal === 'false' || o.IsExternal === false) keys.push(o.SurveyId); });
        return Util.countDistictItems(keys);
    }

    async countProjectAnswersToPushExternal (projectId) {
        const keys = [];
        const results = await this.db.Answer.where({ ProjectId: projectId, _ACK: 0 }).toArray();
        results.forEach(o => { if (o.IsExternal === 'true' || o.IsExternal === true) keys.push(o.SurveyId); });
        return Util.countDistictItems(keys);
    }

    async idsOfAllAnswersToPush () {
        const list = [];
        await this.db.Answer.where({ _ACK: 0 }).each(o => { list.push(o._id); });

        list.forEach(x => {
            if (!this.answersToPush.includes(x)) this.answersToPush.push(x);
        });
    }

    async getAnswer (_id) {
        const rec = await this.db.Answer.get(_id);
        return rec;
    }

    async setAnswer (rec, _id) { // _id is optional. Do not pass when already in the record (value) or if auto.
        // if (!rec._id && !_id) rec._id = `${Data.p8()}${Data.p8()}${Data.p8()}`;
        const id = await this.db.Answer.add(rec);
        return id;
    }

    async setAnswerUpload (answerId, upload) {
        const id = await this.db.AnswerUpload.add({ AnswerId: answerId, Question: upload.Question, File: upload.File });
        return id;
    }

    async clearAnswerUploads (answerId) {
        const fileUploads = await this.db.AnswerUpload.where('AnswerId').equals(answerId).toArray();
        for (const fileUpload of fileUploads) {
            fileUpload.File = null;
            await this.db.AnswerUpload.update(fileUpload._id, fileUpload);
        }
    }

    async updateAnswer (id, keyVals) {
        await this.db.Answer.update(id, keyVals);
    }

    /**
     * Push one answer at a time. Answers can be very big due to images.
     * Get all the ids of the answers to push. Loop this list to push.
     */
    async pushAnswers (userId, callback) {
        /* await this.db.Answer.update(3, { _ACK: 0 });
        await this.db.Answer.update(4, { _ACK: 0 });
        callback();
        if (callback) return; */
        const max = await this.countAllAnswersToPush();
        // alert('Items to submit ...', max);
        if (!max) { // Nothing to do.
            callback(null, { End: true });
            return;
        }
        // Determine the answers to process
        await this.idsOfAllAnswersToPush();
        if (callback) callback(null, { Progress: 0 }); // Alert the UI to display the progress.
        await Util.sleep(500); // Let the UI update (display the progress).
        let count = 0;
        for (let i = this.answersToPush.length - 1; i >= 0; i--) {
            const localId = this.answersToPush[i];
            // Progress.
            count += 1;
            // Get the record.
            const rec = await this.db.Answer.get(localId);
            // Set a new user unique id for the server. Helps to catch duplicate submissions.
            // rec._id = rec.Answer._IMP && rec.Answer._id ? rec.Answer._id : `${userId}_${(rec.EndDate || rec.UpdateDate).getTime()}_${rec._id}`; // If import, then use the import id.
            const dt = rec.EndDate || rec.UpdateDate;
            const recordId = rec._id;
            rec._id = `${userId}_${dt.getTime()}_${rec._id}`; // If import, then use the import id.
            // Push the answer.
            const svrId = await this.pushAnswer(localId, rec, recordId);

            if (callback) {
                if (svrId) callback(null, { SurveyId: rec.SurveyId, Progress: (count / max) * 100 }); // Survey id for menu and progress.
                else callback(null, { Progress: (count / max) * 100 }); // Progress.
            }
            // Remove record once its processed
            this.answersToPush.splice(i, 1);
        }
        // All done. INform the UI.
        if (callback) callback(null, { End: true }); // Done.
    }

    /**
     * Sends the local unsubmitted answer to the server.
     * Uses `multipart/form-data` to post because of possibly large content (images).
     *
     * @param {String} localId Local record id.
     * @param {Object} rec Locally stored record.
     * @returns localId
     */
    async pushAnswer (localId, rec, recordId) {
        try {
            await Util.sleep(25); // Throttle a bit.
            // Push to the server.
            const formData = new FormData();
            // Take out all the images.
            const fileKeys = [];
            const keys = Object.keys(rec.Answer);
            for (const key of keys) {
                if (Array.isArray(rec.Answer[key])) {
                    for (let index = 0; index < rec.Answer[key].length; index++) {
                        const element = rec.Answer[key][index];
                        if (element.toString().substring(0, 11) === 'data:image/') {
                            const blobTest = await fetch(element).then(res => res.blob()); // Change the DataUrl to a blob to post as file.
                            let blob = null;
                            if (blobTest.size > 1048576) { // If bigger than 1 MB. This check has already been done on TakePicture. Just a precaution if something slips through.
                                const imgTools = new ImageTools();
                                blob = await imgTools.resize(blobTest, { width: 640, height: 480 });
                            }
                            else blob = blobTest;
                            const fileKey = `_x${key}_${index}`;
                            fileKeys.push(fileKey);
                            formData.append(key, blob);
                            rec.Answer[key][index] = fileKey; // fileKey;
                        }
                    }
                }
                if (typeof rec.Answer[key] === 'string' && rec.Answer[key].substring(0, 11) === 'data:image/') {
                    // const blobTest = await fetch(rec.Answer[key]).then(res => res.blob());
                    // const blob = await imgTools.resize(blobTest, { width: 640, height: 480 });

                    // rec.Answer[key] = { IMG_ENC: this.b64EncodeUnicode(rec.Answer[key]) };
                    // rec.Answer[key] = { IMG_ENC: Encoder.base64EncArr(Encoder.strToUTF8Arr(rec.Answer[key])) };

                    const blobTest = await fetch(rec.Answer[key]).then(res => res.blob()); // Change the DataUrl to a blob to post as file.
                    let blob = null;
                    if (blobTest.size > 1048576) { // If bigger than 1 MB. This check has already been done on TakePicture. Just a precaution if something slips through.
                        const imgTools = new ImageTools();
                        blob = await imgTools.resize(blobTest, { width: 640, height: 480 });
                    }
                    else blob = blobTest;
                    // const fileKey = `FILE_${key}`;
                    const fileKey = `_x${key}`;
                    fileKeys.push(fileKey);
                    // rec.Answer[key] = '-FILE-'; // fileKey;
                    // formData.append(key, blob); // fileKey
                    /* formData.append(key, {
                        uri: rec.Answer[key],
                        type: 'image/jpeg',
                        name: `${key}.jpg`,
                    }); */
                    // formData.append(fileKey, rec.Answer[key]);
                    formData.append(key, blob);
                    rec.Answer[key] = fileKey; // fileKey;
                }
            }
            if (fileKeys.length) rec._IMGS = fileKeys;
            for (const [key, value] of Object.entries(rec)) {
                formData.append(key, typeof value === 'object' && !Data.isDate(value) ? JSON.stringify(value) : value);
            }

            const fileUploads = await this.db.AnswerUpload.where('AnswerId').equals(recordId).toArray();
            for (const fileUpload of fileUploads) {
                formData.append(`formUpload_${fileUpload.Question}`, fileUpload.File);
            }
            // Post the file and data.
            if (this.isOnline()) {
                const submitUrl = (rec.IsExternal) ? '/AnswerStaging/submitexternal' : '/AnswerStaging/submit';
                const reqOptions = {
                    url: submitUrl,
                    method: 'POST',
                    headers: {
                        Accept: '*/*',
                        'Content-Type': 'multipart/form-data'
                    },
                    /* onUploadProgress: function (progressEvent) {
                        const perc = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                        // progress.style.width = `${perc}%`;
                    }, */
                    data: formData,
                };
                // const res = await this.$http.post('/AnswerStaging/submit', rec);

                const res = await this.$http.request(reqOptions);
                if (res.data.s) { // Success
                    // Update the DB record with the server id.
                    await this.db.Answer.update(localId, { _SvrId: res.data.d, _ACK: 1 });
                    return res.data.d;
                }
                else if (res.data.m === 'E11000') { // Duplicate, indicating a re-submission. Possibly due to previous error.
                    await this.db.Answer.update(localId, { _ACK: 1 }); // Flag the record as acknowledged by the server.
                    return 1;
                }
                else {
                    alert('ERR ' + res.data.m);
                }
            }
        }
        catch (ex) {
            const msg = ex.response
                ? `${ex.response.data.error}: ${ex.response.data.message}`
                : ex.message;
            alert(msg);
            return null;
        }
    }

    async pushAnswerX (localId, rec) {
        try {
            await Util.sleep(25); // Throttle a bit.
            // Push to the server.
            // const z = compress(JSON.stringify(rec));
            // const res = await this.$http.post('/AnswerStaging/submit', { d: z });
            /* const config = { // 'Content-Type': 'application/x-www-form-urlencoded', multipart/form-data
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Cache-Control': 'no-transform'
                }
            }; */
            const keys = Object.keys(rec.Answer);
            for (const key of keys) {
                if (typeof rec.Answer[key] === 'string' && rec.Answer[key].substring(0, 11) === 'data:image/') {
                    // rec.Answer[key] = { IMG_ENC: this.b64EncodeUnicode(rec.Answer[key]) };
                    rec.Answer[key] = { IMG_ENC: Encoder.base64EncArr(Encoder.strToUTF8Arr(rec.Answer[key])) };
                }
            }
            // const bin = this.b64EncodeUnicode(JSON.stringify(rec));
            const res = await this.$http.post('/AnswerStaging/submit', rec);
            alert('Responded ' + res);
            if (res.data.s) { // Success
                // Update the DB record with the server id.
                await this.db.Answer.update(localId, { _SvrId: res.data.d, _ACK: 1 });
                return res.data.d;
            }
            else if (res.data.m === 'E11000') { // Duplicate, indicating a re-submission. Possibly due to previous error.
                await this.db.Answer.update(localId, { _ACK: 1 }); // Flag the record as acknowledged by the server.
                return 1;
            }
            else {
                alert('ERR ' + res.data.m);
            }
        }
        catch (ex) {
            alert(ex.message);
            return null;
        }
    }
    // #endregion

    // #region Internal
    get _db () {
        return this.db;
    }

    get http () {
        return this.$http;
    }

    get authHeader () {
        const storedToken = window.localStorage.getItem('srvy__Token');
        if (storedToken != null) {
            const tokenVal = JSON.parse(storedToken).value;
            const headers = { '\'Authorization\'': '\'' + tokenVal + '\'' };
            return headers;
        }
        return null;
    }

    set http (http) {
        this.$http = http;
    }

    b64EncodeUnicode (str) {
        return btoa(encodeURIComponent(str));
    };

    unicodeDecodeB64 (str) {
        return decodeURIComponent(atob(str));
    };

    getFilterReg (searchFor, searchAt) {
        switch (searchAt) {
            case 'start': // Starts with.
                return new RegExp(`(^${searchFor.split(',').join(')|(^')})`, 'gi');
            case 'end': // Ends with.
                return new RegExp(`(${searchFor.split(',').join('$)|(')}$)`, 'gi');
            case 'any': // Anywhere.
                return new RegExp(`(${searchFor.split(',').join(')|(')})`, 'i');
            case 'exact': // Exact match
                return new RegExp(`(^${searchFor.split(',').join('$)|(^')}$)`, 'gi');
        }
    }

    isMatch (rec, reg, fields) {
        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            if (reg.test(rec[field])) return true;
        }
        return false;
    }

    base64Encode (s) {
        return btoa(unescape(encodeURIComponent(s)));
    }

    base64Decode (s) {
        return decodeURIComponent(escape(atob(s)));
    }

    async refreshFromServer (tableName, whereParams, resourceUrl, base64Record) {
        try {
            // If not online, do not check for updated records.
            if (whereParams === undefined) return false;
            // Gather survey ids and their dates.
            const recChecks = [];
            if (whereParams) { // Based on parameters.
                await this.db[tableName].where(whereParams).each(o => {
                    recChecks.push({ _id: o._id, UpdateDate: o.UpdateDate });
                });
            }
            else { // All records.
                await this.db[tableName].each(o => {
                    if (!base64Record) {
                        recChecks.push({ _id: o._id, UpdateDate: o.UpdateDate });
                    }
                    else {
                        const base64String = this.base64Encode(JSON.stringify(o));
                        recChecks.push({ _id: o._id, UpdateDate: o.UpdateDate, base64String });
                    }
                });
            }
            // Let the server check if there is new/updated data.
            const res = await this.$http.post(resourceUrl, { Check: recChecks }, this.authHeader);
            const currentDate = new Date();
            await this.setSetting({ _id: 'LastSync', Value: currentDate });
            if (res.data.s) { // Success
                let hasChanges = false;
                // Store the records.
                if (res.data.d.Latest) {
                    try {
                        await this.db[tableName].bulkPut(res.data.d.Latest);
                        hasChanges = true;
                    }
                    catch (ex) {
                        console.error(ex);
                    }
                }
                // Remove deleted records.
                if (res.data.d.Remove.length) {
                    await this.db[tableName].bulkDelete(res.data.d.Remove);
                    hasChanges = true;
                }
                return hasChanges;
            }
            else {
                return false;
            }
        }
        catch (ex) {
            return false;
        }
    }

    async downloadFormUpload (id) {
        const res = await this.$http.request({
            url: `${config.API_SERVER}/api/v1/FormUpload/${encodeURI(id)}`,
            method: 'GET',
            responseType: 'blob'
        });

        return res;
    }

    // #endregion

    // #region Backup
    /**
     * Exports the entire IndexedDb file.
     * @param progressCallback Progress callback.
     * @return {Promise<Blob>}
     */
    async export (progressCallback) {
        const blob = await exportDB(this.db, {
            progressCallback
            /* progressCallback: progress => {
                // totalTables: number;
                // completedTables: number;
                // totalRows: number;
                // completedRows: number;
                // done: boolean;
            } */
        });
        return blob;
    }

    /**
     * Imports an IndexedDb file as blob and overwrites the existing instance.
     * @param blob File blob.
     * @param progressCallback Progress callback.
     * @return {Promise<void>}
     */
    async import (blob, progressCallback) {
        this.db = await importDB(blob, {
            acceptVersionDiff: true,
            acceptMissingTables: true,
            acceptNameDiff: true,
            acceptChangedPrimaryKey: true,
            overwriteValues: true,
            clearTablesBeforeImport: true,
            progressCallback,
            /* progressCallback: progress => {
                // totalTables: number;
                // completedTables: number;
                // totalRows: number;
                // completedRows: number;
                // done: boolean;
            } */
        });
    }

    /**
     * Imports an IndexedDb file as blob and appends the data to the existing DB.
     * @param blob File blob.
     * @param progressCallback Progress callback.
     * @return {Promise<void>}
     */
    async importAppend (blob, progressCallback) {
        await importInto(this.db, blob, {
            acceptVersionDiff: true,
            acceptMissingTables: true,
            acceptNameDiff: true,
            acceptChangedPrimaryKey: true,
            overwriteValues: true,
            clearTablesBeforeImport: true,
            progressCallback,
            /* progressCallback: progress => {
                // totalTables: number;
                // completedTables: number;
                // totalRows: number;
                // completedRows: number;
                // done: boolean;
            } */
        });
    }
    // #endregion
};
