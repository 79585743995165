var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "h-100p", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "mb-0" },
        [
          _c("v-col", { attrs: { sm: "12" } }, [
            _c("h1", { staticClass: "title font-weight-light" }, [
              _vm._v(" My Reports "),
            ]),
          ]),
        ],
        1
      ),
      _vm.surveyNames.length === 0
        ? _c(
            "v-row",
            { staticClass: "mt-0" },
            [
              _c(
                "v-col",
                { attrs: { sm: "12" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-alert",
                            {
                              staticClass: "ma-2",
                              attrs: { prominent: "", type: "info" },
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-col", { staticClass: "grow" }, [
                                    _vm._v(
                                      " You have no personalized reports available. Please contact your administrator to create a report template. "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.surveyNames.length > 0
        ? _c(
            "v-row",
            { staticClass: "el-map-cover mt-0" },
            [
              _c(
                "v-col",
                { staticClass: "pa-1 pt-0", attrs: { sm: "12" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "h-100p d-flex flex-column",
                      attrs: { loading: _vm.isBusy },
                    },
                    [
                      _c(
                        "v-card-actions",
                        { staticClass: "pa-0" },
                        [
                          _c(
                            "v-tabs",
                            {
                              attrs: { small: "" },
                              on: { change: _vm.onTabChange },
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            [
                              _c("v-tab", [_vm._v("Options")]),
                              _c(
                                "v-tab",
                                {
                                  attrs: {
                                    disabled: _vm.options.SurveyId === null,
                                  },
                                },
                                [_vm._v("Report")]
                              ),
                              _c(
                                "v-subheader",
                                {
                                  staticStyle: {
                                    position: "absolute",
                                    right: "5px",
                                  },
                                },
                                [
                                  _vm._v("Data for"),
                                  _c("b", { staticClass: "ml-2 f-xl" }, [
                                    _vm._v(_vm._s(_vm.selectedReportName)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !_vm.viewReport
                        ? _c(
                            "v-card-text",
                            { staticClass: "flex-grow-1 pa-0" },
                            [
                              _c(
                                "v-form",
                                { staticClass: "w-1024-max" },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "row-smaller" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { sm: "5" } },
                                        [
                                          _c(
                                            "v-subheader",
                                            {
                                              staticClass:
                                                "text-uppercase mt-8 mb-3",
                                              staticStyle: { height: "20px" },
                                            },
                                            [_vm._v("Select Form")]
                                          ),
                                          _c(
                                            "v-row",
                                            { staticClass: "row-smaller" },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { sm: "12" } },
                                                [
                                                  _c(
                                                    "v-radio-group",
                                                    {
                                                      attrs: { column: "" },
                                                      on: {
                                                        change:
                                                          _vm.onReportChange,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.options.SurveyId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.options,
                                                            "SurveyId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "options.SurveyId",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.surveyNames,
                                                      function (o) {
                                                        return _c("v-radio", {
                                                          key: o._id,
                                                          class: `mt-1 ${o._id}`,
                                                          attrs: {
                                                            label: o.Name,
                                                            value: o._id,
                                                            "hide-details": "",
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { sm: "7" } },
                                        [
                                          _c(
                                            "v-subheader",
                                            {
                                              staticClass:
                                                "text-uppercase mt-8 mb-3",
                                              staticStyle: { height: "20px" },
                                            },
                                            [_vm._v("Filter Options")]
                                          ),
                                          _c(
                                            "v-row",
                                            { staticClass: "row-smaller" },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { sm: "6" } },
                                                [
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      attrs: {
                                                        "close-on-content-click": false,
                                                        "nudge-right": 32,
                                                        transition:
                                                          "scale-transition",
                                                        "offset-y": "",
                                                        "min-width": "290px",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  _vm._g(
                                                                    {
                                                                      attrs: {
                                                                        placeholder:
                                                                          "Data start date",
                                                                        hint: "From date",
                                                                        "prepend-icon":
                                                                          "mdi-calendar",
                                                                        "persistent-hint":
                                                                          "",
                                                                        readonly:
                                                                          "",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .options
                                                                            .StartDate,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.options,
                                                                              "StartDate",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "options.StartDate",
                                                                      },
                                                                    },
                                                                    on
                                                                  )
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        791518891
                                                      ),
                                                      model: {
                                                        value:
                                                          _vm.modalStartDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.modalStartDate =
                                                            $$v
                                                        },
                                                        expression:
                                                          "modalStartDate",
                                                      },
                                                    },
                                                    [
                                                      _c("v-date-picker", {
                                                        attrs: {
                                                          max: new Date()
                                                            .toISOString()
                                                            .substr(0, 10),
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            _vm.modalStartDate = false
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.options
                                                              .StartDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.options,
                                                              "StartDate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "options.StartDate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { sm: "6" } },
                                                [
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      attrs: {
                                                        "close-on-content-click": false,
                                                        "nudge-right": 32,
                                                        transition:
                                                          "scale-transition",
                                                        "offset-y": "",
                                                        "min-width": "290px",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-text-field",
                                                                  _vm._g(
                                                                    {
                                                                      attrs: {
                                                                        placeholder:
                                                                          "Data end date",
                                                                        hint: "To date",
                                                                        "prepend-icon":
                                                                          "mdi-calendar",
                                                                        "persistent-hint":
                                                                          "",
                                                                        readonly:
                                                                          "",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .options
                                                                            .EndDate,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.options,
                                                                              "EndDate",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "options.EndDate",
                                                                      },
                                                                    },
                                                                    on
                                                                  )
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        3020990406
                                                      ),
                                                      model: {
                                                        value: _vm.modalEndDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.modalEndDate = $$v
                                                        },
                                                        expression:
                                                          "modalEndDate",
                                                      },
                                                    },
                                                    [
                                                      _c("v-date-picker", {
                                                        attrs: {
                                                          max: new Date()
                                                            .toISOString()
                                                            .substr(0, 10),
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            _vm.modalEndDate = false
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.options.EndDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.options,
                                                              "EndDate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "options.EndDate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { staticClass: "row-smaller" },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { sm: "12" } },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: _vm.tagOptions,
                                                      "item-text": "Name",
                                                      "item-value": "_id",
                                                      multiple: "",
                                                      disabled: "",
                                                      label:
                                                        "Filter data by project tags",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value: _vm.options.Tags,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.options,
                                                          "Tags",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "options.Tags",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { staticClass: "row-smaller" },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { sm: "12" } },
                                                [
                                                  _c("v-filter", {
                                                    staticClass: "mb-4",
                                                    attrs: {
                                                      filterList: _vm.filters,
                                                      surveyFilterFields:
                                                        _vm.filterFields,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { staticClass: "row-smaller" },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { sm: "12" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        disabled:
                                                          _vm.options
                                                            .SurveyId === null,
                                                      },
                                                      on: {
                                                        click: _vm.onViewReport,
                                                      },
                                                    },
                                                    [_vm._v("View Report")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.viewReport
                        ? _c(
                            "v-card-text",
                            { staticClass: "flex-grow-1 pa-0" },
                            [
                              _vm.noData
                                ? _c(
                                    "v-alert",
                                    {
                                      staticClass: "ma-2",
                                      attrs: { prominent: "", type: "info" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { align: "center" } },
                                        [
                                          _c("v-col", { staticClass: "grow" }, [
                                            _vm._v(
                                              " No data found. The specified criteria yielded no results."
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " If you are certain that there is data, review and amend the specified filter options and try again. "
                                            ),
                                          ]),
                                          _c(
                                            "v-col",
                                            { staticClass: "shrink" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { text: "" },
                                                  on: {
                                                    click: _vm.onToOptionsClick,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { staticClass: "mr-1" },
                                                    [
                                                      _vm._v(
                                                        "mdi-arrow-left-circle"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "Amend Filter Options"
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "d-flex flex-column" },
                                    [
                                      _c("div", { staticClass: "mt-4 ml-2" }, [
                                        _c("div", { staticClass: "f-b ml-0" }, [
                                          _vm._v(
                                            "Total records: " +
                                              _vm._s(
                                                _vm.$format.int(_vm.pager.total)
                                              )
                                          ),
                                        ]),
                                      ]),
                                      _c("v-divider"),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "el-clearfix pb-1 pr-2",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "mt-1 float-left" },
                                            [
                                              _c("v-pagination", {
                                                staticStyle: { width: "auto" },
                                                attrs: {
                                                  length: _vm.pager.pages,
                                                  "total-visible": 10,
                                                },
                                                on: {
                                                  next: _vm.loadData,
                                                  previous: _vm.loadData,
                                                  input: _vm.loadData,
                                                },
                                                model: {
                                                  value: _vm.pager.page,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.pager,
                                                      "page",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "pager.page",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mt-3 pl-2 float-right",
                                            },
                                            [
                                              _c("v-numeric", {
                                                staticClass: "el-text-right",
                                                staticStyle: {
                                                  "max-width": "90px",
                                                },
                                                attrs: {
                                                  maxlength: 11,
                                                  label: "Go to Page",
                                                  dense: "",
                                                  placeholder: "Page #",
                                                  hint: `Max: ${_vm.$format.int(
                                                    _vm.pager.pages
                                                  )}`,
                                                  "persistent-hint": "",
                                                },
                                                on: {
                                                  enter: _vm.loadData,
                                                  blur: _vm.loadData,
                                                },
                                                model: {
                                                  value: _vm.pager.page,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.pager,
                                                      "page",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "pager.page",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mt-3 pl-2 float-right",
                                            },
                                            [
                                              _c("v-select", {
                                                staticStyle: {
                                                  "max-width": "80px",
                                                },
                                                attrs: {
                                                  items: _vm.pageSizes,
                                                  label: "Page Size",
                                                  dense: "",
                                                  "hide-details": "",
                                                },
                                                on: { change: _vm.loadData },
                                                model: {
                                                  value: _vm.pager.size,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.pager,
                                                      "size",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "pager.size",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._l(_vm.reportData, function (o) {
                                        return _c(
                                          "v-card",
                                          {
                                            key: o._id,
                                            staticClass:
                                              "d-flex flex-column ml-2 mr-2 mt-0 mb-2",
                                            attrs: { elevation: 2 },
                                          },
                                          [
                                            _vm.allowPdf(o._id)
                                              ? _c(
                                                  "v-row",
                                                  {
                                                    staticClass: "mt-2",
                                                    attrs: { dense: "" },
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "pt-0",
                                                        attrs: { sm: "12" },
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                    attrs,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "primary",
                                                                                  icon: "",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.showTemplateOptions(
                                                                                      o._id
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            "v-btn",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  dark: "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " mdi-file-pdf-box "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Export as PDF"
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "f-b ml-2 el-text-truncate",
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(o.UserName) + " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "f-s ml-2 mr-2 opa-5",
                                              },
                                              [
                                                _vm._v(" " + _vm._s(o.Date)),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "float-right",
                                                  },
                                                  [_vm._v(_vm._s(o.Version))]
                                                ),
                                              ]
                                            ),
                                            _c("v-divider"),
                                            _c(
                                              "v-card-text",
                                              {
                                                staticClass: "flex-grow-1 pa-0",
                                              },
                                              [
                                                _c(
                                                  "v-container",
                                                  {
                                                    staticClass: "pt-0",
                                                    attrs: { fluid: "" },
                                                  },
                                                  _vm._l(
                                                    o.Values.filter(
                                                      (x) =>
                                                        x.Value !== null &&
                                                        x.Value !== undefined &&
                                                        x.Value !== ""
                                                    ),
                                                    function (item) {
                                                      return _c(
                                                        "div",
                                                        { key: item.Field },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              staticClass:
                                                                "mt-2",
                                                              attrs: {
                                                                dense: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "pt-0",
                                                                  attrs: {
                                                                    sm: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "f-s",
                                                                    staticStyle:
                                                                      {
                                                                        "line-height":
                                                                          "1",
                                                                      },
                                                                    domProps: {
                                                                      innerHTML:
                                                                        _vm._s(
                                                                          item.Title
                                                                        ),
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-row",
                                                            {
                                                              staticClass:
                                                                "mt-0",
                                                              attrs: {
                                                                dense: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "pt-0",
                                                                  attrs: {
                                                                    sm: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "body-1",
                                                                    },
                                                                    [
                                                                      item.Type ===
                                                                      "ImageArray"
                                                                        ? _c(
                                                                            "v-container",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  padding:
                                                                                    "4px 0px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  fluid:
                                                                                    "",
                                                                                  "grid-list-md":
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-layout",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      row: "",
                                                                                      wrap: "",
                                                                                    },
                                                                                },
                                                                                _vm._l(
                                                                                  item.Value,
                                                                                  function (
                                                                                    img
                                                                                  ) {
                                                                                    return _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        key: img,
                                                                                        attrs:
                                                                                          {
                                                                                            xs4: "",
                                                                                            md2: "",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-img",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                "max-width":
                                                                                                  "300px",
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                src: img,
                                                                                                width:
                                                                                                  "100%",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : item.Type ===
                                                                            "Image" ||
                                                                          item.Type ===
                                                                            "Signature" ||
                                                                          item.Type ===
                                                                            "Sketch"
                                                                        ? _c(
                                                                            "v-container",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  padding:
                                                                                    "4px 0px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  fluid:
                                                                                    "",
                                                                                  "grid-list-md":
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-layout",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      row: "",
                                                                                      wrap: "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-flex",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          xs4: "",
                                                                                          md2: "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "img",
                                                                                        {
                                                                                          staticStyle:
                                                                                            {
                                                                                              "max-width":
                                                                                                "300px",
                                                                                            },
                                                                                          attrs:
                                                                                            {
                                                                                              src: item.Value,
                                                                                              alt: item.Title,
                                                                                              width:
                                                                                                "100%",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : item.Type ===
                                                                            "FileUpload" &&
                                                                          _vm.uploadIsImage(
                                                                            item.Value
                                                                          )
                                                                        ? _c(
                                                                            "v-container",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  padding:
                                                                                    "4px 0px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  fluid:
                                                                                    "",
                                                                                  "grid-list-md":
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-layout",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      row: "",
                                                                                      wrap: "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-flex",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          xs4: "",
                                                                                          md2: "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "img",
                                                                                        {
                                                                                          staticStyle:
                                                                                            {
                                                                                              "max-width":
                                                                                                "300px",
                                                                                            },
                                                                                          attrs:
                                                                                            {
                                                                                              src:
                                                                                                item
                                                                                                  .Value
                                                                                                  .Value ||
                                                                                                _vm.noImage(),
                                                                                              alt: item
                                                                                                .Value
                                                                                                .filename,
                                                                                              width:
                                                                                                "100%",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : item.Type ===
                                                                            "FileUpload" &&
                                                                          !_vm.uploadIsImage(
                                                                            item.Value
                                                                          )
                                                                        ? _c(
                                                                            "v-container",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  padding:
                                                                                    "4px 0px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  fluid:
                                                                                    "",
                                                                                  "grid-list-md":
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-btn",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      href: _vm.getFileUploadValue(
                                                                                        item
                                                                                      ),
                                                                                      target:
                                                                                        "_blank",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        item
                                                                                          .Value
                                                                                          .filename
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : Array.isArray(
                                                                            item.Value
                                                                          )
                                                                        ? _c(
                                                                            "v-simple-table",
                                                                            {
                                                                              scopedSlots:
                                                                                _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key: "default",
                                                                                      fn: function () {
                                                                                        return [
                                                                                          item.InputType !==
                                                                                          22
                                                                                            ? _c(
                                                                                                "thead",
                                                                                                [
                                                                                                  _c(
                                                                                                    "tr",
                                                                                                    [
                                                                                                      _c(
                                                                                                        "th",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "text-left",
                                                                                                          staticStyle:
                                                                                                            {
                                                                                                              width:
                                                                                                                "50%",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "b",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "Question"
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "th",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "text-left",
                                                                                                          staticStyle:
                                                                                                            {
                                                                                                              width:
                                                                                                                "50%",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "b",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "Answer"
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          item.InputType !==
                                                                                          22
                                                                                            ? _c(
                                                                                                "tbody",
                                                                                                _vm._l(
                                                                                                  item.Value,
                                                                                                  function (
                                                                                                    qa
                                                                                                  ) {
                                                                                                    return _c(
                                                                                                      "tr",
                                                                                                      {
                                                                                                        key: qa._id,
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "td",
                                                                                                          {
                                                                                                            staticStyle:
                                                                                                              {
                                                                                                                width:
                                                                                                                  "50%",
                                                                                                              },
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              " " +
                                                                                                                _vm._s(
                                                                                                                  qa.Question
                                                                                                                ) +
                                                                                                                " "
                                                                                                            ),
                                                                                                          ]
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "td",
                                                                                                          {
                                                                                                            staticStyle:
                                                                                                              {
                                                                                                                width:
                                                                                                                  "50%",
                                                                                                              },
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              " " +
                                                                                                                _vm._s(
                                                                                                                  qa.Value
                                                                                                                    ? "true"
                                                                                                                    : "false"
                                                                                                                ) +
                                                                                                                " "
                                                                                                            ),
                                                                                                          ]
                                                                                                        ),
                                                                                                      ]
                                                                                                    )
                                                                                                  }
                                                                                                ),
                                                                                                0
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          item.InputType ===
                                                                                          22
                                                                                            ? _c(
                                                                                                "thead",
                                                                                                [
                                                                                                  _c(
                                                                                                    "tr",
                                                                                                    [
                                                                                                      _c(
                                                                                                        "th",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "text-left",
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "b",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "Driver ID"
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "th",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "text-left",
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "b",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "Driver Name"
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "th",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "text-left",
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "b",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "Driver Surname"
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          item.InputType ===
                                                                                          22
                                                                                            ? _c(
                                                                                                "tbody",
                                                                                                _vm._l(
                                                                                                  item.Value,
                                                                                                  function (
                                                                                                    qa
                                                                                                  ) {
                                                                                                    return _c(
                                                                                                      "tr",
                                                                                                      {
                                                                                                        key: qa._id,
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "td",
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              _vm._s(
                                                                                                                qa.DriverId
                                                                                                              )
                                                                                                            ),
                                                                                                          ]
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "td",
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              _vm._s(
                                                                                                                qa.DriverName
                                                                                                              )
                                                                                                            ),
                                                                                                          ]
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "td",
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              _vm._s(
                                                                                                                qa.DriverSurname
                                                                                                              )
                                                                                                            ),
                                                                                                          ]
                                                                                                        ),
                                                                                                      ]
                                                                                                    )
                                                                                                  }
                                                                                                ),
                                                                                                0
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ]
                                                                                      },
                                                                                      proxy: true,
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                            }
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  padding:
                                                                                    "4px 0px",
                                                                                },
                                                                              domProps:
                                                                                {
                                                                                  innerHTML:
                                                                                    _vm._s(
                                                                                      _vm.getItemValue(
                                                                                        item.Value
                                                                                      )
                                                                                    ),
                                                                                },
                                                                            }
                                                                          ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }),
                                      _c("v-divider"),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "el-clearfix pb-1 pr-2",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "mt-1 float-left" },
                                            [
                                              _c("v-pagination", {
                                                staticStyle: { width: "auto" },
                                                attrs: {
                                                  length: _vm.pager.pages,
                                                  "total-visible": 10,
                                                },
                                                on: {
                                                  next: _vm.loadData,
                                                  previous: _vm.loadData,
                                                  input: _vm.loadData,
                                                },
                                                model: {
                                                  value: _vm.pager.page,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.pager,
                                                      "page",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "pager.page",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "v-bottom-sheet",
            {
              attrs: { inset: "" },
              model: {
                value: _vm.showsheet,
                callback: function ($$v) {
                  _vm.showsheet = $$v
                },
                expression: "showsheet",
              },
            },
            [
              _c(
                "v-sheet",
                {
                  staticClass: "text-center",
                  staticStyle: { "min-height": "200px" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-6",
                      attrs: { text: "", color: "red" },
                      on: {
                        click: function ($event) {
                          _vm.showsheet = !_vm.showsheet
                        },
                      },
                    },
                    [_vm._v(" close ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "py-3" },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { sm: "3" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.templateListFiltered,
                                  "item-text": "Name",
                                  "item-value": "_id",
                                  label: "Select your export template",
                                },
                                model: {
                                  value: _vm.selectedTemplate,
                                  callback: function ($$v) {
                                    _vm.selectedTemplate = $$v
                                  },
                                  expression: "selectedTemplate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-3",
                          attrs: {
                            color: "primary",
                            disabled: _vm.selectedTemplate === "",
                            loading: _vm.isRendering,
                          },
                          on: { click: _vm.renderPdf },
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v("mdi-file-pdf-box"),
                          ]),
                          _vm._v("Show "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.selectedTemplate !== "" && _vm.selectedRecord !== ""
            ? _c(
                "v-dialog",
                {
                  attrs: {
                    scrollable: "",
                    fullscreen: "",
                    transition: "dialog-bottom-transition",
                  },
                  model: {
                    value: _vm.showPdf,
                    callback: function ($$v) {
                      _vm.showPdf = $$v
                    },
                    expression: "showPdf",
                  },
                },
                [
                  _c(
                    "v-card",
                    { attrs: { loading: _vm.isRendering } },
                    [
                      _c(
                        "v-toolbar",
                        {
                          attrs: {
                            dark: "",
                            color: "primary",
                            elevation: "4",
                            flat: "",
                          },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "", "x-small": "" },
                              on: {
                                click: function ($event) {
                                  _vm.showPdf = false
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          ),
                          _c("v-toolbar-title", [_vm._v("PDF Preview")]),
                          _c("v-spacer"),
                        ],
                        1
                      ),
                      _vm.pdfSource !== ""
                        ? _c("vue-pdf-app", {
                            attrs: {
                              pdf: _vm.pdfSource,
                              title: false,
                              "page-number": 1,
                              "file-name": _vm.filename,
                              config: _vm.pdfViewerConfig,
                            },
                          })
                        : _vm._e(),
                      _vm.pdfSource === ""
                        ? _c(
                            "v-card",
                            { attrs: { height: "100%" } },
                            [
                              _vm.isRendering
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                            "justify-content": "center",
                                          },
                                          attrs: {
                                            cols: "12",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _c("v-progress-circular", {
                                            attrs: {
                                              size: 70,
                                              width: 7,
                                              color: "primary",
                                              indeterminate: "",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.isRendering
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                            "justify-content": "center",
                                          },
                                          attrs: {
                                            cols: "12",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _c("h1", [
                                            _vm._v("Could not load PDF "),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }