var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        "nudge-right": 32,
        "nudge-top": 20,
        transition: "scale-transition",
        "offset-y": "",
        "max-height": "70%",
        "min-width": "290px",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  _vm._b(
                    {
                      ref: "field",
                      attrs: {
                        "error-messages": _vm.errorMessages,
                        "prepend-icon": "mdi-list-box-outline",
                        readonly: "",
                      },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.onEnter.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.displayValue,
                        callback: function ($$v) {
                          _vm.displayValue = $$v
                        },
                        expression: "displayValue",
                      },
                    },
                    "v-text-field",
                    _vm.$attrs,
                    false
                  ),
                  on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.modalPop,
        callback: function ($$v) {
          _vm.modalPop = $$v
        },
        expression: "modalPop",
      },
    },
    [
      _c(
        "v-list",
        { staticClass: "pl-2 pr-2", attrs: { dense: "" } },
        [
          _c(
            "v-list-item",
            { attrs: { dense: "" } },
            [
              _c(
                _vm.filterValueControl,
                _vm._b(
                  {
                    ref: "inputBox",
                    tag: "component",
                    attrs: {
                      autofocus: "",
                      "append-icon": "mdi-plus-circle-outline",
                      inputType: _vm.filterValueInput,
                    },
                    on: {
                      change: _vm.onChange,
                      "send-value": _vm.addValue,
                      "append-click": _vm.addValue,
                      paste: _vm.onPaste,
                      focus: function ($event) {
                        return $event.target.select()
                      },
                    },
                    model: {
                      value: _vm.addingValue,
                      callback: function ($$v) {
                        _vm.addingValue = $$v
                      },
                      expression: "addingValue",
                    },
                  },
                  "component",
                  _vm.$props,
                  false
                )
              ),
              _vm._t("default"),
            ],
            2
          ),
          _vm._l(_vm.model, function (item, index) {
            return _c(
              "v-list-item",
              { key: item, attrs: { dense: "" } },
              [
                _vm._v(" " + _vm._s(item) + " "),
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    staticClass: "ml-1",
                    attrs: { icon: "" },
                    on: {
                      click: function ($event) {
                        return _vm.removeValue(index)
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("mdi-minus-circle-outline")])],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }