<template>
    <v-switch
        ref="field"
        inset
        tabindex="0"
        v-model="model"
        :label="model ? 'Yes / true' : 'No / false'"
        v-on:keyup.enter="onEnter"
        @change="handleChange"
        @click:append="onAppendClick"
        :error-messages="errorMessages"
        v-bind="$attrs">
    </v-switch>
</template>

<script>
export default {
    name: 'v-filter-boolean',
    props: {
        value: null,
        'error-messages': null,
    },
    data () {
        return {
            model: this.value,
        };
    },
    methods: {
        handleChange () {
            this.$emit('change', this.model);
        },
        focus () {
            // this.focus();
        },
        onEnter () {
            return this.$emit('send-value', this.model);
        },
        onAppendClick () {
            return this.$emit('append-click', this.model);
        }
    },
    watch: {
        value () {
            this.model = this.value;
        },
    }
};
</script>
