<template>
    <v-menu
        v-model="modalPop"
        :close-on-content-click="false"
        :nudge-right="32"
        transition="scale-transition"
        offset-y
        min-width="290px">
        <template v-slot:activator="{ on }">
            <v-text-field
                ref="field"
                v-model="model"
                v-on:keyup.enter="onEnter"
                @click:append="onAppendClick"
                :error-messages="errorMessages"
                prepend-icon="mdi-clock-outline"
                format="24hr"
                scrollable
                readonly
                v-bind="$attrs"
                v-on="on">
            </v-text-field>
        </template>
        <v-time-picker v-model="model" @input="modalPop = false"></v-time-picker>
    </v-menu>
</template>

<script>
export default {
    name: 'v-filter-time',
    props: {
        value: null,
        'error-messages': null,
    },
    data () {
        return {
            model: this.value,
            modalPop: false,
        };
    },
    methods: {
        handleChange () {
            this.$emit('change', this.model);
        },
        focus () {
            this.$refs.field.focus();
        },
        onEnter () {
            return this.$emit('send-value', this.model);
        },
        onAppendClick () {
            return this.$emit('append-click', this.model);
        }
    },
    watch: {
        value () {
            this.model = this.value;
        },
        modalPop () {
            if (!this.modalPop) this.handleChange();
        }
    }
};
</script>
