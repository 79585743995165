<template>
    <div>
        <div v-if="!disableFilter">
            <v-text-field
                v-model="filterValue"
                class="w-512-max"
                :clearable="true"
                dense
                single-line
                hint="Type to search and filter the available options"
                persistent-hint
                @keydown="filterData"
                @click:clear="clearFilter"
            ></v-text-field>
            <div class="text-center" v-if="selectedType && selectedType.value !== '0' && totalPage > 1">
                <v-pagination
                v-model="currentpage"
                :length="totalPage"
                :total-visible="5"
                ></v-pagination>
            </div>
        </div>
        <div v-if="selectedSourceValue.length > 0 && selectedType.value === '2'" >
            <v-chip v-for="item in selectedSourceValue" :key="item">
            {{item}}
            </v-chip>
        </div>
        <v-chip v-else-if="selectedSourceValue.length > 0 && selectedType.value !== '2'" >{{selectedSourceValue[0]}}</v-chip>
        <div style="max-height: 350px; overflow-x: auto;">
            <div v-if="selectedType && selectedType.value === '0'">
                <v-select
                    label="Source Data Preview"
                    :items="selectedSourceItems"
                    item-text="text"
                    item-value="value"
                    persistent-hint
                    v-model="selectedDropdownValue"
                    @change="onDropdownValueChanged"
                ></v-select>
            </div>

            <div v-if="selectedType && selectedType.value === '1'">
                <v-radio-group v-model="selectedRadioValue" @change="onRadioValueChanged">
                    <v-radio
                        v-for="item in selectedSourceItems"
                        :key="item.value"
                        :label="item.text"
                        :value="item.value"
                    ></v-radio>
                </v-radio-group>
            </div>

            <div v-if="selectedType && selectedType.value === '2'">
                <v-checkbox
                    v-for="item in selectedSourceItems" :key="item.value"
                    :label="item.text"
                    :value="item.text"
                    v-model="selectedSourceValue"
                    @change="onCheckboxChange"
                    class="mt-1"
                    hide-details>
                </v-checkbox>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DynamicLookup',
    props: {
        items: {
            type: Array,
            default () {
                return null;
            }
        },
        selectedItem: {
            type: Object,
            default () {
                return null;
            },
        },
        selectedType: {
            type: Object,
            default () {
                return null;
            },
        },
        disableFilter: {
            type: Boolean,
            default () {
                return false;
            },
        },
        selectedValues: {
            type: Array,
            default () {
                return null;
            }
        }
    },
    data () {
        return {
            filterValue: '',
            selectedDropdownValue: null,
            selectedRadioValue: null,
            selectedCheckboxValues: [],
            selectedSourceItems: [],
            selectedSourceValue: [],
            currentpage: 1,
            totalPage: 1,
            itemsPerpage: 50,
            recordStart: 0,
            recordEnd: 0,
            totalItems: 0,
        };
    },
    isEmpty (obj) {
        return Object.keys(obj).length === 0;
    },
    mounted () {
        if (this.selectedValues.length > 0) this.selectedSourceValue = this.selectedValues;
    },
    watch: {
        currentpage: {
            handler (newValues) {
                this.filterValue = '';
                this.filterData();
            }
        },
        items: {
            handler (newValues) {
                if (this.filterValue !== '') this.filterValue = '';
                this.currentpage = 1;
                this.selectedSourceItems = newValues;
                this.selectedSourceValue = this.selectedValues;
                this.filterData();
            }
        },
        selectedValues: {
            deep: true,
            handler (newValues) {
                this.selectedSourceValue = newValues;
            }
        }
    },
    methods: {
        clearFilter () {
            this.filterValue = '';
            this.filterData();
        },
        updateValues (newValues, items) {
            if (this.selectedType.value === '0') {
                items.forEach(listElement => {
                    if (newValues[0] === listElement.text) {
                        this.selectedDropdownValue = listElement.value;
                        this.onDropdownValueChanged(listElement.value);
                    }
                });
            }

            if (this.selectedType.value === '1') {
                items.forEach(listElement => {
                    if (newValues[0] === listElement.text) {
                        this.selectedRadioValue = listElement.value;
                        this.onRadioValueChanged(listElement.value);
                    }
                });
            }

            if (this.selectedType.value === '2') {
                if (this.items.length > 0 && this.items !== undefined) {
                    if (newValues.length > 0) this.onCheckboxChange(newValues);
                }
            }
        },
        setRecordValues (currentpage) {
            this.totalItems = this.items.length;
            this.totalPage = Math.ceil((this.totalItems / this.itemsPerpage));
            this.recordStart = ((currentpage - 1) * this.itemsPerpage);
            this.recordEnd = this.recordStart + this.itemsPerpage;
            if (this.recordEnd > this.totalItems) this.recordEnd = this.totalItems;
        },
        onDropdownValueChanged (value) {
            this.emitSelectedValue([value]);
        },
        onRadioValueChanged (value) {
            if (this.selectedSourceValue.length === 0 && value !== undefined) this.emitSelectedValue([value]);
            else if (this.selectedSourceValue.length > 0 && value !== undefined) {
                this.selectedSourceValue[0] = value;
                this.emitSelectedValue([value]);
            }
        },
        onCheckboxChange (newValues) {
            const vals = newValues.map(value => this.items.find(s => s.text === value).value);
            this.emitSelectedValue(vals);
        },
        emitSelectedValue (values) {
            if (this.items.length > 0 && this.items !== undefined) {
                const selectedValues = values.map(value => this.items.find(s => s.value === value).text);
                this.selectedSourceValue = selectedValues;
                this.$emit('selected-value', selectedValues);
            }
        },
        filterData () {
            this.setRecordValues(this.currentpage);
            if (!this.filterValue && this.filterValue === '') {
                this.selectedSourceItems = this.items.slice(this.recordStart, this.recordEnd);
                if (this.selectedSourceValue.length > 0) this.updateValues(this.selectedSourceValue, this.selectedSourceItems);
            }
            else {
                try {
                    const { items, filterValue, recordStart, recordEnd } = this;
                    // Escape special characters in filterValue to safely use it in a regex
                    const escapedFilterValue = filterValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                    // Create a regex pattern with word boundaries for the filter value
                    const regex = new RegExp('\\b' + escapedFilterValue + '\\S' + '\\b', 'i');
                    let filteredItems = items
                        .filter(element => regex.test(element.text))
                        .slice(recordStart, recordEnd);
                    if (filteredItems.length === 0) {
                        const escapedFilterValue = filterValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                        const regex = new RegExp(escapedFilterValue, 'i');
                        filteredItems = items
                            .filter(element => regex.test(element.text))
                            .slice(recordStart, recordEnd);
                    }
                    this.selectedSourceItems = filteredItems;
                    if (this.selectedSourceValue.length > 0) this.updateValues(this.selectedSourceValue, this.selectedSourceItems);
                }
                catch (error) {
                    this.selectedSourceItems = [];
                }
            }
        }
    },
};
</script>
