// import axios from 'axios';
// import config from '@/config';

export default function guest ({ next }) {
    const userDetails = localStorage.srvy__User;
    if (userDetails !== undefined) {
        return next();
    }
    else {
        return next('/SignOut');
    }
}
