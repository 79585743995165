<template>
    <v-container>
        <v-row>
            <v-col sm="12" class="mb-0 pb-0">
                <h1 class="title font-weight-light">
                    <v-icon v-if="this.preview" @click="onBack">mdi-arrow-left-circle</v-icon>
                    {{ this.item.Name }}&nbsp;<span class="font-weight-light subtitle-1" v-html="this.preview ? '<b class=accent--text>PREVIEW</b>. No answers will be saved' : ''"></span>
                </h1>
            </v-col>
            <v-col sm="12" class="mt-0 pt-0 w-1024">
                <v-card-actions class="ma-0 pa-0">
                    <span class="font-weight-light f-m">{{ user.FullName }}</span>
                    <v-spacer></v-spacer>
                    <span class="font-weight-light f-s">{{ $format.dateTime(new Date()) }}</span>
                </v-card-actions>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col sm="12">
                <v-card :loading="isBusy" class="w-1024">
                    <v-container>
                        <v-card-text>
                            <div class="question-label" v-if="item.ShowStart" v-html="item.StartMessage"></div>
                        </v-card-text>
                        <v-card-text style="min-height:300px;">
                            <div v-for="question in this.item.Questions" :key="question._id">
                                <div class="question-label-form" v-html="question.Question[0].Value"></div>
                                <div>
                                    <!-- Text -->
                                    <v-text-field
                                        v-if="question.InputType === $CONST.INPUT_TYPE.Text && question.Lines < 2"
                                        v-model="question.Answer"
                                        :counter="question.Max || false"
                                        :minlength="question.Min || false"
                                        :maxlength="question.Max || false"
                                        :prefix="question.Pre"
                                        :suffix="question.Post"
                                        :placeholder="question.InHint"
                                        :hint="question.OutHint"
                                        @blur="onAnswerBlur(question.Index)"
                                        persistent-hint>
                                    </v-text-field>
                                    <v-textarea
                                        v-else-if="question.InputType === $CONST.INPUT_TYPE.Text && question.Lines > 1"
                                        v-model="question.Answer"
                                        :rows="question.Lines || 2"
                                        :counter="question.Max || false"
                                        :minlength="question.Min || false"
                                        :maxlength="question.Max || false"
                                        :prefix="question.Pre"
                                        :suffix="question.Post"
                                        :placeholder="question.InHint"
                                        :hint="question.OutHint"
                                        @blur="onAnswerBlur(question.Index)"
                                        persistent-hint>
                                    </v-textarea>
                                    <!-- Number -->
                                    <div v-else-if="question.InputType === $CONST.INPUT_TYPE.Number">
                                        <v-numeric
                                            v-model="question.Answer"
                                            :maxlength="11"
                                            :prefix="question.Pre"
                                            :suffix="question.Post"
                                            :placeholder="question.InHint"
                                            :hint="question.OutHint"
                                            @blur="onAnswerBlur(question.Index)"
                                            persistent-hint>
                                        </v-numeric>
                                        <span class="caption ml-2 mt-0" v-if="!isBlank(question.Min) || !isBlank(question.Max)">{{ !isBlank(question.Min) && !isBlank(question.Max) ? `Must be between ${question.Min} and ${question.Max}` : (isBlank(question.Min) ? `Must not be greater than ${question.Max}` : `Must not be less than ${question.Min}`)}}</span>
                                    </div>
                                    <!-- Mobile -->
                                    <v-mask
                                        v-else-if="question.InputType === $CONST.INPUT_TYPE.Mobile"
                                        v-model="question.Answer"
                                        :prefix="question.Pre"
                                        :suffix="question.Post"
                                        :placeholder="question.InHint"
                                        :hint="question.OutHint"
                                        @blur="onAnswerBlur(question.Index)"
                                        prepend-icon="mdi-cellphone"
                                        persistent-hint>
                                    </v-mask>
                                    <!-- Email -->
                                    <v-text-field
                                        v-else-if="question.InputType === $CONST.INPUT_TYPE.Email"
                                        v-model="question.Answer"
                                        :counter="question.Max || false"
                                        :minlength="question.Min || false"
                                        :maxlength="question.Max || false"
                                        :prefix="question.Pre"
                                        :suffix="question.Post"
                                        :placeholder="question.InHint"
                                        :hint="question.OutHint"
                                        :rules="[rules.email]"
                                        @blur="onAnswerBlur(question.Index)"
                                        prepend-icon="mdi-email-open-outline"
                                        persistent-hint>
                                    </v-text-field>
                                    <!-- WebAddress -->
                                    <v-text-field
                                        v-else-if="question.InputType === $CONST.INPUT_TYPE.WebAddress"
                                        v-model="question.Answer"
                                        :counter="question.Max || false"
                                        :minlength="question.Min || false"
                                        :maxlength="question.Max || false"
                                        :prefix="question.Pre"
                                        :suffix="question.Post"
                                        :placeholder="question.InHint"
                                        :hint="question.OutHint"
                                        :rules="[rules.web]"
                                        @blur="onAnswerBlur(question.Index)"
                                        prepend-icon="mdi-web"
                                        persistent-hint>
                                    </v-text-field>
                                    <!-- YesNo -->
                                    <v-btn-toggle
                                        v-else-if="question.InputType === $CONST.INPUT_TYPE.YesNo"
                                        v-model="question.Answer"
                                        color="accent"
                                        group
                                        tile>
                                        <v-btn :value="true" class="pl-11 pr-11" @blur="onAnswerBlur(question.Index)">
                                            Yes
                                        </v-btn>
                                        <v-btn :value="false" class="pl-12 pr-12" @blur="onAnswerBlur(question.Index)">
                                            No
                                        </v-btn>
                                    </v-btn-toggle>
                                    <!-- Select One -->
                                    <div v-else-if="question.InputType === $CONST.INPUT_TYPE.SelectOne">
                                        <!-- Filter -->
                                        <!-- <v-text-field v-show="question.Filter" v-model="filterOne()" class="w-512-max" dense single-line hint="Type to search and filter the available options" prepend-icon="mdi-magnify" persistent-hint></v-text-field>
                                        <span v-show="question.Filter" class="caption opa-6 ml-8">A maximum result of 20 options will be shown.</span> -->
                                        <!-- Options -->
                                        <v-radio-group v-model="question.Answer" @change="onAnswerBlur(question.Index)">
                                            <v-radio
                                                v-for="item in question.Options"
                                                :key="item._id"
                                                :label="item.Value"
                                                :value="item.Value">
                                            </v-radio>
                                        </v-radio-group>
                                        <span class="caption">{{ question.OutHint }}</span>
                                    </div>
                                    <!-- Select Many -->
                                    <div v-else-if="question.InputType === $CONST.INPUT_TYPE.SelectMany">
                                        <!-- Filter -->
                                        <!-- <v-text-field v-show="question.Filter" v-model="filterMany" class="w-512-max" dense single-line hint="Type to search and filter the available options" prepend-icon="mdi-magnify" persistent-hint @keyup.native="filterManyChange"></v-text-field>
                                        <span v-show="question.Filter" class="caption opa-6 ml-8">A maximum result of 20 options will be shown.</span> -->
                                        <!-- Options -->
                                        <v-checkbox
                                            v-model="question.Answer"
                                            v-for="item in question.Options"
                                            :key="item._id"
                                            :label="item.Value"
                                            :value="item.Value"
                                            @change="onAnswerBlur(question.Index)"
                                            class="mt-1"
                                            multiple
                                            hide-details>
                                        </v-checkbox>
                                        <span class="caption mt-6 d-block">{{ question.OutHint }}</span>
                                    </div>
                                    <!-- Date -->
                                    <v-menu
                                        v-else-if="question.InputType === $CONST.INPUT_TYPE.Date"
                                        v-model="modalDate"
                                        :close-on-content-click="false"
                                        :nudge-right="32"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="question.Answer"
                                                :placeholder="question.InHint"
                                                :hint="question.OutHint"
                                                @blur="onAnswerBlur(question.Index)"
                                                prepend-icon="mdi-calendar"
                                                persistent-hint
                                                readonly
                                                v-on="on">
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="question.Answer" @input="modalDate = false"></v-date-picker>
                                    </v-menu>
                                    <!-- Time -->
                                    <v-menu
                                        v-else-if="question.InputType === $CONST.INPUT_TYPE.Time"
                                        ref="menu"
                                        v-model="modalTime"
                                        :close-on-content-click="false"
                                        :nudge-right="32"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="question.Answer"
                                                :placeholder="question.InHint"
                                                :hint="question.OutHint"
                                                @blur="onAnswerBlur(question.Index)"
                                                prepend-icon="mdi-clock-outline"
                                                format="24hr"
                                                scrollable
                                                persistent-hint
                                                readonly
                                                v-on="on">
                                            </v-text-field>
                                        </template>
                                        <v-time-picker v-model="question.Answer" @click:minute="modalTime = false"></v-time-picker>
                                    </v-menu>
                                    <!-- DOB -->
                                    <v-menu
                                        v-else-if="question.InputType === $CONST.INPUT_TYPE.DateOfBirth"
                                        v-model="modalDOB"
                                        :close-on-content-click="false"
                                        :nudge-right="32"
                                        @blur="onAnswerBlur(question.Index)"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="question.Answer"
                                                :placeholder="question.InHint"
                                                :hint="question.OutHint"
                                                prepend-icon="mdi-gift-outline"
                                                persistent-hint
                                                readonly
                                                v-on="on">
                                            </v-text-field>
                                        </template>
                                        <v-date-picker ref="previewDOB" v-model="question.Answer" @input="modalDOB = false" :max="new Date().toISOString().substr(0, 10)"></v-date-picker>
                                    </v-menu>
                                    <!-- Rating -->
                                    <div v-else-if="question.InputType === $CONST.INPUT_TYPE.Rating">
                                        <v-rating
                                            v-model="question.Answer"
                                            :length="question.Max || 5"
                                            @input="onAnswerBlur(question.Index)"
                                            color="accent"
                                            background-color="grey lighten-1"></v-rating>
                                        <span class="caption">{{ question.OutHint }}</span>
                                    </div>
                                    <!-- Slider -->
                                    <div v-else-if="question.InputType === $CONST.INPUT_TYPE.RangeSlider">
                                        <v-row class="row-smaller mt-7">
                                            <v-col sm="12" class="d-flex">
                                                <span class="body-1 mr-2" v-show="question.Pre">{{ question.Pre }}</span>
                                                <v-slider
                                                    v-model="question.Answer"
                                                    :thumb-size="24"
                                                    :min="question.Min || 0"
                                                    :max="question.Max || 10"
                                                    :step="question.Step || 1"
                                                    :hint="question.OutHint"
                                                    @input="onAnswerBlur(question.Index)"
                                                    thumb-label="always"
                                                    ticks="always"
                                                    tick-size="4"
                                                    color="accent"
                                                    persistent-hint>
                                                </v-slider>
                                                <span class="body-1 ml-2" v-show="question.Post">{{ question.Post }}</span>
                                            </v-col>
                                        </v-row>
                                        <v-row class="row-smaller">
                                            <v-col sm="12" class="text-center display-1 accent--text" align-self="center">
                                                <span>{{ question.Answer }}</span>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <!-- Image -->
                                    <div v-else-if="question.InputType === $CONST.INPUT_TYPE.Image">
                                        <v-btn color="primary" class="mr-2">
                                            <v-icon left>mdi-camera</v-icon>
                                            Take Picture
                                        </v-btn>
                                        <v-btn color="primary" v-if="question.Local" @click="onFileLoadPhotoClick">
                                            <v-icon left>mdi-folder-image</v-icon>
                                            Load Image
                                        </v-btn>
                                        <v-file-input ref="fileLoadPhoto" v-model="question.ValueFile" accept="image/*" label="Load Image" class="d-none" @change="onFileLoadChange"></v-file-input>
                                        <br/>
                                        <v-img :src="question.Answer" class="mt-2"></v-img><!-- https://picsum.photos/510/300?random -->
                                        <span class="caption">{{ question.OutHint }}</span>
                                    </div>
                                    <!-- Signature -->
                                    <div v-else-if="question.InputType === $CONST.INPUT_TYPE.Signature">
                                        <v-btn color="primary" v-if="question.Local" @click="onFileLoadSignClick">
                                            <v-icon left>mdi-folder-image</v-icon>
                                            Load Image
                                        </v-btn>
                                        <v-file-input ref="fileLoadSign" v-model="question.ValueFile" accept="image/*" label="Load Image" class="d-none" @change="onFileLoadChange"></v-file-input>
                                        <br/>
                                        <v-img :src="question.Answer" class="mt-2"></v-img><!-- https://picsum.photos/510/300?random -->
                                        <span class="caption">{{ question.OutHint }}</span>
                                    </div>
                                    <!-- GPS Location -->
                                    <div v-else-if="question.InputType === $CONST.INPUT_TYPE.GPSLocation">
                                        <v-btn color="primary" @click="getLocation(question._id)">
                                            <v-icon left>mdi-map-marker-radius</v-icon>
                                            Get Location
                                        </v-btn>
                                        <br/>
                                        <span class="caption">{{ question.OutHint }}</span>
                                        <v-row class="w-512-max mt-2">
                                            <v-col sm="4">Coordinates</v-col>
                                            <!-- <v-col sm="8" class="font-weight-bold">{{ question.Answer || '-' }}</v-col> -->
                                            <v-col sm="8" class="font-weight-bold">{{ question.Display || '-' }}</v-col>
                                        </v-row>
                                        <v-row class="w-512-max">
                                            <v-col sm="4">Latitude</v-col>
                                            <v-col sm="8">{{ question.DisplayLat || '-' }}</v-col>
                                        </v-row>
                                        <v-row class="w-512-max">
                                            <v-col sm="4">Longitude</v-col>
                                            <v-col sm="8">{{ question.DisplayLon || '-' }}</v-col>
                                        </v-row>
                                        <v-row class="w-512-max">
                                            <v-col sm="4">Accuracy</v-col>
                                            <v-col sm="8" :class="question.DisplayAccFail ? 'error--text' : ''">{{ question.DisplayAcc || '-' }}</v-col>
                                        </v-row>
                                        <v-row class="w-512-max">
                                            <v-col sm="4">Altitude</v-col>
                                            <v-col sm="8">{{ question.DisplayAlt || '-' }}</v-col>
                                        </v-row>
                                        <!-- <v-row class="w-512-max">
                                            <v-col sm="4">Speed</v-col>
                                            <v-col sm="8">{{ question.Meta.ValueSpd || '-' }}</v-col>
                                        </v-row> -->
                                    </div>
                                    <!-- Barcode -->
                                    <div v-else-if="question.InputType === $CONST.INPUT_TYPE.Barcode">
                                        <v-text-field
                                            v-model="question.Answer"
                                            prepend-icon="mdi-qrcode-scan"
                                            :readonly="!question.Manual"
                                            :prefix="question.Pre"
                                            :suffix="question.Post"
                                            :placeholder="question.InHint"
                                            :hint="question.OutHint"
                                            persistent-hint>
                                            <template v-slot:append-outer>
                                                <v-btn color="primary" @click="scanBarcode">Scan</v-btn>
                                            </template>
                                        </v-text-field>
                                        <!-- <v-row class="w-512-max mt-2">
                                            <v-col sm="12" class="font-weight-bold">{{ question.Answer || '-' }}"</v-col>
                                        </v-row> -->
                                    </div>
                                    <div v-else-if="question.InputType === $CONST.INPUT_TYPE.FileUpload">
                                        <v-fileupload
                                        :key="question._id"
                                        :parentData.sync="question.ValueFile"
                                        :validState.sync="questionErrors[question.Field]"
                                        :parentImageData.sync="imageData[question.Field]"
                                        :uploadType="question.UploadType"
                                        :uploadSizeLimit="question.UploadSizeLimit"
                                        :placeholder="question.InHint"
                                        :isOptional="question.Optional">
                                        </v-fileupload>
                                    </div>
                                </div>
                            </div>
                        </v-card-text>
                        <v-card-text>
                            <div class="question-label" v-if="item.ShowEnd" v-html="item.EndMessage"></div>
                        </v-card-text>
                        <v-alert type="error" v-show="answerNotValid">
                            Answer validation failed. Please review your answer.
                        </v-alert>
                        <div v-if="isSaveDone" class="text-right">
                            <v-icon color="success" style="font-size:100px;">mdi-check</v-icon>
                        </div>
                        <v-divider v-if="!isSaveDone"></v-divider>
                        <v-card-actions v-if="!isSaveDone">
                            <v-spacer></v-spacer>
                            <v-chip v-if="item.RatingTotal" label class="mr-2" title="Rating Total">
                                <v-icon left>mdi-calculator-variant</v-icon>
                                {{ ratingTotal }}
                            </v-chip>
                            <v-btn text :disabled="isSaving" :loading="isSaving" @click="onSave">Submit<v-icon>mdi-flag-checkered</v-icon></v-btn>
                        </v-card-actions>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-0" v-if="this.preview">
            <v-col sm="12">
                <v-container>
                    <v-btn color="primary" @click="onBack">Back</v-btn>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Constants from '@/util/Constants';
import Data from '@/util/Data';
import mask from '@/controls/Mask';
import numeric from '@/controls/Numeric';
import { mapState } from 'vuex';
import FileUpload from '@/controls/FileUpload';

/*
 * NOTE: Here, Answers are stored as arrays. Non-looping question answers are on index 0
 *       while looping question answers are on the relevant loop index.
 *       The values are extracted on save.
 */

export default {
    name: 'Act',
    components: {
        'v-mask': mask,
        'v-numeric': numeric,
        'v-fileupload': FileUpload
    },
    mounted () {
        if (this.$route.query) {
            if (this.$route.query.id) this.item._id = this.$route.query.id;
            if (this.$route.query.preview) this.preview = this.$route.query.preview;
            if (this.$route.query.external) this.isExternal = this.$route.query.external;
        }
        this.loadData();
    },
    /**
     * This is only called if the view has already been initialised, but shown/used again e.g. /User/1 and /User/2
     */
    beforeRouteUpdate (to, from, next) {
        if (this.$route.query) {
            // Reset previous values.
            this.startDate = new Date();
            this.item = { Name: '...', RatingValue: 0, Questions: [Data.duplicate(Constants.BLANK_QUESTION)] };
            if (to.query.id) this.item._id = to.query.id;
            if (to.query.preview) this.preview = to.query.preview;
            this.stepState = [];
            this.isEnd = false;
            this.answerNotValid = false;
            // this.loopCounter = 0;
            // this.loopTo = 0;
            this.ratingTotal = 0;
            this.index = -1; // this.item.ShowStart ? 0 : this.item.Questions[0].Index;
            // this.question = this.item.Questions[0];
        }
        next();
        this.loadData();
    },
    data: () => ({
        isBusy: false,
        isExternal: false,
        lang: 'en',
        item: { Name: '...', Questions: [Data.duplicate(Constants.BLANK_QUESTION)] },
        preview: false,
        modalDate: false,
        modalTime: false,
        modalDOB: false,
        // question: { Question: [{ Value: '' }], ShowRules: [], PassRules: [], RatingRules: [], Options: [] },
        // questionText: '',
        // loopCounter: 0,
        // loopTo: 0,
        answerNotValid: false,
        stepState: [], // Previous question indexes for moving backwards.
        index: -1,
        filterOne: '',
        filterMany: '',
        isEnd: false,
        isSaving: false,
        isSaveDone: false,
        startDate: new Date(),
        defStartMsg: '',
        defEndMsg: '',
        ratingTotal: 0,
        rules: {
            required: value => !!`${(value || '')}`.length || 'Required.',
            min: value => (value || '').length > 1 || 'Minimum 2 characters',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || 'Invalid e-mail.';
            },
            web: value => {
                const pattern = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
                return pattern.test(value) || 'Invalid web address.';
            }
        },
        imageData: {},
        questionErrors: {}
    }),
    methods: {
        async loadData () {
            if (!this.item._id) {
                this.isBusy = false;
                this.$hideProgress();
                this.$warning('No Questions', 'There are no questions to preview. Please add questions and make sure the form is saved before previewing.');
                this.onBack();
                return;
            }
            this.isBusy = true;
            this.$showProgress();
            try {
                const res = await this.$http.get(`/Survey/${this.item._id}`);
                if (!res.data.s) {
                    this.isBusy = false;
                    this.$hideProgress();
                    this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
                    this.onBack();
                    return;
                }
                const d = res.data;
                this.isValid = true;
                if (!d.d.Questions.length) {
                    this.isBusy = false;
                    this.$hideProgress();
                    this.$warning('No Questions', 'There are no questions to preview. Please add questions and make sure the form is saved before previewing.');
                    this.onBack();
                }
                Data.sort(d.d.Questions, 'Index');
                // Check if there are looping questions. Add the parent id to those questions.
                d.d.Questions.forEach(o => {
                    if (o.LoopParent) {
                        const nums = o.LoopParent.split(/[ ,;]+/);
                        const loopIndexes = [];
                        nums.forEach(num => {
                            if (num.indexOf('-') > -1) {
                                const startEnd = num.split('-');
                                const numStart = +startEnd[0];
                                const numEnd = +startEnd[1];
                                for (let si = numStart; si <= numEnd; si++) {
                                    loopIndexes.push(si);
                                    const q = d.d.Questions.find(x => x.Index === si);
                                    q.LoopParentIndex = o.Index;
                                }
                            }
                            else {
                                const idx = +num;
                                loopIndexes.push(idx);
                                const q = d.d.Questions.find(x => x.Index === idx);
                                q.LoopParentIndex = o.Index;
                            }
                        });
                        o.loopStartIndex = loopIndexes[0];
                        o.loopEndIndex = loopIndexes[loopIndexes.length - 1];
                    }
                    else if (o.LoopParent !== undefined) delete o.LoopParent;
                    this.imageData[o.Field] = null;
                    this.questionErrors[o.Field] = false;
                });
                this.defStartMsg = d.d.StartMessage;
                this.defEndMsg = d.d.EndMessage;
                this.item = d.d;
                if (!this.item.RatingTotal) this.item.RatingTotal = 0;
                this.index = this.item.ShowStart ? 0 : this.item.Questions[0].Index;
                // this.question = this.item.Questions[0];
                this.prepareQuestions();
            }
            catch (ex) {
                this.$error(this.$t('general.data_failed'), this.$t('general.an_error'));
            }
            finally {
                this.isBusy = false;
                this.$hideProgress();
            }
        },
        isBlank (value) {
            return value === '' || value === undefined || value === null;
        },
        onFileLoadPhotoClick () {
            const fu = this.$refs.fileLoadPhoto.$el.querySelectorAll('input');
            fu[0].click();
        },
        onFileLoadSignClick () {
            const fu = this.$refs.fileLoadSign.$el.querySelectorAll('input');
            fu[0].click();
        },
        onFileLoadChange (file) {
            const reader = new FileReader();
            reader.onload = e => {
                this.question.Answer = e.target.result;
            };
            reader.readAsDataURL(file); // Convert to base64 string.
        },
        getLocation () {
            const q = this.question;
            this.$set(q, 'Display', 'Locating...');
            this.$forceUpdate();
            const options = {
                enableHighAccuracy: true,
                timeout: (this.question.Timeout || 20) * 1000,
                maximumAge: 10

            };
            if (!q.Meta) q.Meta = [];
            this.startLocationWatch(q, options);

            // TODO: Apply timeout in options.
            /* let location = {};
            // if (Ui.isMac()) {
            location = await Ui.getLocation(options);
            // }
            // else {
            //     location = await Geolocation.getCurrentPosition(options);
            // }

            if (!q.Meta) q.Meta = [];
            const meta = q.Meta;
            const answer = q.Answer;
            const idx = q.loopCounter || 0;

            if (!meta[idx]) meta[idx] = {};
            meta[idx].ValueLat = parseFloat(location.coords.latitude.toFixed(7));
            this.$set(q, 'DisplayLat', meta[idx].ValueLat);
            meta[idx].ValueLon = parseFloat(location.coords.longitude.toFixed(7));
            this.$set(q, 'DisplayLon', meta[idx].ValueLon);
            meta[idx].ValueAcc = parseFloat(location.coords.accuracy.toFixed(2));
            this.$set(q, 'DisplayAcc', meta[idx].ValueAcc);
            meta[idx].ValueAccFail = location.coords.accuracy > q.Accuracy;
            this.$set(q, 'DisplayAccFail', meta[idx].ValueAccFail);
            meta[idx].ValueAlt = location.coords.altitude ? parseFloat(location.coords.altitude.toFixed(7)) : -1;
            this.$set(q, 'DisplayAlt', meta[idx].ValueAlt);
            meta[idx].ValueTime = location.timestamp;
            this.$set(q, 'DisplayTime', new Date(meta[idx].ValueTime).toISOString());
            // meta.ValueArr = [meta[idx].ValueLon, meta[idx].ValueLat]; // Mongo stores as long then lat.
            meta[idx].ValueDisplay = this.$format.dmsLatLong(meta[idx].ValueLat, meta[idx].ValueLon);
            this.$set(q, 'Display', meta[idx].ValueDisplay);
            this.$forceUpdate();

            // q.Accuracy
            // q.GPSSkip

            // answer[idx] = this.$format.dmsLatLong(meta[idx].ValueLat, meta[idx].ValueLon);
            answer[idx] = [meta[idx].ValueLon, meta[idx].ValueLat]; // Mongo stores as long then lat. */

            // q.Answer = q.Answer.slice();
            /* navigator.geolocation.getCurrentPosition(location => {
                // q.ValueLat = parseFloat(location.coords.latitude.toFixed(7));
                // q.ValueLon = parseFloat(location.coords.longitude.toFixed(7));
                // q.ValueAcc = location.coords.accuracy;
                // q.ValueAccFail = location.coords.accuracy > q.Accuracy;
                // q.ValueAlt = location.coords.altitude ? parseFloat(location.coords.altitude.toFixed(7)) : -1;
                // // q.ValueSpd
                // q.ValueArr = [q.ValueLon, q.ValueLat]; // Mongo stores ar long then lat.
                // this.$set(q, 'Answer', this.$format.dmsLatLong(q.ValueLat, q.ValueLon)); // Trigger change with $set.
                // Show a map?
                // mapLink.href = `https://www.openstreetmap.org/#map=18/${latitude}/${longitude}`;
                // mapLink.textContent = `Latitude: ${latitude} °, Longitude: ${longitude} °`;
                if (!q.Meta) q.Meta = [];
                const meta = q.Meta;
                const answer = q.Answer;
                const idx = q.loopCounter || 0;

                if (!meta[idx]) meta[idx] = {};
                meta[idx].ValueLat = parseFloat(location.coords.latitude.toFixed(7));
                this.$set(q, 'DisplayLat', meta[idx].ValueLat);
                meta[idx].ValueLon = parseFloat(location.coords.longitude.toFixed(7));
                this.$set(q, 'DisplayLon', meta[idx].ValueLon);
                meta[idx].ValueAcc = location.coords.accuracy;
                this.$set(q, 'DisplayAcc', meta[idx].ValueAcc);
                meta[idx].ValueAccFail = location.coords.accuracy > q.Accuracy;
                this.$set(q, 'DisplayAccFail', meta[idx].ValueAccFail);
                meta[idx].ValueAlt = location.coords.altitude ? parseFloat(location.coords.altitude.toFixed(7)) : -1;
                this.$set(q, 'DisplayAlt', meta[idx].ValueAlt);
                // meta.ValueArr = [meta[idx].ValueLon, meta[idx].ValueLat]; // Mongo stores as long then lat.
                meta[idx].ValueDisplay = this.$format.dmsLatLong(meta[idx].ValueLat, meta[idx].ValueLon);
                this.$set(q, 'Display', meta[idx].ValueDisplay);
                this.$forceUpdate();

                // answer[idx] = this.$format.dmsLatLong(meta[idx].ValueLat, meta[idx].ValueLon);
                answer[idx] = [meta[idx].ValueLon, meta[idx].ValueLat]; // Mongo stores as long then lat.
                // q.Answer = q.Answer.slice();
                // this.$set(q, 'Answer', answer); // Use .$set to trigger the change.
            }); */
        },
        startLocationWatch (q, options) {
            const meta = q.Meta;
            const answer = q.Answer;
            const idx = q.loopCounter || 0;
            let attempts = 0;
            if (!meta[idx]) meta[idx] = {};
            this.isLocationOn = true;
            this.bestLocation = {
                latitude: 0,
                longitude: 0,
                accuracy: 10000
            };
            this.locationWatchId = navigator.geolocation.watchPosition(location => {
                attempts += 1;
                this.$set(q, 'DisplayAttempts', attempts);
                if (location.coords.accuracy < this.bestLocation.accuracy) {
                    this.bestLocation = location.coords;
                    this.setLocation(location, q, meta, answer, idx);
                    if (this.bestLocation.accuracy <= (q.Accuracy || 10)) {
                        this.stopLocation();
                    }
                }
            }, err => {
                this.errorDetail = err.message;
                this.stopLocation();
                if (q.Display === 'Locating...') {
                    this.$set(q, 'Display', err.message);
                }
            }, options);
        },
        stopLocation () {
            this.isLocationOn = false;
            navigator.geolocation.clearWatch(this.locationWatchId);
        },
        setLocation (location, q, meta, answer, idx) {
            meta[idx].ValueLat = parseFloat(location.coords.latitude.toFixed(7));
            this.$set(q, 'DisplayLat', meta[idx].ValueLat);
            meta[idx].ValueLon = parseFloat(location.coords.longitude.toFixed(7));
            this.$set(q, 'DisplayLon', meta[idx].ValueLon);
            meta[idx].ValueAcc = parseFloat(location.coords.accuracy.toFixed(2));
            this.$set(q, 'DisplayAcc', meta[idx].ValueAcc);
            meta[idx].ValueAccFail = location.coords.accuracy > q.Accuracy;
            this.$set(q, 'DisplayAccFail', meta[idx].ValueAccFail);
            meta[idx].ValueAlt = location.coords.altitude ? parseFloat(location.coords.altitude.toFixed(7)) : 'n/a';
            this.$set(q, 'DisplayAlt', meta[idx].ValueAlt);
            meta[idx].ValueTime = location.timestamp;
            this.$set(q, 'DisplayTime', this.$format.dateShortTime(new Date(meta[idx].ValueTime)));
            // meta.ValueArr = [meta[idx].ValueLon, meta[idx].ValueLat]; // Mongo stores as long then lat.
            meta[idx].ValueDisplay = this.$format.dmsLatLong(meta[idx].ValueLat, meta[idx].ValueLon);
            this.$set(q, 'Display', meta[idx].ValueDisplay);
            this.$forceUpdate();

            // q.Accuracy
            // q.GPSSkip

            // answer[idx] = this.$format.dmsLatLong(meta[idx].ValueLat, meta[idx].ValueLon);
            answer[idx] = [meta[idx].ValueLon, meta[idx].ValueLat]; // Mongo stores as long then lat.
        },
        scanBarcode () {
        },
        questionText (q) {
            return q.Question.Value || '<p>[Question]</p>';
        },
        prepareQuestions () {
            for (const q of this.item.Questions) {
                q.Answer = '';
                if (q.InputType === Constants.INPUT_TYPE.GPSLocation) {
                    q.Answer = [];
                    if (!q.Meta) {
                        q.Meta = {};
                        q.Meta.ValueLat = '';
                        q.Meta.ValueLon = '';
                        q.Meta.ValueAcc = '';
                        q.Meta.ValueAccFail = '';
                        q.Meta.ValueAlt = '';
                        q.Meta.ValueArr = '';
                        // q.Meta.ValueSpd = '';
                    }
                }
                else if (q.InputType === Constants.INPUT_TYPE.FileUpload) {
                    q.Answer = [''];
                }
                // Defaults.
                if (q.Default !== undefined) {
                    /* switch (q.InputType) {
                        case Constants.INPUT_TYPE.Date: {
                            if (`${q.Default}`.toUpperCase() === 'TODAY') q.Answer = this.$format.dateYMDDash(new Date());
                            else q.Answer = q.Default;
                            break;
                        }
                        default:
                            q.Answer = q.Default;
                            break;
                    } */
                    let def = `${q.Default}`;
                    // if (def === '{today}') q.Answer = this.$format.dateYMDDash(new Date()); // NOTE: The date control requires this format.
                    // else {
                    if (def.indexOf('{today}') > -1) def = def.replace(/{today}/g, this.$format.dateYMDDash(new Date()));
                    if (def.indexOf('{now}') > -1) def = def.replace(/{now}/g, this.$format.hoursMinutes(new Date()));
                    if (def.indexOf('{rating-total}') > -1) def = def.replace(/{rating-total}/g, this.$format.number(this.ratingTotal));
                    q.Answer = def;
                    // }
                }
                // TODO: Handle language selection.
                // TODO: Add previous answer value in question text, similar to rules e.g. {13}.
            }
        },
        optionsOne (question) {
            if (question.Filter) {
                return question.Options.filter(o => o.Lang === this.lang && o.Value.toLocaleLowerCase().startsWith(this.filterOne.toLocaleLowerCase())).slice(0, 20);
            }
            else {
                return question.Options;
            }
        },
        optionsMany (question) {
            if (question.Filter) {
                return question.Options.filter(o => o.Lang === this.lang && o.Value.toLocaleLowerCase().startsWith(this.filterMany.toLocaleLowerCase())).slice(0, 20);
            }
            else {
                return question.Options;
            }
        },
        /* onPrevious () {
            this.answerNotValid = false;
            if (!this.stepState.length) return;
            this.filterOne = '';
            this.filterMany = '';
            // Move.
            const step = this.stepState.pop();
            this.loopCounter = step.counter;
            this.index = step.index;
        },
        onNext () {
            // Validate current question PASS rules.
            const pass = this.runPassRules();
            this.answerNotValid = pass === false;
            if (this.answerNotValid) return;

            this.filterOne = '';
            this.filterMany = '';

            // Keep current index for moving backwards.
            const currentIndex = this.index;
            const currentCounter = this.loopCounter;
            // if (!this.item.ShowEnd && this.index >= Constants.END_QUESTION) return;

            let nextIndex = 0;
            if (pass === true) { // A boolean and not a number.
                if (this.index === this.item.Questions[this.item.Questions.length - 1].Index && this.item.ShowEnd) {
                    // At the end and can show end message.
                    nextIndex = Constants.END_QUESTION;
                }
                else {
                    // Get the next question and validate its SHOW rules.
                    nextIndex = this.getNextQuestionIndex(currentIndex);
                }
            }
            // else nextIndex = pass - 1; // NextId received from `runPassRules`. Display index therefore - 1.
            else nextIndex = pass;
            // Move.
            this.stepState.push({ index: currentIndex, counter: currentCounter });
            this.index = nextIndex;
        }, */
        /**
         * Runs the pass rules for the current question.
         * Returns boolean or next question index.
         */
        onAnswerBlur (index) {
            this.runPassRules(index);
            if (this.item.Rating) {
                this.runRatingRules(index);
            }
        },
        runPassRules (index) {
            const value = this.makeRuleValue(this.getAnswerValue(index));
            const q = this.item.Questions.find(o => o.Index === index);
            if (q.InputType === Constants.INPUT_TYPE.Number) {
                if (q.Min !== undefined && value < +q.Min) return false;
                if (q.Max !== undefined && value > +q.Max) return false;
            }
            if (!q.Optional && this.isBlank(value)) {
                // Question is required therefore check that a value exists before continuing.
                return false;
            }
            if (!q.PassRules.length) return true; // No rules to run.
            const len = q.PassRules.length;
            // Run each rule. Stop at first success.
            let isOk = false;
            for (let i = 0; i < len; i++) {
                const o = q.PassRules[i];
                // Replace @ with the current question value.
                const rule = o.Value.replace(/@/g, value === '' ? "''" : value);
                const pass = Data.calc(rule, this.item.Questions, Constants.INPUT_TYPE);
                if (pass) return o.NextId || true; // Rule passes, go to the Next index.
                else if (o.NextId) isOk = true; // Rule failed but is a jump check because it has a Next index. Let it pass.
                else isOk = false; // Rule failed.
            }
            // Rules are done. Even if validation failed, pass because of optional.
            return q.Optional ? true : isOk;
        },
        runRatingRules (index) {
            const value = this.makeRuleValue(this.getAnswerValue(index));
            const q = this.item.Questions.find(o => o.Index === index);
            const len = q.RatingRules.length;
            if (!len) return true; // No rules to run.
            for (let i = 0; i < len; i++) {
                const o = q.RatingRules[i];
                // Replace @ with the current question value.
                const rule = o.Value.replace(/@/g, value === '' ? "''" : value);
                const match = Data.calc(rule, this.item.Questions, q.Options);
                if (match) {
                    q.Rating = o.Rating;
                    break;
                }
            }
            this.sumRatings();
        },
        sumRatings () {
            let total = 0;
            for (const q of this.item.Questions) {
                if (typeof q.Rating === 'number') {
                    total += q.Rating;
                }
            }
            this.ratingTotal = total;

            // Update the varables in the questions as well as Start and End messages.
            if (this.item.ShowStart) {
                let def = this.defStartMsg;
                if (def.indexOf('{today}') > -1) def = def.replace(/{today}/g, this.$format.dateYMDDash(new Date()));
                if (def.indexOf('{rating-total}') > -1) def = def.replace(/{rating-total}/g, this.$format.number(this.ratingTotal));
                this.item.StartMessage = def;
            }
            if (this.item.ShowEnd) {
                let def = this.defEndMsg;
                if (def.indexOf('{today}') > -1) def = def.replace(/{today}/g, this.$format.dateYMDDash(new Date()));
                if (def.indexOf('{rating-total}') > -1) def = def.replace(/{rating-total}/g, this.$format.number(this.ratingTotal));
                this.item.EndMessage = def;
            }
        },
        /**
         * Converts a value to be used in the rule engine.
         */
        makeRuleValue (value) {
            // If value is an array, flatten and wrap in [].
            if (Array.isArray(value)) {
                const vt = [];
                const len = value.length;
                for (let j = 0; j < len; j++) {
                    if (Data.isString(value[j])) vt.push(`"${value[j]}"`);
                    else vt.push(value[j]);
                }
                value = `[${vt.join(',')}]`;
            }
            else if (Data.isDate(value)) {
                value = this.$format.dateYMD(value);
            }
            return value;
        },
        /**
         * Finds the next question index.
         * Runs the show rules on it.
         * Recursive.
         */
        getNextQuestionIndex (fromIndex) {
            const current = this.item.Questions.find(o => o.Index === fromIndex);
            if (!current) return this.item.Questions[0].Index;
            const pos = this.item.Questions.indexOf(current);
            const nextPos = pos + 1;
            let q = this.item.Questions[nextPos];
            if (this.loopCounter === 0 && q && q.LoopParentIndex !== undefined) {
                this.loopCounter = 1;
                this.loopTo = current.Answer[0];
            }
            else if (current && current.LoopParentIndex !== undefined) {
                // const parentAnswer = this.getAnswerValue(current.LoopParentIndex);
                const parent = this.item.Questions.find(o => o.Index === current.LoopParentIndex);
                // const parentAnswer = Data.getAnswerValue(parent, Constants.INPUT_TYPE);
                const parentAnswer = parent.Answer[0];
                this.loopTo = parentAnswer;
                if (fromIndex === parent.loopEndIndex) {
                    if (this.loopCounter < parentAnswer) {
                        this.loopCounter += 1; // Increment the loop counter.
                        q = this.item.Questions.find(x => x.Index === parent.loopStartIndex); // Go back to the loop start.
                    }
                    else this.loopCounter = 0;
                }
            }
            if (!q) return Constants.END_QUESTION;
            const len = q.ShowRules.length;
            if (!len) return q.Index; // No rules. Go to the next question.
            // Run each rule. All must pass.
            for (let i = 0; i < len; i++) {
                const o = q.ShowRules[i];
                const rule = o.Value;
                const pass = Data.calc(rule, this.item.Questions, Constants.INPUT_TYPE);
                if (!pass) return this.getNextQuestionIndex(q.Index); // Failure. Step forward to another valid/available question.
            }
            return q.Index; // All passed. Go to the next question.
        },
        getAnswerValue (index) {
            const q = this.item.Questions.find(o => o.Index === index);
            // return Data.getAnswerValue(q, Constants.INPUT_TYPE);
            return q.Answer;
        },
        onSave () {
            const fileUploads = [];
            const answer = {};

            let questionError = false;
            for (const member in this.questionErrors) {
                questionError = this.questionErrors[member];
                if (questionError) break;
            }

            if (questionError) return;

            for (const o of this.item.Questions) {
                if (o.InputType === this.$CONST.INPUT_TYPE.FileUpload) {
                    o.Answer = {};
                    fileUploads.push({ Question: o.Field, File: o.ValueFile });
                }
                // const v = this.getAnswerValue(o.Index);
                const v = o.LoopParentIndex ? o.Answer.slice(1) : (Array.isArray(o.Answer) ? o.Answer[0] : o.Answer ?? null);
                if (v !== undefined && v !== null) answer[o.Field] = v;
            };

            if (this.item.Rating) answer.Rating = this.ratingTotal;
            if (this.preview) {
                this.$info('Answers', `<pre>${JSON.stringify(answer, null, 4)}</pre>`);
                this.onBack();
                return;
            }
            this.save(answer, fileUploads);
        },
        async save (answer, fileUploads) {
            try {
                this.isSaving = true;
                const data = {
                    ProjectId: this.viewProject._id,
                    SurveyId: this.item._id,
                    Version: this.item.Version,
                    Answer: answer,
                    StartDate: this.startDate,
                    EndDate: new Date(),
                    User: this.user._id,
                    Done: false,
                };

                const formData = new FormData();
                for (const [key, value] of Object.entries(data)) {
                    formData.append(key, typeof value === 'object' && !Data.isDate(value) ? JSON.stringify(value) : value);
                }

                for (const fileUpload of fileUploads) {
                    formData.append(`formUpload_${fileUpload.Question}`, fileUpload.File);
                }
                const submitUrl = (this.isExternal) ? '/AnswerStaging/submitexternal' : '/AnswerStaging/submit';
                const res = await this.$http.post(submitUrl, formData);
                if (res.data.s) {
                    this.isSaveDone = true;
                    this.$success('Done', 'Your answer has been saved.');
                    if (!this.preview && this.item.Exposure === 'I') { // Internal.
                        setTimeout(() => {
                            this.$router.push({ name: 'Dashboard' });
                        }, 1000);
                    }
                }
                else {
                    this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
                }
            }
            catch (ex) {
                this.$error(this.$t('general.save_error'), this.$t('general.an_error'));
            }
            finally {
                this.isSaving = false;
            }
        },
        onBack () {
            this.$router.go(-1);
        }
    },
    watch: {
        index () { // val
            const q = this.item.Questions.find(o => o.Index === this.index);
            if (q) {
                if (q.Answer === undefined) {
                    this.$set(q, 'Answer', []);
                }
                if (q.InputType === Constants.INPUT_TYPE.GPSLocation) {
                    q.Answer = [];
                    if (!q.Meta) q.Meta = [];
                    const idx = this.loopCounter || 0;
                    if (!q.Meta[idx]) {
                        q.Meta[idx] = {};
                        q.Meta[idx].ValueLat = '';
                        q.Meta[idx].ValueLon = '';
                        q.Meta[idx].ValueAcc = '';
                        q.Meta[idx].ValueAccFail = '';
                        q.Meta[idx].ValueAlt = '';
                        q.Meta[idx].ValueArr = '';
                        // q.Meta[idx].ValueSpd = '';
                    }
                }
                q.loopCounter = this.loopCounter; // This is only used in `getAnswerValue`.
                this.question = q;
                // TODO: Handle language selection.
                // TODO: Add previous answer value in question text, similar to rules e.g. {13}.
                this.questionText = this.question.Question[0].Value || '<p>[Question]</p>';
                if (this.questionText.indexOf('%index%') > -1 && q.LoopParentIndex !== undefined) { // Looping question.
                    this.questionText = this.questionText.replace(/%index%/g, q.loopCounter);
                }
                // Default value.
                if (this.question.Default !== undefined && this.question.Answer === undefined) this.question.Answer = this.question.Default;
            }
            else this.question = {};
            // this.isEnd = this.item.ShowEnd ? (this.item.Questions.length === 1 || this.index === Constants.END_QUESTION);
            const len = this.item.Questions.length;
            this.isEnd = this.item.ShowEnd ? this.index === Constants.END_QUESTION : (len === 1 || this.item.Questions[len - 1].Index === this.index);
        },
        modalDOB (val) {
            val && setTimeout(() => (this.$refs.previewDOB.activePicker = 'YEAR'));
        },
        viewProject () {
            if (!this.isExternal) this.$router.push({ name: 'Dashboard' });
        }
    },
    computed: {
        ...mapState({
            viewProject: 'viewProject',
            user: 'user'
        })
    }
};
</script>
