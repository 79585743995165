var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _vm.isExternal && _vm.offlineDataAvailble
        ? _c(
            "div",
            [
              _c(
                "v-card",
                { attrs: { color: "info" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", color: "info" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Sync Message")]),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                  _c(
                    "v-banner",
                    {
                      attrs: { "single-line": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "actions",
                            fn: function () {
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { small: "", color: "success" },
                                    on: { click: _vm.resyncExternalForms },
                                  },
                                  [_c("v-icon", [_vm._v("mdi-cloud-sync")])],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { small: "", color: "error" },
                                    on: { click: _vm.cancelResync },
                                  },
                                  [_c("v-icon", [_vm._v("mdi-close")])],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        360333788
                      ),
                    },
                    [
                      _vm._v(
                        " You have some offline form submissions. Click on the sync button to sync them to the server. "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("br"),
            ],
            1
          )
        : _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "mb-0 pb-0", attrs: { sm: "12" } },
                    [
                      _c(
                        "h1",
                        { staticClass: "title font-weight-light" },
                        [
                          this.preview
                            ? _c("v-icon", { on: { click: _vm.onBack } }, [
                                _vm._v("mdi-arrow-left-circle"),
                              ])
                            : _vm._e(),
                          _vm._v(" " + _vm._s(this.item.Name) + " "),
                          _c("span", {
                            staticClass: "font-weight-light subtitle-1",
                            domProps: {
                              innerHTML: _vm._s(
                                this.preview
                                  ? "<b class=accent--text>PREVIEW</b>. No answers will be saved"
                                  : ""
                              ),
                            },
                          }),
                          _c("span", { staticClass: "caption ml-2" }, [
                            _vm._v("v." + _vm._s(_vm.item.Version)),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "mt-0 pt-0 w-1024", attrs: { sm: "12" } },
                    [
                      _c(
                        "v-card-actions",
                        { staticClass: "ma-0 pa-0" },
                        [
                          _c("span", { staticClass: "font-weight-light f-m" }, [
                            _vm._v(_vm._s(_vm.user.FullName)),
                          ]),
                          _c("v-spacer"),
                          _c("span", { staticClass: "font-weight-light f-s" }, [
                            _vm._v(_vm._s(_vm.$format.dateTime(new Date()))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-0" },
                [
                  _c(
                    "v-col",
                    { attrs: { sm: "12" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "w-1024",
                          attrs: { loading: _vm.isBusy },
                        },
                        [
                          _c(
                            "v-container",
                            [
                              _c("v-progress-linear", {
                                attrs: {
                                  value:
                                    (_vm.index / _vm.item.Questions.length) *
                                    100,
                                  active: true,
                                  absolute: "",
                                  top: "",
                                  color: "accent",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("v-spacer"),
                                  _c("span", { staticClass: "caption" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.index === _vm.$CONST.END_QUESTION
                                          ? _vm.item.Questions.length
                                          : _vm.index
                                      ) +
                                        " / " +
                                        _vm._s(_vm.item.Questions.length)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              !_vm.isSaveDone
                                ? _c(
                                    "v-card-actions",
                                    [
                                      !_vm.isSaving
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                disabled: _vm.item.ShowStart
                                                  ? _vm.index === 0
                                                  : _vm.index === 1,
                                              },
                                              on: { click: _vm.onPrevious },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-chevron-left"),
                                              ]),
                                              _vm._v("Previous"),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-card-text",
                                { staticStyle: { "min-height": "300px" } },
                                [
                                  _vm.item.ShowStart && _vm.index === 0
                                    ? _c("div", {
                                        staticClass: "question-label",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.item.StartMessage
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.item.ShowEnd && _vm.isEnd
                                    ? _c("div", {
                                        staticClass: "question-label",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.item.EndMessage
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.index !== 0 &&
                                  _vm.index !== _vm.$CONST.END_QUESTION
                                    ? _c("div", {
                                        staticClass: "question-label",
                                        domProps: {
                                          innerHTML: _vm._s(_vm.questionText),
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    [
                                      _vm.showInputType(
                                        _vm.$CONST.INPUT_TYPE.Text
                                      ) && (_vm.question.Lines || 1) < 2
                                        ? _c("v-text-field", {
                                            attrs: {
                                              counter:
                                                _vm.question.Max || false,
                                              minlength:
                                                _vm.question.Min || false,
                                              maxlength:
                                                _vm.question.Max || false,
                                              prefix: _vm.question.Pre,
                                              suffix: _vm.question.Post,
                                              placeholder: _vm.question.InHint,
                                              hint: _vm.question.OutHint,
                                              "persistent-hint": "",
                                            },
                                            on: {
                                              keyup: function ($event) {
                                                return _vm.formatValidation(
                                                  _vm.question._id,
                                                  _vm.question.Format
                                                )
                                              },
                                              keydown: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                $event.preventDefault()
                                                return _vm.onNext.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.question.Answer[
                                                  _vm.loopCounter || 0
                                                ],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.question.Answer,
                                                  _vm.loopCounter || 0,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "question.Answer[loopCounter || 0]",
                                            },
                                          })
                                        : _vm.showInputType(
                                            _vm.$CONST.INPUT_TYPE.Text
                                          ) && (_vm.question.Lines || 1) > 1
                                        ? _c("v-textarea", {
                                            attrs: {
                                              rows: _vm.question.Lines || 2,
                                              counter:
                                                _vm.question.Max || false,
                                              minlength:
                                                _vm.question.Min || false,
                                              maxlength:
                                                _vm.question.Max || false,
                                              prefix: _vm.question.Pre,
                                              suffix: _vm.question.Post,
                                              placeholder: _vm.question.InHint,
                                              hint: _vm.question.OutHint,
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.question.Answer[
                                                  _vm.loopCounter || 0
                                                ],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.question.Answer,
                                                  _vm.loopCounter || 0,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "question.Answer[loopCounter || 0]",
                                            },
                                          })
                                        : _vm.showInputType(
                                            _vm.$CONST.INPUT_TYPE.Number
                                          )
                                        ? _c(
                                            "div",
                                            [
                                              _c("v-numeric", {
                                                attrs: {
                                                  type: "number",
                                                  maxlength: 11,
                                                  numberType:
                                                    _vm.question.NumType,
                                                  prefix: _vm.question.Pre,
                                                  suffix: _vm.question.Post,
                                                  placeholder:
                                                    _vm.question.InHint,
                                                  hint: _vm.question.OutHint,
                                                  "persistent-hint": "",
                                                },
                                                on: { enter: _vm.onNext },
                                                model: {
                                                  value:
                                                    _vm.question.Answer[
                                                      _vm.loopCounter || 0
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.question.Answer,
                                                      _vm.loopCounter || 0,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "question.Answer[loopCounter || 0]",
                                                },
                                              }),
                                              !_vm.isBlank(_vm.question.Min) ||
                                              !_vm.isBlank(_vm.question.Max)
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "caption ml-2 mt-0",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          !_vm.isBlank(
                                                            _vm.question.Min
                                                          ) &&
                                                            !_vm.isBlank(
                                                              _vm.question.Max
                                                            )
                                                            ? `Must be between ${_vm.question.Min} and ${_vm.question.Max}`
                                                            : _vm.isBlank(
                                                                _vm.question.Min
                                                              )
                                                            ? `Must not be greater than ${_vm.question.Max}`
                                                            : `Must not be less than ${_vm.question.Min}`
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm.showInputType(
                                            _vm.$CONST.INPUT_TYPE.Mobile
                                          )
                                        ? _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "mask",
                                                rawName: "v-mask",
                                                value: _vm.mobileMask(
                                                  _vm.question.Answer[
                                                    _vm.loopCounter || 0
                                                  ]
                                                ),
                                                expression:
                                                  "mobileMask(question.Answer[loopCounter || 0])",
                                              },
                                            ],
                                            attrs: {
                                              prefix: _vm.question.Pre,
                                              suffix: _vm.question.Post,
                                              placeholder: _vm.question.InHint,
                                              hint: _vm.question.OutHint,
                                              rules: [_vm.rules.mobile],
                                              "prepend-icon": "mdi-cellphone",
                                              "persistent-hint": "",
                                            },
                                            on: {
                                              keydown: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                $event.preventDefault()
                                                return _vm.onNext.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.question.Answer[
                                                  _vm.loopCounter || 0
                                                ],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.question.Answer,
                                                  _vm.loopCounter || 0,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "question.Answer[loopCounter || 0]",
                                            },
                                          })
                                        : _vm.showInputType(
                                            _vm.$CONST.INPUT_TYPE.Email
                                          )
                                        ? _c("v-text-field", {
                                            attrs: {
                                              counter:
                                                _vm.question.Max || false,
                                              minlength:
                                                _vm.question.Min || false,
                                              maxlength:
                                                _vm.question.Max || false,
                                              prefix: _vm.question.Pre,
                                              suffix: _vm.question.Post,
                                              placeholder: _vm.question.InHint,
                                              hint: _vm.question.OutHint,
                                              rules: [_vm.rules.email],
                                              "prepend-icon":
                                                "mdi-email-open-outline",
                                              "persistent-hint": "",
                                            },
                                            on: {
                                              keydown: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                $event.preventDefault()
                                                return _vm.onNext.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.question.Answer[
                                                  _vm.loopCounter || 0
                                                ],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.question.Answer,
                                                  _vm.loopCounter || 0,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "question.Answer[loopCounter || 0]",
                                            },
                                          })
                                        : _vm.showInputType(
                                            _vm.$CONST.INPUT_TYPE.WebAddress
                                          )
                                        ? _c("v-text-field", {
                                            attrs: {
                                              counter:
                                                _vm.question.Max || false,
                                              minlength:
                                                _vm.question.Min || false,
                                              maxlength:
                                                _vm.question.Max || false,
                                              prefix: _vm.question.Pre,
                                              suffix: _vm.question.Post,
                                              placeholder: _vm.question.InHint,
                                              hint: _vm.question.OutHint,
                                              rules: [_vm.rules.web],
                                              "prepend-icon": "mdi-web",
                                              "persistent-hint": "",
                                            },
                                            on: {
                                              keydown: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                $event.preventDefault()
                                                return _vm.onNext.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.question.Answer[
                                                  _vm.loopCounter || 0
                                                ],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.question.Answer,
                                                  _vm.loopCounter || 0,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "question.Answer[loopCounter || 0]",
                                            },
                                          })
                                        : _vm.showInputType(
                                            _vm.$CONST.INPUT_TYPE.YesNo
                                          )
                                        ? _c(
                                            "v-btn-toggle",
                                            {
                                              attrs: {
                                                color: "accent",
                                                group: "",
                                                tile: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.question.Answer[
                                                    _vm.loopCounter || 0
                                                  ],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.question.Answer,
                                                    _vm.loopCounter || 0,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "question.Answer[loopCounter || 0]",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "pl-11 pr-11",
                                                  attrs: { value: true },
                                                },
                                                [_vm._v(" Yes ")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "pl-12 pr-12",
                                                  attrs: { value: false },
                                                },
                                                [_vm._v(" No ")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm.showInputType(
                                            _vm.$CONST.INPUT_TYPE.SelectOneDD
                                          )
                                        ? _c(
                                            "div",
                                            [
                                              _c("v-text-field", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.question.Filter,
                                                    expression:
                                                      "question.Filter",
                                                  },
                                                ],
                                                staticClass: "w-512-max",
                                                attrs: {
                                                  dense: "",
                                                  "single-line": "",
                                                  hint: "Type to search and filter the available options",
                                                  "prepend-icon": "mdi-magnify",
                                                  "persistent-hint": "",
                                                },
                                                model: {
                                                  value: _vm.filterOne,
                                                  callback: function ($$v) {
                                                    _vm.filterOne = $$v
                                                  },
                                                  expression: "filterOne",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.question.Filter,
                                                      expression:
                                                        "question.Filter",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "caption opa-6 ml-8",
                                                },
                                                [
                                                  _vm._v(
                                                    "A maximum result of 20 options will be shown."
                                                  ),
                                                ]
                                              ),
                                              _c("v-select", {
                                                attrs: {
                                                  items: _vm.optionsOne,
                                                  label: "Select Option",
                                                  "item-text": "Value",
                                                  "item-value": "Value",
                                                  "persistent-hint": "",
                                                  "return-object": "",
                                                  "single-line": "",
                                                },
                                                on: {
                                                  change: _vm.updateSelect,
                                                },
                                                model: {
                                                  value:
                                                    _vm.question.Answer[
                                                      _vm.loopCounter || 0
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.question.Answer,
                                                      _vm.loopCounter || 0,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "question.Answer[loopCounter || 0]",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "caption" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.question.OutHint)
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm.showInputType(
                                            _vm.$CONST.INPUT_TYPE.SelectOne
                                          )
                                        ? _c(
                                            "div",
                                            [
                                              _c("v-text-field", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.question.Filter,
                                                    expression:
                                                      "question.Filter",
                                                  },
                                                ],
                                                staticClass: "w-512-max",
                                                attrs: {
                                                  dense: "",
                                                  "single-line": "",
                                                  hint: "Type to search and filter the available options",
                                                  "prepend-icon": "mdi-magnify",
                                                  "persistent-hint": "",
                                                },
                                                model: {
                                                  value: _vm.filterOne,
                                                  callback: function ($$v) {
                                                    _vm.filterOne = $$v
                                                  },
                                                  expression: "filterOne",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.question.Filter,
                                                      expression:
                                                        "question.Filter",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "caption opa-6 ml-8 mt-0",
                                                },
                                                [
                                                  _vm._v(
                                                    "A maximum result of 20 options will be shown."
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-radio-group",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.question.Answer[
                                                        _vm.loopCounter || 0
                                                      ],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.question.Answer,
                                                        _vm.loopCounter || 0,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "question.Answer[loopCounter || 0]",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.optionsOne,
                                                  function (item) {
                                                    return _c("v-radio", {
                                                      key: item._id,
                                                      attrs: {
                                                        label: item.Value,
                                                        value: item.Value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "caption" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.question.OutHint)
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm.showInputType(
                                            _vm.$CONST.INPUT_TYPE.SelectMany
                                          )
                                        ? _c(
                                            "div",
                                            [
                                              _c("v-text-field", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.question.Filter,
                                                    expression:
                                                      "question.Filter",
                                                  },
                                                ],
                                                staticClass: "w-512-max",
                                                attrs: {
                                                  dense: "",
                                                  "single-line": "",
                                                  hint: "Type to search and filter the available options",
                                                  "prepend-icon": "mdi-magnify",
                                                  "persistent-hint": "",
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    return _vm.filterManyChange.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.filterMany,
                                                  callback: function ($$v) {
                                                    _vm.filterMany = $$v
                                                  },
                                                  expression: "filterMany",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.question.Filter,
                                                      expression:
                                                        "question.Filter",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "caption opa-6 ml-8",
                                                },
                                                [
                                                  _vm._v(
                                                    "A maximum result of 20 options will be shown."
                                                  ),
                                                ]
                                              ),
                                              _vm._l(
                                                _vm.optionsMany,
                                                function (item) {
                                                  return _c("v-checkbox", {
                                                    key: item._id,
                                                    staticClass: "mt-1",
                                                    attrs: {
                                                      label: item.Value,
                                                      value: item.Value,
                                                      multiple: "",
                                                      "hide-details": "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.question.Answer[
                                                          _vm.loopCounter || 0
                                                        ],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.question.Answer,
                                                          _vm.loopCounter || 0,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "question.Answer[loopCounter || 0]",
                                                    },
                                                  })
                                                }
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "caption mt-6 d-block",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.question.OutHint)
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm.showInputType(
                                            _vm.$CONST.INPUT_TYPE.Date
                                          )
                                        ? _c(
                                            "v-menu",
                                            {
                                              attrs: {
                                                "close-on-content-click": false,
                                                "nudge-right": 32,
                                                transition: "scale-transition",
                                                "offset-y": "",
                                                "min-width": "290px",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "v-text-field",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              placeholder:
                                                                _vm.question
                                                                  .InHint,
                                                              hint: _vm.question
                                                                .OutHint,
                                                              "prepend-icon":
                                                                "mdi-calendar",
                                                              "persistent-hint":
                                                                "",
                                                              readonly: "",
                                                            },
                                                            on: {
                                                              keydown:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter",
                                                                      13,
                                                                      $event.key,
                                                                      "Enter"
                                                                    )
                                                                  )
                                                                    return null
                                                                  $event.preventDefault()
                                                                  return _vm.onNext.apply(
                                                                    null,
                                                                    arguments
                                                                  )
                                                                },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.question
                                                                  .Answer[
                                                                  _vm.loopCounter ||
                                                                    0
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.question
                                                                      .Answer,
                                                                    _vm.loopCounter ||
                                                                      0,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "question.Answer[loopCounter || 0]",
                                                            },
                                                          },
                                                          on
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                              model: {
                                                value: _vm.question.ModalDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.question,
                                                    "ModalDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "question.ModalDate",
                                              },
                                            },
                                            [
                                              _c("v-date-picker", {
                                                on: {
                                                  input: function ($event) {
                                                    _vm.question.ModalDate = false
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.question.Answer[
                                                      _vm.loopCounter || 0
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.question.Answer,
                                                      _vm.loopCounter || 0,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "question.Answer[loopCounter || 0]",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm.showInputType(
                                            _vm.$CONST.INPUT_TYPE.Time
                                          )
                                        ? _c(
                                            "v-menu",
                                            {
                                              ref: "menu",
                                              attrs: {
                                                "close-on-content-click": false,
                                                "nudge-right": 32,
                                                transition: "scale-transition",
                                                "offset-y": "",
                                                "max-width": "290px",
                                                "min-width": "290px",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "v-text-field",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              placeholder:
                                                                _vm.question
                                                                  .InHint,
                                                              hint: _vm.question
                                                                .OutHint,
                                                              "prepend-icon":
                                                                "mdi-clock-outline",
                                                              format: "24hr",
                                                              scrollable: "",
                                                              "persistent-hint":
                                                                "",
                                                              readonly: "",
                                                            },
                                                            on: {
                                                              keydown:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter",
                                                                      13,
                                                                      $event.key,
                                                                      "Enter"
                                                                    )
                                                                  )
                                                                    return null
                                                                  $event.preventDefault()
                                                                  return _vm.onNext.apply(
                                                                    null,
                                                                    arguments
                                                                  )
                                                                },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.question
                                                                  .Answer[
                                                                  _vm.loopCounter ||
                                                                    0
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.question
                                                                      .Answer,
                                                                    _vm.loopCounter ||
                                                                      0,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "question.Answer[loopCounter || 0]",
                                                            },
                                                          },
                                                          on
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                              model: {
                                                value: _vm.modalTime,
                                                callback: function ($$v) {
                                                  _vm.modalTime = $$v
                                                },
                                                expression: "modalTime",
                                              },
                                            },
                                            [
                                              _c("v-time-picker", {
                                                on: {
                                                  "click:minute": function (
                                                    $event
                                                  ) {
                                                    _vm.modalTime = false
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.question.Answer[
                                                      _vm.loopCounter || 0
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.question.Answer,
                                                      _vm.loopCounter || 0,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "question.Answer[loopCounter || 0]",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm.showInputType(
                                            _vm.$CONST.INPUT_TYPE.DateOfBirth
                                          )
                                        ? _c(
                                            "v-menu",
                                            {
                                              attrs: {
                                                "close-on-content-click": false,
                                                "nudge-right": 32,
                                                transition: "scale-transition",
                                                "offset-y": "",
                                                "min-width": "290px",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "v-text-field",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              placeholder:
                                                                _vm.question
                                                                  .InHint,
                                                              hint: _vm.question
                                                                .OutHint,
                                                              "prepend-icon":
                                                                "mdi-gift-outline",
                                                              "persistent-hint":
                                                                "",
                                                              readonly: "",
                                                            },
                                                            on: {
                                                              keydown:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter",
                                                                      13,
                                                                      $event.key,
                                                                      "Enter"
                                                                    )
                                                                  )
                                                                    return null
                                                                  $event.preventDefault()
                                                                  return _vm.onNext.apply(
                                                                    null,
                                                                    arguments
                                                                  )
                                                                },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.question
                                                                  .Answer[
                                                                  _vm.loopCounter ||
                                                                    0
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.question
                                                                      .Answer,
                                                                    _vm.loopCounter ||
                                                                      0,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "question.Answer[loopCounter || 0]",
                                                            },
                                                          },
                                                          on
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                              model: {
                                                value: _vm.modalDOB,
                                                callback: function ($$v) {
                                                  _vm.modalDOB = $$v
                                                },
                                                expression: "modalDOB",
                                              },
                                            },
                                            [
                                              _c("v-date-picker", {
                                                ref: "previewDOB",
                                                attrs: {
                                                  max: new Date()
                                                    .toISOString()
                                                    .substr(0, 10),
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    _vm.modalDOB = false
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.question.Answer[
                                                      _vm.loopCounter || 0
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.question.Answer,
                                                      _vm.loopCounter || 0,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "question.Answer[loopCounter || 0]",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm.showInputType(
                                            _vm.$CONST.INPUT_TYPE.Rating
                                          )
                                        ? _c(
                                            "div",
                                            [
                                              _c("v-rating", {
                                                attrs: {
                                                  length: _vm.question.Max || 5,
                                                  color: "accent",
                                                  "background-color":
                                                    "grey lighten-1",
                                                },
                                                model: {
                                                  value: _vm.ratingVal,
                                                  callback: function ($$v) {
                                                    _vm.ratingVal = $$v
                                                  },
                                                  expression: "ratingVal",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "caption" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.question.OutHint)
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm.showInputType(
                                            _vm.$CONST.INPUT_TYPE.RangeSlider
                                          )
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "row-smaller mt-7",
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "d-flex",
                                                      attrs: { sm: "12" },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.question
                                                                  .Pre,
                                                              expression:
                                                                "question.Pre",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "body-1 mr-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.question.Pre
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("v-slider", {
                                                        attrs: {
                                                          "thumb-size": 24,
                                                          min:
                                                            _vm.question.Min ||
                                                            0,
                                                          max:
                                                            _vm.question.Max ||
                                                            10,
                                                          step:
                                                            _vm.question.Step ||
                                                            1,
                                                          hint: _vm.question
                                                            .OutHint,
                                                          "thumb-label":
                                                            "always",
                                                          ticks: "always",
                                                          "tick-size": "4",
                                                          color: "accent",
                                                          "persistent-hint": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.question.Answer[
                                                              _vm.loopCounter ||
                                                                0
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.question
                                                                .Answer,
                                                              _vm.loopCounter ||
                                                                0,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "question.Answer[loopCounter || 0]",
                                                        },
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.question
                                                                  .Post,
                                                              expression:
                                                                "question.Post",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "body-1 ml-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.question.Post
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                { staticClass: "row-smaller" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "text-center display-1 accent--text",
                                                      attrs: {
                                                        sm: "12",
                                                        "align-self": "center",
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.question.Answer[
                                                              _vm.loopCounter ||
                                                                0
                                                            ]
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm.showInputType(
                                            _vm.$CONST.INPUT_TYPE.Image
                                          )
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click: _vm.onTakePicture,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { left: "" } },
                                                    [_vm._v("mdi-camera")]
                                                  ),
                                                  _vm._v(" Take Picture "),
                                                ],
                                                1
                                              ),
                                              _vm.question.Local
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.onFileLoadPhotoClick,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { left: "" } },
                                                        [
                                                          _vm._v(
                                                            "mdi-folder-image"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "hide-sx",
                                                        },
                                                        [_vm._v("Load")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c("v-file-input", {
                                                ref: "fileLoadPhoto",
                                                staticClass: "d-none",
                                                attrs: {
                                                  accept: "image/*",
                                                  label: "Load Image",
                                                },
                                                on: {
                                                  change: _vm.onFileLoadChange,
                                                },
                                                model: {
                                                  value: _vm.question.ValueFile,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.question,
                                                      "ValueFile",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "question.ValueFile",
                                                },
                                              }),
                                              _c("br"),
                                              _c("v-img", {
                                                staticClass: "mt-2",
                                                attrs: {
                                                  src: _vm.question.Answer[
                                                    _vm.loopCounter || 0
                                                  ],
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "caption" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.question.OutHint)
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm.showInputType(
                                            _vm.$CONST.INPUT_TYPE.Sketch
                                          )
                                        ? _c("div", [
                                            !_vm.question
                                              .DefaultSketchBackground
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.onSketchImage,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: { left: "" },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " mdi-camera "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " Take Picture "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c("br"),
                                                    _vm.sketchImageSrc
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _c("SketchImage", {
                                                              attrs: {
                                                                defaultImage:
                                                                  _vm.question
                                                                    .DefaultSketchBackground,
                                                                image:
                                                                  _vm.question
                                                                    .Answer[
                                                                    _vm.loopCounter ||
                                                                      0
                                                                  ],
                                                              },
                                                              on: {
                                                                "image-data":
                                                                  _vm.newImageData,
                                                              },
                                                            }),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "caption",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .question
                                                                        .OutHint
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.question.DefaultSketchBackground
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c("SketchImage", {
                                                      attrs: {
                                                        defaultImage:
                                                          _vm.question
                                                            .DefaultSketchBackground,
                                                        image:
                                                          _vm.sketchImageSrc,
                                                      },
                                                      on: {
                                                        "image-data":
                                                          _vm.newImageData,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm.showInputType(
                                            _vm.$CONST.INPUT_TYPE.DynamicLookup
                                          )
                                        ? _c(
                                            "div",
                                            [
                                              _c("DynamicLookup", {
                                                attrs: {
                                                  items:
                                                    _vm.selectedDynamicLookupSourceItems,
                                                  selectedItem:
                                                    _vm.question
                                                      .DynamicLookupOptions
                                                      .SelectedSource,
                                                  selectedType:
                                                    _vm.selectedDynamicLookupType,
                                                  disableFilter:
                                                    !_vm.question.Filter,
                                                  selectedValues:
                                                    _vm.selectedDynamicValues,
                                                },
                                                on: {
                                                  "selected-value": (
                                                    selection
                                                  ) =>
                                                    _vm.onDynamicLookupSelection(
                                                      selection,
                                                      _vm.question
                                                    ),
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm.showInputType(
                                            _vm.$CONST.INPUT_TYPE.Signature
                                          )
                                        ? _c(
                                            "div",
                                            [
                                              !_vm.canSignAgain
                                                ? _c(
                                                    "v-icon",
                                                    { staticClass: "mr-2" },
                                                    [_vm._v("mdi-fingerprint")]
                                                  )
                                                : _vm._e(),
                                              _vm.canSignAgain
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click: _vm.onSignAgain,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { left: "" } },
                                                        [
                                                          _vm._v(
                                                            "mdi-fingerprint"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "hide-sx mr-1",
                                                        },
                                                        [_vm._v("Sign")]
                                                      ),
                                                      _vm._v("Again "),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.question.Local
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.onFileLoadSignClick,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { left: "" } },
                                                        [
                                                          _vm._v(
                                                            "mdi-folder-image"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "hide-sx",
                                                        },
                                                        [_vm._v("Load")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "error ml-2 float-right",
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click: _vm.onSignClear,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { text: "" } },
                                                    [
                                                      _vm._v(
                                                        "mdi-delete-forever-outline"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "hide-sx" },
                                                    [_vm._v("Clear")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("v-file-input", {
                                                ref: "fileLoadSign",
                                                staticClass: "d-none",
                                                attrs: {
                                                  accept: "image/*",
                                                  label: "Load Image",
                                                },
                                                on: {
                                                  change: _vm.onFileLoadChange,
                                                },
                                                model: {
                                                  value: _vm.question.ValueFile,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.question,
                                                      "ValueFile",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "question.ValueFile",
                                                },
                                              }),
                                              _c("br"),
                                              !_vm.canSignAgain
                                                ? _c("canvas", {
                                                    staticStyle: {
                                                      width: "100%",
                                                      height: "300px",
                                                      border:
                                                        "10px solid #EEEEEE",
                                                    },
                                                    attrs: { id: "user-sign" },
                                                  })
                                                : _vm._e(),
                                              _vm.canSignAgain
                                                ? _c("v-img", {
                                                    staticClass: "mt-2",
                                                    attrs: {
                                                      src: _vm.question.Answer[
                                                        _vm.loopCounter || 0
                                                      ],
                                                    },
                                                  })
                                                : _vm._e(),
                                              _c(
                                                "span",
                                                { staticClass: "caption" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.question.OutHint)
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm.showInputType(
                                            _vm.$CONST.INPUT_TYPE.GPSLocation
                                          )
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: !_vm.isLocationOn,
                                                      expression:
                                                        "!isLocationOn",
                                                    },
                                                  ],
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click: _vm.getLocation,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { left: "" } },
                                                    [
                                                      _vm._v(
                                                        "mdi-map-marker-radius"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" Get Location "),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: _vm.isLocationOn,
                                                      expression:
                                                        "isLocationOn",
                                                    },
                                                  ],
                                                  attrs: { color: "warning" },
                                                  on: {
                                                    click: _vm.stopLocation,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { left: "" } },
                                                    [
                                                      _vm._v(
                                                        "mdi-map-marker-off-outline"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" Stop "),
                                                ],
                                                1
                                              ),
                                              _c("br"),
                                              _c(
                                                "span",
                                                { staticClass: "caption" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.question.OutHint)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "w-512-max mt-2 row-smaller",
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { sm: "4" } },
                                                    [_vm._v("Coordinates")]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "font-weight-bold subtitle-1",
                                                      attrs: { sm: "8" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.question.Display
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "w-512-max row-smaller",
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { sm: "4" } },
                                                    [_vm._v("Latitude")]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { sm: "8" } },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.question
                                                            .DisplayLat || "-"
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "w-512-max row-smaller",
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { sm: "4" } },
                                                    [_vm._v("Longitude")]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { sm: "8" } },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.question
                                                            .DisplayLon || "-"
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "w-512-max row-smaller",
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { sm: "4" } },
                                                    [_vm._v("Accuracy")]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      class: _vm.question
                                                        .DisplayAccFail
                                                        ? "error--text"
                                                        : "",
                                                      attrs: { sm: "8" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.question
                                                            .DisplayAcc
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "w-512-max row-smaller",
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { sm: "4" } },
                                                    [_vm._v("Altitude")]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { sm: "8" } },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.question
                                                            .DisplayAlt || "-"
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "w-512-max row-smaller",
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { sm: "4" } },
                                                    [_vm._v("Measured at")]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { sm: "8" } },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.question
                                                            .DisplayTime || "-"
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "w-512-max row-smaller",
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { sm: "4" } },
                                                    [_vm._v("Attempts")]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { sm: "8" } },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.question
                                                            .DisplayAttempts ||
                                                            "-"
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm.showInputType(
                                            _vm.$CONST.INPUT_TYPE.Barcode
                                          )
                                        ? _c(
                                            "div",
                                            [
                                              _c("QRScanner", {
                                                ref: "barcodeScanner",
                                                attrs: {
                                                  questionValue:
                                                    _vm.question.Answer[
                                                      _vm.loopCounter || 0
                                                    ],
                                                  icon: "mdi-qrcode-scan",
                                                  readonly:
                                                    !_vm.question.Manual,
                                                  scanvin: _vm.question.ScanVin,
                                                  scanreg: _vm.question.ScanReg,
                                                  scanengine:
                                                    _vm.question.ScanEngine,
                                                  prefix: _vm.question.Pre,
                                                  suffix: _vm.question.Post,
                                                  placeholder:
                                                    _vm.question.InHint,
                                                  hint: _vm.question.OutHint,
                                                },
                                                on: {
                                                  "update-value":
                                                    _vm.updateScannerValue,
                                                  "next-question": _vm.onNext,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm.showInputType(
                                            _vm.$CONST.INPUT_TYPE
                                              .DriverQRScanner
                                          )
                                        ? _c(
                                            "div",
                                            [
                                              _c("DriverQRScanner", {
                                                ref: "driverScanner",
                                                attrs: {
                                                  questionValue:
                                                    _vm.question.Answer[
                                                      _vm.loopCounter || 0
                                                    ],
                                                  icon: "mdi-qrcode-scan",
                                                },
                                                on: {
                                                  "update-value":
                                                    _vm.updateDriverValue,
                                                  "next-question": _vm.onNext,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm.showInputType(
                                            _vm.$CONST.INPUT_TYPE.XorGrid
                                          )
                                        ? _c(
                                            "div",
                                            [
                                              _c("v-xorgrid", {
                                                ref: "xorGrid",
                                                attrs: {
                                                  gridData:
                                                    _vm.question.GridData,
                                                  prefix: _vm.question.Pre,
                                                  suffix: _vm.question.Post,
                                                },
                                                on: {
                                                  valueChanged:
                                                    _vm.updatedGridPairs,
                                                  validResultChanged:
                                                    _vm.updateGridResult,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm.showInputType(
                                            _vm.$CONST.INPUT_TYPE.FileUpload
                                          )
                                        ? _c(
                                            "div",
                                            [
                                              _c("v-fileupload", {
                                                key: _vm.question._id,
                                                attrs: {
                                                  parentData:
                                                    _vm.question.ValueFile,
                                                  validState:
                                                    _vm.questionErrors[
                                                      _vm.question.Field
                                                    ],
                                                  parentImageData:
                                                    _vm.imageData[
                                                      _vm.question.Field
                                                    ],
                                                  uploadType:
                                                    _vm.question.UploadType,
                                                  uploadSizeLimit:
                                                    _vm.question
                                                      .UploadSizeLimit,
                                                  hint: _vm.question.InHint,
                                                  isOptional:
                                                    _vm.question.Optional,
                                                },
                                                on: {
                                                  "update:parentData":
                                                    function ($event) {
                                                      return _vm.$set(
                                                        _vm.question,
                                                        "ValueFile",
                                                        $event
                                                      )
                                                    },
                                                  "update:parent-data":
                                                    function ($event) {
                                                      return _vm.$set(
                                                        _vm.question,
                                                        "ValueFile",
                                                        $event
                                                      )
                                                    },
                                                  "update:validState":
                                                    function ($event) {
                                                      return _vm.$set(
                                                        _vm.questionErrors,
                                                        _vm.question.Field,
                                                        $event
                                                      )
                                                    },
                                                  "update:valid-state":
                                                    function ($event) {
                                                      return _vm.$set(
                                                        _vm.questionErrors,
                                                        _vm.question.Field,
                                                        $event
                                                      )
                                                    },
                                                  "update:parentImageData":
                                                    function ($event) {
                                                      return _vm.$set(
                                                        _vm.imageData,
                                                        _vm.question.Field,
                                                        $event
                                                      )
                                                    },
                                                  "update:parent-image-data":
                                                    function ($event) {
                                                      return _vm.$set(
                                                        _vm.imageData,
                                                        _vm.question.Field,
                                                        $event
                                                      )
                                                    },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "v-alert",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errorDetail,
                                      expression: "errorDetail",
                                    },
                                  ],
                                  attrs: { type: "error" },
                                },
                                [_vm._v(" " + _vm._s(_vm.errorDetail) + " ")]
                              ),
                              _c(
                                "v-alert",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.answerNotValid,
                                      expression: "answerNotValid",
                                    },
                                  ],
                                  attrs: { type: "error" },
                                },
                                [
                                  _vm._v(
                                    " Answer validation failed. Please review your answer. "
                                  ),
                                ]
                              ),
                              _vm.isSaveDone
                                ? _c(
                                    "div",
                                    { staticClass: "text-right" },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticStyle: { "font-size": "100px" },
                                          attrs: { color: "success" },
                                        },
                                        [_vm._v("mdi-check")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.isSaveDone ? _c("v-divider") : _vm._e(),
                              !_vm.isSaveDone
                                ? _c(
                                    "v-card-actions",
                                    [
                                      !_vm.isSaving
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                disabled: _vm.item.ShowStart
                                                  ? _vm.index === 0
                                                  : _vm.index === 1,
                                              },
                                              on: { click: _vm.onPrevious },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-chevron-left"),
                                              ]),
                                              _vm._v("Previous"),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("v-spacer"),
                                      _vm.loopCounter
                                        ? _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                color: "accent",
                                                label: "",
                                                dark: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { left: "" } },
                                                [
                                                  _vm._v(
                                                    "mdi-rotate-3d-variant"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.loopCounter) +
                                                  "/" +
                                                  _vm._s(_vm.loopTo) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("v-spacer"),
                                      _vm.item.RatingTotal
                                        ? _c(
                                            "v-chip",
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                label: "",
                                                title: "Rating Total",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { left: "" } },
                                                [
                                                  _vm._v(
                                                    "mdi-calculator-variant"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.ratingTotal) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isSingleQuestion &&
                                      !_vm.item.ShowStart &&
                                      !_vm.item.ShowEnd
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                disabled: _vm.isSaving,
                                                loading: _vm.isSaving,
                                                text: "",
                                              },
                                              on: { click: _vm.onSave },
                                            },
                                            [
                                              _vm._v("Finish "),
                                              _c("v-icon", [
                                                _vm._v(" mdi-flag-checkered "),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isSingleQuestion &&
                                      _vm.item.ShowStart &&
                                      !_vm.item.ShowEnd &&
                                      _vm.coverMessageIndex === 0
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                disabled: _vm.isSaving,
                                                loading: _vm.isSaving,
                                                text: "",
                                              },
                                              on: { click: _vm.onNext },
                                            },
                                            [
                                              _vm._v("Next "),
                                              _c("v-icon", [
                                                _vm._v(" mdi-chevron-right "),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isSingleQuestion &&
                                      _vm.item.ShowStart &&
                                      !_vm.item.ShowEnd &&
                                      _vm.coverMessageIndex === 1
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                disabled: _vm.isSaving,
                                                loading: _vm.isSaving,
                                                text: "",
                                              },
                                              on: { click: _vm.onSave },
                                            },
                                            [
                                              _vm._v("Finish "),
                                              _c("v-icon", [
                                                _vm._v(" mdi-flag-checkered "),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isSingleQuestion &&
                                      _vm.item.ShowStart &&
                                      _vm.item.ShowEnd &&
                                      _vm.coverMessageIndex === 0
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                disabled: _vm.isSaving,
                                                loading: _vm.isSaving,
                                                text: "",
                                              },
                                              on: { click: _vm.onNext },
                                            },
                                            [
                                              _vm._v("Next "),
                                              _c("v-icon", [
                                                _vm._v(" mdi-chevron-right "),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isSingleQuestion &&
                                      _vm.item.ShowStart &&
                                      _vm.item.ShowEnd &&
                                      _vm.coverMessageIndex === 1
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                disabled: _vm.isSaving,
                                                loading: _vm.isSaving,
                                                text: "",
                                              },
                                              on: { click: _vm.onNext },
                                            },
                                            [
                                              _vm._v("Next "),
                                              _c("v-icon", [
                                                _vm._v(" mdi-chevron-right "),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isSingleQuestion &&
                                      _vm.item.ShowStart &&
                                      _vm.item.ShowEnd &&
                                      _vm.coverMessageIndex === 2
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                disabled: _vm.isSaving,
                                                loading: _vm.isSaving,
                                                text: "",
                                              },
                                              on: { click: _vm.onSave },
                                            },
                                            [
                                              _vm._v("Finish "),
                                              _c("v-icon", [
                                                _vm._v(" mdi-flag-checkered "),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isSingleQuestion &&
                                      !_vm.item.ShowStart &&
                                      _vm.item.ShowEnd &&
                                      _vm.coverMessageIndex === 0
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                disabled: _vm.isSaving,
                                                loading: _vm.isSaving,
                                                text: "",
                                              },
                                              on: { click: _vm.onNext },
                                            },
                                            [
                                              _vm._v("Next "),
                                              _c("v-icon", [
                                                _vm._v(" mdi-chevron-right "),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isSingleQuestion &&
                                      !_vm.item.ShowStart &&
                                      _vm.item.ShowEnd &&
                                      _vm.coverMessageIndex === 1
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                disabled: _vm.isSaving,
                                                loading: _vm.isSaving,
                                                text: "",
                                              },
                                              on: { click: _vm.onSave },
                                            },
                                            [
                                              _vm._v("Finish "),
                                              _c("v-icon", [
                                                _vm._v(" mdi-flag-checkered "),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.isSingleQuestion &&
                                      ((_vm.item.ShowStart &&
                                        !_vm.item.ShowEnd &&
                                        _vm.index === 0) ||
                                        (!_vm.item.ShowStart &&
                                          !_vm.item.ShowEnd &&
                                          _vm.index ===
                                            _vm.item.Questions.length - 1))
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                disabled: _vm.isSaving,
                                                loading: _vm.isSaving,
                                                text: "",
                                              },
                                              on: { click: _vm.onNext },
                                            },
                                            [
                                              _vm._v("Next "),
                                              _c("v-icon", [
                                                _vm._v(" mdi-chevron-right "),
                                              ]),
                                            ],
                                            1
                                          )
                                        : !_vm.isSingleQuestion &&
                                          ((!_vm.item.ShowStart &&
                                            !_vm.item.ShowEnd &&
                                            !_vm.checkShowMessage) ||
                                            (_vm.item.ShowEnd
                                              ? !_vm.isEnd
                                              : _vm.index !== 0 &&
                                                _vm.index !==
                                                  _vm.item.Questions[
                                                    _vm.item.Questions.length -
                                                      1
                                                  ].Index))
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                disabled: _vm.isSaving,
                                                loading: _vm.isSaving,
                                                text: "",
                                              },
                                              on: { click: _vm.onNext },
                                            },
                                            [
                                              _vm._v("Next "),
                                              _c("v-icon", [
                                                _vm._v(" mdi-chevron-right "),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.isSingleQuestion &&
                                      (_vm.item.ShowEnd
                                        ? _vm.isEnd
                                        : _vm.index ===
                                            _vm.$CONST.END_QUESTION ||
                                          _vm.isEnd)
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                disabled: _vm.isSaving,
                                                loading: _vm.isSaving,
                                                text: "",
                                              },
                                              on: { click: _vm.onSave },
                                            },
                                            [
                                              _vm._v("Finish "),
                                              _c("v-icon", [
                                                _vm._v(" mdi-flag-checkered "),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              this.preview
                ? _c(
                    "v-row",
                    { staticClass: "mt-0" },
                    [
                      _c(
                        "v-col",
                        { attrs: { sm: "12" } },
                        [
                          _c(
                            "v-container",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: { click: _vm.onBack },
                                },
                                [_vm._v("Back")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }