var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "d-flex" }, [
      _c(
        "div",
        { staticClass: "flex-grow-1" },
        [
          _c("v-text-field", {
            attrs: {
              "prepend-icon": _vm.icon,
              readonly: _vm.readonly,
              prefix: _vm.prefix,
              suffix: _vm.suffix,
              placeholder: _vm.placeholder,
              hint: _vm.hint,
              "persistent-hint": "",
              clearable: "",
              "clear-icon": "mdi-close-circle",
            },
            on: {
              input: _vm.emitValue,
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.preventDefault()
                return _vm.emitOnNextQuestion.apply(null, arguments)
              },
            },
            model: {
              value: _vm.answerValue,
              callback: function ($$v) {
                _vm.answerValue = $$v
              },
              expression: "answerValue",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex-shrink-1 pt-3 pl-2" },
        [
          _vm.showScanButton
            ? _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: { click: _vm.startScanning },
                },
                [_vm._v("Scan")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "d-flex" }, [
      _c(
        "div",
        { staticClass: "flex-grow-1 pt-3 pl-2" },
        [
          _c("StreamBarcodeReader", {
            ref: "scanner",
            attrs: {
              autofocus: true,
              "aspect-ratio": 2,
              "full-width-barcode": _vm.fullWidthBarcode,
            },
            on: {
              decode: (a, b, c) => _vm.onDecode(a, b, c),
              loaded: () => _vm.onLoaded(),
            },
          }),
        ],
        1
      ),
    ]),
    _vm.scannerRunning
      ? _c("div", { staticClass: "d-flex" }, [
          _c(
            "div",
            { staticClass: "flex-grow-1 pt-3 pl-2" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "warning" },
                  on: { click: _vm.stopScanning },
                },
                [_vm._v("Cancel")]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }