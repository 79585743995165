var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _vm.surveys.length === 0 || _vm.surveys.length === undefined
        ? _c(
            "v-row",
            { staticClass: "mt-0" },
            [
              _c(
                "v-col",
                { attrs: { sm: "12" } },
                [
                  _c(
                    "v-alert",
                    { attrs: { prominent: "", type: "info" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c("v-col", { staticClass: "grow" }, [
                            _vm._v(
                              " No submission data to display.Please complete some forms or use the form section to create forms if none has been created yet or alternatively contact your administrator for assistance. Please note that the Dashboard is near-live and updated periodically. "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { sm: "12" } }, [
                    _c(
                      "h1",
                      { staticClass: "title font-weight-light" },
                      [
                        _vm._v(" My Submissions per form   "),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              fab: "",
                              "x-small": "",
                              color: "primary",
                              loading: _vm.isBusy,
                            },
                            on: { click: _vm.forceSync },
                          },
                          [_c("v-icon", [_vm._v(" mdi-sync ")])],
                          1
                        ),
                        _c(
                          "span",
                          { staticClass: "font-weight-light subtitle-1" },
                          [_vm._v(" " + _vm._s(_vm.dataDate) + " ")]
                        ),
                        _c(
                          "span",
                          { staticClass: "float-right" },
                          [
                            _c(
                              "v-menu",
                              {
                                attrs: {
                                  "open-on-hover": "",
                                  bottom: "",
                                  "offset-y": "",
                                  "nudge-top": "-4",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "span",
                                          [
                                            _c(
                                              "v-chip",
                                              _vm._g(
                                                {
                                                  staticClass: "mt-1",
                                                  attrs: {
                                                    small: "",
                                                    label: "",
                                                    color: "transparent",
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    "mdi-sort-" +
                                                      _vm._s(_vm.sortIcon)
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _c(
                                  "v-list",
                                  _vm._l(_vm.sortOptions, function (item) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: item.id,
                                        on: {
                                          click: function ($event) {
                                            return _vm.onSortClick(item.id)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { left: "" } },
                                              [
                                                _vm._v(
                                                  "mdi-sort-" + _vm._s(item.id)
                                                ),
                                              ]
                                            ),
                                            _vm._v(_vm._s(item.text)),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-menu",
                              {
                                attrs: {
                                  "open-on-hover": "",
                                  bottom: "",
                                  "offset-y": "",
                                  "nudge-top": "-4",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "span",
                                          { staticClass: "forStatus" },
                                          [
                                            _c(
                                              "v-chip",
                                              _vm._g(
                                                {
                                                  staticClass: "mt-1",
                                                  attrs: {
                                                    small: "",
                                                    label: "",
                                                    color: "transparent",
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    "mdi-view-" +
                                                      _vm._s(_vm.layoutIcon)
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _c(
                                  "v-list",
                                  _vm._l(_vm.layoutOptions, function (item) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: item.id,
                                        on: {
                                          click: function ($event) {
                                            return _vm.onLayoutClick(item.id)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { left: "" } },
                                              [
                                                _vm._v(
                                                  "mdi-view-" + _vm._s(item.id)
                                                ),
                                              ]
                                            ),
                                            _vm._v(_vm._s(item.text)),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-0" },
                [
                  _vm.layoutIcon === "module-outline"
                    ? _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-wrap w-1200-max",
                          attrs: { sm: "12" },
                        },
                        _vm._l(_vm.surveys, function (item) {
                          return _c(
                            "span",
                            {
                              key: item._id,
                              staticClass: "el-dash-box",
                              on: {
                                click: function ($event) {
                                  return _vm.onDetailClick(item._id)
                                },
                              },
                            },
                            [
                              _c("vuenime", {
                                attrs: {
                                  value: item,
                                  duration: 600,
                                  delay: 100,
                                  endDelay: 0,
                                  easing: "cubicBezier(.2,.84,.41,.83)",
                                  round: 0,
                                  direction: "normal",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ Color }) {
                                        return [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "ml-1 mr-1 mb-2 pa-3 body-2",
                                              attrs: {
                                                dark:
                                                  item.ColorText === "white",
                                                color: Color,
                                                flat: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "row-smaller mt-0",
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { sm: "8" } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-truncate body-2 f-bm cur-default",
                                                          attrs: {
                                                            title: item.Name,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.Name)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "text-right",
                                                      attrs: {
                                                        sm: "4",
                                                        "align-self": "end",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "caption mr-1 opa-5 cur-default",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "v." +
                                                              _vm._s(
                                                                item.Version
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "mb-1 opa-5",
                                                          attrs: { medium: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.Icon)
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "row-smaller mb-0",
                                                },
                                                [_c("v-divider")],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: item.MyCount !== 0,
                                                      expression:
                                                        "item.MyCount !== 0",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "row-smaller mb-0 mt-1",
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { sm: "6" } },
                                                    [_vm._v("My Total")]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { sm: "6" } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cur-default d-flex justify-end",
                                                        },
                                                        [
                                                          _c(
                                                            "v-chip",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "light-blue lighten-1",
                                                                small: "",
                                                                label: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$format.int(
                                                                    item.MyCount
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        item.UnPushed !== 0,
                                                      expression:
                                                        "item.UnPushed !== 0",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "row-smaller mb-0 mt-1",
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { sm: "6" } },
                                                    [_vm._v("My Unsynced")]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { sm: "6" } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cur-default d-flex justify-end",
                                                        },
                                                        [
                                                          _c(
                                                            "v-chip",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "#f6d029",
                                                                small: "",
                                                                label: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$format.int(
                                                                    item.UnPushed
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.layoutIcon === "module"
                    ? _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-wrap w-1200-max",
                          attrs: { sm: "12" },
                        },
                        _vm._l(_vm.surveys, function (item) {
                          return _c(
                            "span",
                            {
                              key: item._id,
                              staticClass: "el-dash-box",
                              on: {
                                click: function ($event) {
                                  return _vm.onDetailClick(item._id)
                                },
                              },
                            },
                            [
                              _c("vuenime", {
                                attrs: {
                                  value: item,
                                  duration: 600,
                                  delay: 100,
                                  endDelay: 0,
                                  easing: "cubicBezier(.2,.84,.41,.83)",
                                  round: 0,
                                  direction: "normal",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ Color }) {
                                        return [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "ml-1 mr-1 mb-2 pa-3",
                                              attrs: {
                                                dark:
                                                  item.ColorText === "white",
                                                color: Color,
                                                flat: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "row-smaller mt-0",
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticStyle: {
                                                        "padding-right":
                                                          "2px !important",
                                                      },
                                                      attrs: { sm: "10" },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-truncate body-2 opa-8 cur-default",
                                                          attrs: {
                                                            title: item.Name,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.Name)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "text-right",
                                                      staticStyle: {
                                                        "padding-left":
                                                          "2px !important",
                                                      },
                                                      attrs: { sm: "2" },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "caption mr-1 cur-default opa-8",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "v." +
                                                              _vm._s(
                                                                item.Version
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "row-smaller mb-0",
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { sm: "10" } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "headline cur-default",
                                                        },
                                                        [
                                                          _c(
                                                            "v-chip",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    item.MyCount !==
                                                                    0,
                                                                  expression:
                                                                    "item.MyCount !== 0",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "pa-2 mr-1",
                                                              attrs: {
                                                                color:
                                                                  "light-blue lighten-1",
                                                                small: "",
                                                                label: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$format.int(
                                                                    item.MyCount
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-chip",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    item.UnPushed !==
                                                                    0,
                                                                  expression:
                                                                    "item.UnPushed !== 0",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "pa-2 mr-1",
                                                              attrs: {
                                                                color:
                                                                  "#f6d029",
                                                                small: "",
                                                                label: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$format.int(
                                                                    item.UnPushed
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "text-right",
                                                      attrs: {
                                                        sm: "2",
                                                        "align-self": "end",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mb-1",
                                                          attrs: { medium: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.Icon)
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.layoutIcon === "sequential"
                    ? _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-wrap w-1200-max",
                          attrs: { sm: "12" },
                        },
                        _vm._l(_vm.surveys, function (item) {
                          return _c(
                            "span",
                            {
                              key: item._id,
                              staticStyle: { width: "100%" },
                              on: {
                                click: function ($event) {
                                  return _vm.onDetailClick(item._id)
                                },
                              },
                            },
                            [
                              _c("vuenime", {
                                attrs: {
                                  value: item,
                                  duration: 600,
                                  delay: 100,
                                  endDelay: 0,
                                  easing: "cubicBezier(.2,.84,.41,.83)",
                                  round: 0,
                                  direction: "normal",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ Color }) {
                                        return [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "ml-0 mr-0 mb-2 pa-3",
                                              attrs: {
                                                dark:
                                                  item.ColorText === "white",
                                                color: Color,
                                                flat: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "row-smaller mt-0 mb-0",
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { sm: "7" } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-truncate title cur-default",
                                                          attrs: {
                                                            title: item.Name,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                medium: "",
                                                                left: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.Icon
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.Name
                                                              ) +
                                                              " "
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "caption cur-default",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "v." +
                                                                  _vm._s(
                                                                    item.Version
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-spacer", {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          item.Review == 0 ||
                                                          item.Rejected == 0,
                                                        expression:
                                                          "item.Review == 0 || item.Rejected == 0",
                                                      },
                                                    ],
                                                  }),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "text-right",
                                                      attrs: {
                                                        sm: "5",
                                                        "align-self": "end",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-chip",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                item.MyCount !==
                                                                0,
                                                              expression:
                                                                "item.MyCount !== 0",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "pa-2 mr-1",
                                                          attrs: {
                                                            color:
                                                              "light-blue lighten-1",
                                                            small: "",
                                                            label: "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$format.int(
                                                                item.MyCount
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-chip",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                item.UnPushed !==
                                                                0,
                                                              expression:
                                                                "item.UnPushed !== 0",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "pa-2 mr-1",
                                                          attrs: {
                                                            color: "#f6d029",
                                                            small: "",
                                                            label: "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$format.int(
                                                                item.UnPushed
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.layoutIcon === "headline"
                    ? _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-wrap w-1200-max",
                          attrs: { sm: "12" },
                        },
                        _vm._l(_vm.surveys, function (item) {
                          return _c(
                            "span",
                            {
                              key: item._id,
                              staticStyle: { width: "100%" },
                              on: {
                                click: function ($event) {
                                  return _vm.onDetailClick(item._id)
                                },
                              },
                            },
                            [
                              _c("vuenime", {
                                attrs: {
                                  value: item,
                                  duration: 600,
                                  delay: 100,
                                  endDelay: 0,
                                  easing: "cubicBezier(.2,.84,.41,.83)",
                                  round: 0,
                                  direction: "normal",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ Color }) {
                                        return [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "ml-0 mr-0 mb-1 pa-1",
                                              attrs: {
                                                dark:
                                                  item.ColorText === "white",
                                                color: Color,
                                                flat: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "row-smaller mt-0 mb-0",
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { sm: "9" } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-truncate body-1 cur-default",
                                                          attrs: {
                                                            title: item.Name,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                left: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.Icon
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.Name
                                                              ) +
                                                              " "
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "caption cur-default",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "v." +
                                                                  _vm._s(
                                                                    item.Version
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "text-right",
                                                      attrs: {
                                                        sm: "3",
                                                        "align-self": "end",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-chip",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                item.MyCount !==
                                                                0,
                                                              expression:
                                                                "item.MyCount !== 0",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "pa-2 mr-1",
                                                          attrs: {
                                                            color:
                                                              "light-blue lighten-1",
                                                            small: "",
                                                            label: "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$format.int(
                                                                item.MyCount
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-chip",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                item.UnPushed !==
                                                                0,
                                                              expression:
                                                                "item.UnPushed !== 0",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "pa-2 mr-1",
                                                          attrs: {
                                                            color: "#f6d029",
                                                            small: "",
                                                            label: "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$format.int(
                                                                item.UnPushed
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.barChart && _vm.isOnline && _vm.myCounts !== undefined
                ? _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { sm: "12" } }, [
                        _c("h1", { staticClass: "title font-weight-light" }, [
                          _vm._v(" Submissions per Form "),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.barChart && _vm.isOnline && _vm.myCounts !== undefined
                ? _c("div", [
                    _c(
                      "figure",
                      [
                        _c("v-chart", {
                          attrs: {
                            option: _vm.option,
                            "init-options": _vm.initOptions,
                            autoresize: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }