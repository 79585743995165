<template>
    <div class="scanner-container" :style="{ width: '100%', height: '100%' }" v-if="fullWidthBarcode">
        <div v-show="!isLoading">
            <video poster="data:image/gif,AAAA" ref="scannervideo" style="width:100%;height:100%"></video>
            <div class="laser" ></div>
        </div>
    </div>
    <div class="scanner-container" :style="{ width: `${width}px`, height: `${height}px` }" v-else>
        <div v-show="!isLoading">
            <video poster="data:image/gif,AAAA" ref="scannervideo"></video>
            <div class="laser" ></div>
        </div>
    </div>
</template>

<script>
import { BrowserMultiFormatReader, Exception } from '@zxing/library';

export default {
    name: 'stream-barcode-reader',
    props: {
        fullWidthBarcode: Boolean,
    },
    data () {
        return {
            isLoading: true,
            width: 0,
            height: 0,
            codeReader: null,
            isMediaStreamAPISupported:
                navigator &&
                navigator.mediaDevices &&
                'enumerateDevices' in navigator.mediaDevices
        };
    },
    mounted () {
        if (!this.isMediaStreamAPISupported) {
            throw new Exception('Media Stream API is not supported');
        }
    },

    beforeDestroy () {
        if (this.codeReader !== null) {
            this.codeReader.reset();
        }
    },
    methods: {
        start () {
            const constraints = {
                facingMode: 'environment',
                focusMode: 'continuous',
                video: {
                    frameRate: {
                        ideal: 10,
                        max: 15
                    },
                },
                aspectRatio: {
                    ideal: 16 / 9
                }
            };
            const mediaStreamConstraints = { video: constraints };
            this.codeReader = new BrowserMultiFormatReader();
            this.codeReader.decodeFromConstraints(
                mediaStreamConstraints,
                this.$refs.scannervideo,
                (result, err) => {
                    if (result) {
                        this.$emit('decode', result.text);
                    }
                }
            );
        },
        startQRScanner () {
            if (this.codeReader !== null) {
                this.codeReader.reset();
            }

            const standardWidth = 400;
            const standardHeight = 400;
            const aspectRatio = standardWidth / standardHeight;
            const setDimensions = () => {
                const initialWidthPercentage = 60;
                const initialHeightPercentage = 60;
                const windowWidth = window.innerWidth;
                const windowHeight = window.innerHeight;

                const calculatedWidth = (initialWidthPercentage / 100) * windowWidth;
                const calculatedHeight = (initialHeightPercentage / 100) * windowHeight;

                if (calculatedWidth / aspectRatio <= calculatedHeight) {
                    this.width = calculatedWidth;
                    this.height = this.width / aspectRatio;
                }
                else {
                    this.height = calculatedHeight;
                    this.width = this.height * aspectRatio;
                }
            };

            if (this.$refs.scannervideo.videoWidth || this.$refs.scannervideo.offsetWidth) {
                setDimensions();
            }
            else {
                this.$refs.scannervideo.addEventListener('loadedmetadata', setDimensions, false);
            }

            this.start();
            this.$refs.scannervideo.oncanplay = event => {
                this.isLoading = false;
                this.$emit('loaded');
            };
        },
        stopQRScanner () {
            this.isLoading = true;
            this.codeReader.reset();
        },
    }
};
</script>

<style scoped>
.scanner-container {
    position: relative;
    width: 80%;
    margin: auto;
    overflow: hidden;
}

.overlay-element {
    position: absolute;
    top: 0;
    width: 100%;
    height: 99%;
    background: rgba(30, 30, 30, 0.5);

    clip-path: polygon(
        0% 0%,
        0% 100%,
        20% 100%,
        20% 22%,
        80% 22%,
        80% 79%,
        20% 79%,
        20% 100%,
        100% 100%,
        100% 0%
    );
}

.laser {
    width: 100%;
    background-color: tomato;
    height: 1px;
    position: absolute;
    top: 40%;
    z-index: 2;
    box-shadow: 0 0 4px red;
    -webkit-animation: scanning 2s infinite;
    animation: scanning 2s infinite;
}
@-webkit-keyframes scanning {
    100% {
        -webkit-transform: translateY(75px);
        transform: translateY(75px);
    }
}
@keyframes scanning {
    50% {
        -webkit-transform: translateY(75px);
        transform: translateY(75px);
    }
}
</style>
