<template>
    <v-numeric
        ref="field"
        v-model="model"
        v-on:keyup.enter="onEnter"
        @change="handleChange"
        @send-value="handleChange"
        @click:append="onAppendClick"
        @append-click="onAppendClick"
        @paste="onPaste"
        :error-messages="errorMessages"
        prepend-icon="mdi-alpha-n-box-outline"
        :alwaysShowValue="false"
        v-bind="$attrs">
    </v-numeric>
</template>

<script>
import numeric from '@/controls/Numeric';

export default {
    name: 'v-filter-number',
    components: {
        'v-numeric': numeric,
    },
    props: {
        value: null,
        'error-messages': null,
    },
    data () {
        return {
            model: this.value,
        };
    },
    methods: {
        handleChange (v) {
            if (v !== undefined) this.model = v;
            return this.$emit('send-value', this.model);
        },
        focus () {
            this.$refs.field.focus();
        },
        onEnter () {
            return this.$emit('send-value', this.model);
        },
        onAppendClick (v) {
            if (v !== undefined) this.model = v;
            return this.$emit('append-click', this.model);
        },
        onPaste (evt) {
            this.$emit('paste', evt);
        },
    },
    watch: {
        value () {
            this.model = this.value;
        },
    }
};
</script>
