var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "d-flex" }, [
      _c(
        "div",
        { staticClass: "flex-shrink-1 pt-3 pl-2" },
        [
          _vm.showScanButton
            ? _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: { click: _vm.startScanning },
                },
                [_vm._v("Scan")]
              )
            : _vm._e(),
          _c(
            "v-icon",
            { staticClass: "mb-1 opa-5 ml-2", attrs: { medium: "" } },
            [_vm._v(_vm._s(_vm.icon))]
          ),
        ],
        1
      ),
    ]),
    _vm.displayQRValue
      ? _c("div", { staticClass: "d-flex" }, [
          _c(
            "div",
            { staticClass: "flex-shrink-1 pt-3 pl-2" },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.tableData,
                  loading: _vm.isBusy,
                  "no-data-text": "No data.",
                  "hide-default-footer": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.DriverId",
                      fn: function ({ item }) {
                        return [
                          _c("div", { staticClass: "subtitle-2 cur-default" }, [
                            _vm._v(_vm._s(item.DriverId)),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "item.DriverName",
                      fn: function ({ item }) {
                        return [
                          _c("div", { staticClass: "subtitle-2 cur-default" }, [
                            _vm._v(_vm._s(item.DriverName)),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "item.DriverSurname",
                      fn: function ({ item }) {
                        return [
                          _c("div", { staticClass: "subtitle-2 cur-default" }, [
                            _vm._v(_vm._s(item.DriverSurname)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  157173340
                ),
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "d-flex" }, [
      _c(
        "div",
        { staticClass: "flex-grow-1 pt-3 pl-2" },
        [
          _c("StreamBarcodeReader", {
            ref: "scanner",
            attrs: { autofocus: true, "aspect-ratio": 2 },
            on: {
              decode: (a, b, c) => _vm.onDecode(a, b, c),
              loaded: () => _vm.onLoaded(),
            },
          }),
        ],
        1
      ),
    ]),
    _vm.scannerRunning
      ? _c("div", { staticClass: "d-flex" }, [
          _c(
            "div",
            { staticClass: "flex-grow-1 pt-3 pl-2" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "warning" },
                  on: { click: _vm.stopScanning },
                },
                [_vm._v("Cancel")]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }