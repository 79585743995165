import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueClipboard from 'vue-clipboard2';
import VueCookies from 'vue-cookies';
import VueCroppie from 'vue-croppie';
import VueI18n from 'vue-i18n';
import VueLocalStorage from 'vue-ls';
import Variables from './config';
import VueMask from 'v-mask';
import { Icon } from 'leaflet';
import { LMap, LTileLayer, LMarker, LPopup, LControlZoom } from 'vue2-leaflet';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import 'croppie/croppie.css'; // import the croppie css manually
import './util/NotificationMixin';
import './scss/utility.scss';
import './scss/custom.scss';
import 'leaflet/dist/leaflet.css';
import Constants from './util/Constants';
import Database from './util/Database';
import Formatter from './util/Formatter';
import messages from './lang/messages.json';
import numberFormats from './lang/numbers.json';
import dateTimeFormats from './lang/dates.json';
import './registerServiceWorker';
import '@mdi/font/css/materialdesignicons.min.css';

Vue.config.productionTip = false;
Vue.use(require('vue-resource'));
Vue.use(VueAxios, axios);
Vue.use(VueClipboard);
Vue.use(VueCookies);
Vue.use(VueCroppie);
Vue.use(VueI18n);
Vue.use(VueMask);
Vue.use(VueLocalStorage, { namespace: 'srvy__' });
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-popup', LPopup);
Vue.component('l-control-zoom', LControlZoom);

const db = new Database();
db.init('SurveyDataConsole');

Object.defineProperty(Vue.prototype, '$db', { value: db });
Object.defineProperty(Vue.prototype, '$CONST', { value: Constants });
Object.defineProperty(Vue.prototype, '$format', { value: Formatter });

const serverUrl = Variables.API_SERVER;
const appServerUrl = Variables.APP_SERVER;

// Split web and api deployments for this project because of the web worker. Thus setting url specifically.
// API base URL.
axios.defaults.baseURL = `${serverUrl}/api/v1`;
// Add a request interceptor to update the last call date.

Vue.prototype.$_FORMAT_LOCALE = 'en-ZA';
Vue.prototype.$_ROOT_URL = serverUrl;
Vue.prototype.$_ROOT_APP_URL = appServerUrl;
Vue.prototype.$_ROOT_URL_IMG = axios.defaults.baseURL;
const locale = 'en';
const i18n = new VueI18n({
    locale,
    numberFormats,
    dateTimeFormats,
    messages
});
export const AppVueInstance = new Vue(); // Create an event bus

// Capacitor requirement.
defineCustomElements(window);

// Fix Leaflet marker icon issue.
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

// To handle PWA install prompt.
window.addEventListener('beforeinstallprompt', evt => {
    Vue.prototype.$_DEFER_INSTALL = evt;
});

window.srvyVueInst = new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount('#app');
