var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-select",
    _vm._b(
      {
        ref: "field",
        attrs: {
          items: _vm.options,
          "error-messages": _vm.errorMessages,
          multiple: _vm.multiple,
          "prepend-icon": "mdi-list-box-outline",
          "item-value": "Value",
          "item-text": "Value",
        },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.onEnter.apply(null, arguments)
          },
          change: _vm.handleChange,
          "click:append": _vm.onAppendClick,
        },
        scopedSlots: _vm._u(
          [
            _vm.multiple
              ? {
                  key: "selection",
                  fn: function ({ item, index }) {
                    return [
                      index === 0
                        ? _c("v-chip", { attrs: { small: "" } }, [
                            _c("span", [_vm._v(_vm._s(item.Value))]),
                          ])
                        : _vm._e(),
                      index === 1
                        ? _c(
                            "span",
                            { staticClass: "grey--text text-caption" },
                            [
                              _vm._v(
                                " (... +" +
                                  _vm._s(_vm.model.length - 1) +
                                  " more) "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                }
              : null,
          ],
          null,
          true
        ),
        model: {
          value: _vm.model,
          callback: function ($$v) {
            _vm.model = $$v
          },
          expression: "model",
        },
      },
      "v-select",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }