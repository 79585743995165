export default {
    addClass (elem, cls) {
        if (elem.classList) {
            elem.classList.add(cls);
        }
        else {
            elem.className += ` ${cls}`;
        }
    },
    hasClass (elem, cls) {
        if (elem.classList) {
            return elem.classList.contains(cls);
        }
        else {
            return new RegExp(`(^| )${cls}( |$)`, 'gi').test(elem.className);
        }
    },
    removeClass (elem, cls) {
        if (elem.classList) {
            elem.classList.remove(cls);
        }
        else {
            elem.className = elem.className.replace(new RegExp(['(^|\\b)', cls.split(' ').join('|'), '(\\b|$)'].join(''), 'gi'), ' ');
        }
    },
    canPaste () {
        return (navigator.clipboard && navigator.clipboard.readText) || (document.queryCommandSupported && document.queryCommandSupported('paste'));
    },
    isApple () {
        const pf = navigator.userAgentData ? (navigator.userAgentData.platform || '').toLowerCase() : (navigator.appVersion || '').toLowerCase();
        if (pf.indexOf('mac') > -1) return true;
        if (pf.indexOf('iphone') > -1) return true;
        if (pf.indexOf('ipad') > -1) return true;
        if (pf.indexOf('safari') > -1) return true;
        return false;
    },
    isMac () {
        if (navigator.userAgentData) return (navigator.userAgentData.platform || '').toLowerCase().indexOf('mac') > -1;
        else return navigator.appVersion.indexOf('Mac') > -1;
    },
    /**
     * Get the GPS location using the browser native API.
     * Uses the browser for Mac cause the Capacitor lib does not make it ask permission.
     * Options:
     *      enableHighAccuracy
     *      timeout
     *      maximumAge
     *
     * @returns location Object
     */
    getLocation (options) {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(location => {
                resolve(location);
            }, err => {
                reject(err);
            }, options);
        });
    },
    /**
     * Reads a value from the system clipboard.
     * {@link https://w3c.github.io/clipboard-apis Clipboard}
     *
     * @param {*} valueType Type of value e.g. text/plain | text/csv | text/html etc.
     * @returns Promise
     */
    // NOTE: Firefox has weird stuff https://developer.mozilla.org/en-US/docs/Mozilla/Tech/XPCOM/Using_the_clipboard.
    clipboardGet (pasteData) {
        return new Promise(resolve => {
            if (pasteData !== undefined && pasteData !== null) { // The data came from a paste event because the browser doesn't support the new `clipboard`.
                resolve(pasteData);
                return;
            }
            if (navigator.clipboard && navigator.clipboard.readText) { // Try the new `clipboard`.
                navigator.clipboard.readText().then(value => {
                    resolve(value);
                }).catch(() => {
                    resolve('');
                });
            }
            else if (document.queryCommandSupported && document.queryCommandSupported('paste')) { // Otherwise try the `execCommand`.
                const textarea = document.createElement('textarea');
                textarea.textContent = '';
                textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
                document.body.appendChild(textarea);
                textarea.select();
                try {
                    document.execCommand('paste'); // Security exception may be thrown by some browsers.
                    setTimeout(() => resolve(textarea.value), 0);
                }
                catch (ex) {
                    setTimeout(() => resolve(''), 0);
                }
                finally {
                    document.body.removeChild(textarea);
                }
            }
            else {
                alert('Your browser is unable to get a value from the clipboard. Please upgrade it to the latest version or use the Chrome, Edge or Firefox browser.');
                setTimeout(() => resolve(''), 0);
            }
        });
    },
    /**
     * Copies the value to the system clipboard.
     * {@link https://w3c.github.io/clipboard-apis Clipboard}
     *
     * @param {*} value Value to copy.
     * @param {*} valueType Type of value e.g. text/plain | text/csv | text/html etc.
     * @returns Clipboard text.
     */
    clipboardSet (value) {
        return new Promise((resolve, reject) => {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard.writeText(value).then(() => {
                    setTimeout(() => resolve(), 0);
                }).catch(err => {
                    setTimeout(() => reject(new Error(`Unable to copy to the clipboard. ${err}`)), 0);
                });
            }
            else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
                const textarea = document.createElement('textarea');
                textarea.textContent = value;
                textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
                document.body.appendChild(textarea);
                textarea.select();
                try {
                    document.execCommand('copy'); // Security exception may be thrown by some browsers.
                    setTimeout(() => resolve(), 0);
                }
                catch (ex) {
                    setTimeout(() => reject(new Error(`Unable to copy to the clipboard. ${ex}`)), 0);
                }
                finally {
                    document.body.removeChild(textarea);
                }
            }
            else {
                setTimeout(() => reject(new Error('Your browser is unable to set a value to the clipboard. Newer Chrome, Edge and Firefox browsers support this feature.')), 0);
            }
        });
    }
};
